import { Pagination, Popconfirm, Switch, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import { CiEdit } from "react-icons/ci";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";

function DataTable(props: any) {
  const Events = useSelector((state: any) => state.Events);
  const user = useSelector((state: any) => state.User.user);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Company",
      dataIndex: "companyDetails ",
      key: "companyDetails",
      render: (item: any, item1: any) => (
        <div>{item1?.companyDetails?.name}</div>
      ),
    },
    {
      title: "Type",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (item: any) => (
        <div style={{ fontSize: 12 }}>{moment(item).format("MMM D, YYYY h:mm:ss A")}</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 50,
      render: (item: any) => (
        <div>
          <Tag color={item ? "green" : "red"}>
            {item ? "Active" : "Inactive"}
          </Tag>
        </div>
      ),
    },

    {
      title: "",
      dataIndex: "status",
      render: (item: any, record: any) => (
        <div>
          {Array.isArray(user.access[Events?.defult?.id]) &&
               user.access[Events?.defult?.id].includes(8) ? null : 
          <Switch onClick={() => props.toggleStatus(record)} checked={item} />}
        </div>
      ),
    },

    {
      title: "Actions",
      width: 50,
      render: (item: any, record: any) => (
        <div className="table-item">
           {Array.isArray(user.access[Events?.defult?.id]) &&
               user.access[Events?.defult?.id].includes(8) ? null : 
          <>
          <div>
            <Tooltip
              placement="bottomLeft"
              title={<div style={{ fontSize: 10 }}>Edit User</div>}
            >
              <CiEdit onClick={() => props.onChange(record)} size={25} />
            </Tooltip>
          </div>
          {record?.userType === "company" ? (
            <div style={{ width: 25 }} />
          ) : (
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this?"
              okText="Yes"
              cancelText="No"
              placement="bottomLeft"
              onConfirm={() => {
                props?.delete(item?.id);
              }}
            >
              <div>
                <IoIosCloseCircleOutline size={25} color="red" />
              </div>
            </Popconfirm>
          )}
        </>}
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table
        size="small"
        scroll={{ x: "max-content" }}
        dataSource={props?.data}
        columns={columns}
        pagination={false}
        loading={props?.loading}
      />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.meta?.total_count}
          showSizeChanger
          showTotal={(total: any) =>
            `Total ${props?.meta?.total_count} Companys`
          }
          onChange={(page, pageSize) => props.onPageChange(page, pageSize)}
          defaultCurrent={props?.meta?.page}
          defaultPageSize={props?.meta?.take}
        />
      </div>
    </div>
  );
}

export default DataTable;
