import { Col, Row } from "react-bootstrap";
import NoImage from "../../../assets/images/noimage.jpg"
function MeetingItem(props: any) {
  const [start, end] = props?.item?.slot?.split(" - ");
  return (
    <div className="VcDetailsScreen-MeetingItem">
      <Row onClick={() => props.onClick(props?.item)}>
        <Col sm={3} xs={12}>
          <div className="VcDetailsScreen-box2">
            <div className="VcDetailsScreen-text9">{props?.item?.slot}</div>
            <div className="VcDetailsScreen-text7">{start}</div>
            <div style={{ margin: 10 }} />
            <div className="VcDetailsScreen-text7">{end}</div>
          </div>
        </Col>
        <Col sm={9} xs={12}>
          <Row>
            <Col sm={9} xs={8}>
              <div className="VcDetailsScreen-MeetingItem2">
                <div className="VcDetailsScreen-text4" style={{ fontSize: 20 }}>
                  {props?.item?.startups?.name}
                </div>
                <div className="VcDetailsScreen-text3">
                  {props?.item?.startups?.category}
                </div>
              </div>
            </Col>
            <Col sm={3} xs={4}>
              <div className="VcDetailsScreen-imageBox">
                <img
                  src={props?.item?.startups?.logo || NoImage}
                  style={{ width: 100, height: 80, objectFit: "contain" }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default MeetingItem;
