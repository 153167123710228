import { Button, Form, Input, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "../../components/loading";
import API from "../../config/api";
import { DELETE, GET, POST } from "../../utils/apiCalls";
import PageHeader from "../routes/pageHeader";
import DataTable from "./components/dataTable";
import Forms from "./components/form";
import "./styles.scss";

function UserScreen() {
  const navigate = useNavigate();
  const Events = useSelector((state: any) => state.Events);
  const User = useSelector((state: any) => state.User);
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [openForm, setOpenForm] = useState(false);
  const [meta, setMeta] = useState<any>({});

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        ...(searchParams.get("query") && { query: searchParams.get("query") }),
        ...(searchParams.get("page") && { page: searchParams.get("page") }),
        ...(searchParams.get("limit") && { take: searchParams.get("limit") }),
        eventId: User?.user?.companyId,
      };
      const res: any = await GET(API.USERS_LIST_SEARCH, params);
      if (res.status) {
        setData(res.data);
        setMeta(res.meta);
      } else {
        message.error("Failed to fetch user data.");
      }
    } catch (error) {
      console.error(error);
      message.error("An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  }, [User?.user?.companyId, searchParams]);

  const editItem = (value: any) => {
    setSelectedItem(value);
    setOpenForm(true);
  };

  const toggleStatus = useCallback(
    async (value: any) => {
      try {
        setLoading(true);
        const obj = { ...value, password: null, status: !value.status };
        const response: any = await POST(API.USERS_EDIT, obj);
        if (response.status) {
          fetchData();
          message.success("Successfully updated");
        } else {
          message.error("Something went wrong.");
        }
      } catch (err) {
        message.error("Something went wrong.");
      } finally {
        setLoading(false);
      }
    },
    [fetchData]
  );

  const deleteItem = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        const response: any = await DELETE(`${API.USERS_DELETE}${id}`);
        if (response?.status) {
          fetchData();
          message.success("Deleted successfully.");
        } else {
          message.error("Something went wrong.");
        }
      } catch (err) {
        message.error("Something went wrong.");
      } finally {
        setLoading(false);
      }
    },
    [fetchData]
  );

  const handleSearchParamsChange = useCallback(
    (data: any) => {
      const queryParams = new URLSearchParams({
        ...(data?.query && { query: data.query }),
        ...(data?.startDate && { startDate: data.startDate }),
        page: data?.page || searchParams.get("page") || "1",
        limit: data?.pageSize || searchParams.get("limit") || "10",
      });
      navigate(`/company/users?${queryParams.toString()}`);
    },
    [navigate, searchParams]
  );

  return (
    <div>
      <PageHeader title="Users">
        <Form
          form={form}
          onValuesChange={(_, allData) =>
            handleSearchParamsChange({ ...allData, page: 1 })
          }
          initialValues={{ query: searchParams.get("query") }}
        >
          <div className="AdminHeader-extra">
            <Form.Item name="query" noStyle>
              <Input style={{ width: 200 }} placeholder="Search" allowClear />
            </Form.Item>
            <Form.Item noStyle>
            {Array.isArray(User?.user.access[Events?.defult?.id]) &&
               User?.user.access[Events?.defult?.id].includes(8) ? null : 

              <Button type="primary" onClick={() => setOpenForm(true)}>
                ADD USER +
              </Button>
}
            </Form.Item>
          </div>
        </Form>
      </PageHeader>

      {loading ? (
        <Loading />
      ) : (
        <DataTable
          data={data}
          meta={meta}
          loading={loading}
          toggleStatus={toggleStatus}
          onChange={editItem}
          delete={deleteItem}
          onPageChange={(page: number, pageSize: number) =>
            handleSearchParamsChange({ page, pageSize })
          }
        />
      )}

      {openForm && (
        <Forms
          item={Events.events}
          visible={openForm}
          data={selectedItem}
          companyId={User?.user?.companyId}
          onClose={() => {
            setSelectedItem({});
            setOpenForm(!openForm);
          }}
          onChange={fetchData}
        />
      )}
      <br />
    </div>
  );
}

export default UserScreen;
