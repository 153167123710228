import "./styles.scss";
import { Container, Col, Row } from "react-bootstrap";
import Logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";

function Header({ logo }: { logo?: string }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="Header">
        <Container fluid style={{ height: "100%" }}>
          <Row style={{ height: "100%" }}>
            <Col sm={2} xs={12} className="Header-col">
              <div className="Header-logoBox" onClick={() => navigate("/")}>
                <img src={logo || Logo} className="Header-logo" alt="logo" />
              </div>
            </Col>
            <Col sm={10} xs={6} style={{ height: "100%" }}></Col>
          </Row>
        </Container>

      </div>
      <div className="Header-space" />
    </>
  );
}

export default Header;
