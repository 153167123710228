import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const InvalidLink = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/"); // Redirect to the home page or any valid route
  };

  return (
    <Result
      status="404"
      title="Invalid-Link"
      subTitle="Oops! The page you're looking for is not available."
      extra={
        <Button type="primary" onClick={goHome}>
          Back Home
        </Button>
      }
    />
  );
};

export default InvalidLink;
