import { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  InputNumber,
  Image,
} from "antd";
import { Col, Row } from "react-bootstrap";
import countries from "../../../components/countries.json";
import API from "../../../config/api";
import { POST } from "../../../utils/apiCalls";

function Forms(props: any) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      let url = props?.data?.id ? API.USERS_EDIT : API.USERS_ADD;
      let obj = {
        id: props?.data?.id,
        name: values.name,
        userType: values.userType,
        role: values.role,
        email: values.email,
        phoneNumber: values.phoneNumber,
        countryCode: values?.country_code,
        password: values.password,
        status: values.status === false ? false : true,
        access: { root: "admin" },
        companyId: props?.data?.companyId,
      };
      let response: any = await POST(url, obj);
      if (response.status) {
        props.onChange();
        props.onClose();
        message.success("Successfully submitted");
      } else {
        message.error(response.message);
      }
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      message.error("Oops.something gone wrong.");
      setLoading(false);
    }
  };
  const cascaderOptions = countries?.map((country) => ({
    flag: country?.flag,
    value: country?.dial_code,
    label: `${country.dial_code}`,
  }));
  return (
    <Modal
      title={`${props?.data?.id ? "Edit" : "New"} User`}
      open={props.visible}
      onCancel={() => props.onClose()}
      footer={false}
      centered
      width={800}
      maskClosable={false}
      closable={!loading}
    >
      <Form
        form={form}
        scrollToFirstError
        onFinish={onFinish}
        initialValues={{
          id: props?.data?.id,
          name: props?.data?.name,
          userType: props?.data?.userType,
          role: props?.data?.role,
          email: props?.data?.email,
          phoneNumber: props?.data?.phoneNumber,
          country_code: props?.data?.countryCode || "+971",
          status: props?.data?.status,
          companyId: props?.data?.companyId,
        }}
      >
        <Row>
          <Col sm={6} xs={12}>
            <div className="label">User Name</div>
            <Form.Item
              name={"name"}
              rules={[{ required: true, message: "Please enter user name" },]}
            >
              <Input placeholder="User Name" size="large" type="text"  />
            </Form.Item>
            <div className="label">User Type</div>

            <Form.Item
              name={"userType"}
              rules={[{ required: true, message: "Please select user type" }]}
            >
              <Select
                size="large"
                placeholder="User Type"
                defaultValue={props?.data?.userType}
              >
                <Select.Option key="admin" value="admin">
                  Admin
                </Select.Option>
                <Select.Option key="company" value="company">
                  Company
                </Select.Option>
              </Select>
            </Form.Item>
            <div className="label">User Role</div>
            <Form.Item
              name={"role"}
              rules={[{ required: true, message: "Please enter user role" }]}
            >
              <Input placeholder="User Role" size="large" type="text"  onKeyPress={(event) => {
                  if (!/^[a-zA-Z ]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}/>
            </Form.Item>
            <div className="label">Email</div>
            <Form.Item
              name={"email"}
              rules={[
                { required: true, message: "Please enter your email" },
                { type: "email", message: "Enter a valid email" },
              ]}
            >
              <Input placeholder="Email" type="email" size="large" />
            </Form.Item>
          </Col>
          <Col sm={6} xs={12}>
            <div className="label">Phone Number</div>
            <Form.Item
              name={"phoneNumber"}
              rules={[
                {
                  required: true,
                  message: "Please enter phone number",
                },
                {
                  pattern:
                    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
                  message: "Please enter valid phone number",
                },
                {
                  validator: (_, value, callback) => {
                    const countryCode = form.getFieldValue("country_code");
                    if (!countryCode) {
                      return Promise.reject("Please select a country code");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
              type="tel"
              onKeyPress={(event) => {
                if (!/^[0-9]+$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
                min={0}
                style={{ width: "100%" }}
                placeholder="Phone Number"
                addonBefore={
                  <Form.Item
                    noStyle
                    name="country_code"
                   
                  >
                    <Select
                    placeholder=""
                      style={{ width: 100 }}
                      showSearch
                      dropdownStyle={{ width: 100 }}
                      allowClear
                    >
                      {cascaderOptions?.map((item: any, index: number) => {
                        return (
                          <Select.Option key={index} value={item?.value}>
                            <div className="d-flex align-items-center gap-2">
                              <Image
                              preview={false}
                                width={31}
                                alt={item?.code}
                                src={`https://flagsapi.com/${item?.flag}/flat/24.png`}
                              />
                              {item?.label}
                            </div>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                }
                size="large"
              />
            </Form.Item>

            <div className="label">Password</div>
            <Form.Item
              name={"password"}
              rules={[{ required: props?.data?.id ? false : true,message: "Please enter password", },
              
              ]}
            >
              <Input placeholder="Password" size="large" />
            </Form.Item>
            <div className="label">Confirm Password</div>
            <Form.Item
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: props?.data?.id ? false : true,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The passwords you entered do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input placeholder="Password" size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <div className="label">Status</div>
            <Form.Item name={"status"}>
              <Switch defaultChecked={props?.data?.status ? true : false} disabled={loading}/>
            </Form.Item>
          </Col>
          <Col sm={6}>
            <br />
            <Row>
              <Col sm={6}>
                <Button block size="large" onClick={() => props.onClose()} disabled={loading}>
                  Close
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  htmlType="submit"
                  block
                  size="large"
                  type="primary"
                  disabled={loading}
                  loading={loading}
                >
                  Done
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Forms;
