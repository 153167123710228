import {
  IoGridOutline,
  IoPeopleOutline,
  IoCalendarOutline,
} from "react-icons/io5";
import { PiBuildingOffice, PiVideoConference } from "react-icons/pi";
import { useLocation, useNavigate } from "react-router-dom";
import { Image, Popconfirm, Popover } from "antd";
import { IoIosLogOut } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { IoCaretDownSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/userSlice";
import { clear } from "../../redux/slices/eventsSlice";
import EventMore from "./eventMore";
import { FaUserCircle } from "react-icons/fa";
import NoImage from "./../../assets/images/noimage.jpg";
function SideBar() {
  const navigate = useNavigate();
  let location = useLocation();
  const Events = useSelector((state: any) => state.Events);
  const User = useSelector((state: any) => state.User);

  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
    dispatch(clear(null));
    sessionStorage.removeItem("sessionData"); // Clear sessionStorage
  };

  return (
    <div className="SideBar">
      <div style={{ flex: 1 }}>
        {Events?.defult ? (
          <Popover
            content={<EventMore data={Events} />}
            placement={"rightBottom"}
            arrow={false}
          >
            <div className="SideBar-eventBox">
              <div>
                <Image
                  src={Events?.defult.image || NoImage}
                  style={{ height: 30, width: 30 }}
                />
              </div>
              <div className="SideBar-txt">{Events?.defult.slug}</div>
              <div>
                <IoCaretDownSharp />
              </div>
            </div>
          </Popover>
        ) : null}
        {(User?.user?.access && User?.user?.access?.root === "admin") ||
        (Array.isArray(User?.user?.access[Events?.defult?.id]) &&
          User?.user?.access[Events?.defult?.id].includes(1)) ? (
          <div
            className={`SideBar-item ${
              location.pathname === "/company/dashboard" ? "active" : " "
            }`}
            onClick={() => navigate("/company/dashboard")}
          >
            <div>
              <IoGridOutline size={20} />
            </div>
            <div>Dashboard</div>
          </div>
        ) : null}
        {(User?.user?.access && User?.user?.access?.root === "admin") ||
        (Array.isArray(User?.user?.access[Events?.defult?.id]) &&
          User?.user?.access[Events?.defult?.id].includes(2)) ? (
          <div
            className={`SideBar-item ${
              location.pathname === "/company/meetings" ? "active" : " "
            }`}
            onClick={() => navigate("/company/meetings?page=1&limit=10")}
          >
            <div>
              <IoCalendarOutline size={20} />
            </div>
            <div>Meetings</div>
          </div>
        ) : null}
        {(User?.user?.access && User?.user?.access?.root === "admin") ||
        (Array.isArray(User?.user?.access[Events?.defult?.id]) &&
          User?.user?.access[Events?.defult?.id].includes(3)) ? (
          <div
            className={`SideBar-item ${
              location.pathname === "/company/investors" ||
              location.pathname === "/company/ivsdetails/"
                ? "active"
                : " "
            }`}
            onClick={() => navigate("/company/investors?page=1&limit=10")}
          >
            <div>
              <IoPeopleOutline size={20} />
            </div>
            <div>Venture Capitalists</div>
          </div>
        ) : null}
        {(User?.user?.access && User?.user?.access?.root === "admin") ||
        (Array.isArray(User?.user?.access[Events?.defult?.id]) &&
          User?.user?.access[Events?.defult?.id].includes(4)) ? (
          <div
            className={`SideBar-item ${
              location.pathname === "/company/startups" ? "active" : " "
            }`}
            onClick={() => navigate("/company/startups?page=1&limit=10")}
          >
            <div>
              <PiBuildingOffice size={20} />
            </div>
            <div>Startups</div>
          </div>
        ) : null}
        {(User?.user?.access && User?.user?.access?.root === "admin") ||
        (Array.isArray(User?.user?.access[Events?.defult?.id]) &&
          User?.user?.access[Events?.defult?.id].includes(5)) ? (
          <div
            className={`SideBar-item ${
              location.pathname === "/company/rooms" ? "active" : " "
            }`}
            onClick={() => navigate("/company/rooms?page=1&limit=10")}
          >
            <div>
              <PiVideoConference size={20} />
            </div>
            <div>Meeting Rooms</div>
          </div>
        ) : null}
        {(User?.user?.access && User?.user?.access?.root === "admin") ||
        (Array.isArray(User?.user?.access[Events?.defult?.id]) &&
          User?.user?.access[Events?.defult?.id].includes(6)) ? (
          <div
            className={`SideBar-item ${
              location.pathname === "/company/user" ? "active" : " "
            }`}
            onClick={() => navigate("/company/users?page=1&limit=10")}
          >
            <div>
              <FaUserCircle size={20} />
            </div>
            <div>Users</div>
          </div>
        ) : null}
      </div>
      {(User?.user?.access && User?.user?.access?.root === "admin") ||
      (Array.isArray(User?.user?.access[Events?.defult?.id]) &&
        User?.user?.access[Events?.defult?.id].includes(7)) ? (
        <div
          className={`SideBar-item ${
            location.pathname === "/company/settings" ? "active" : " "
          }`}
          onClick={() => navigate("/company/settings")}
        >
          <div>
            <IoSettingsOutline size={20} />
          </div>
          <div>Settings</div>
        </div>
      ) : null}
       <Popconfirm
            placement="right"
            title="Delete"
            trigger={["click"]}
            description="Are you sure to logout?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              logOut();
            }}
          >
      <div
        className={`SideBar-item`}
        style={{ color: "red" }}
        
      >

        <div>

          <IoIosLogOut size={20} />
        </div>
        <div>Logout</div>
      </div>
      </Popconfirm>
    </div>
  );
}

export default SideBar;
