import { Button, Input, message } from "antd";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { LuClipboardList } from "react-icons/lu";
import API from "../../config/api";

function VcFormScreen(props: any) {
  let link = `${API.WEB_URL}/vc-details/${props?.data?.uuid}`;
  const copyToClipBoard = () => {
    navigator.clipboard.writeText(link).then(() => {
      message.success("Link copied");
    });
  };

  return (
    <div className="VcFormScreen-successBox">
      <div>
        <IoIosCheckmarkCircleOutline size={100} color="#17bf74" />
      </div>
      <div>
        <div className="VcFormScreen-text1">
          Your registeration is completed successfully
        </div>
        {/* <div>
          Thank you for registering. You can track your information using the
          following link:
        </div>
        <br /> */}
        {/* <div style={{ color: "#000" }}>Copy Link : </div> */}
        {/* <Input
          style={{ color: "blue", borderRadius: 5 }}
          value={link}
          suffix={<LuClipboardList color="green" size={20}  onClick={() => copyToClipBoard()}/>}
        /> */}
        <br />
        <br />
        <div>
          If you have any questions or need further assistance, please do not
          hesitate to contact us.
        </div>
        <br />
        <Button
          type="primary"
          style={{
            fontFamily: "Roc_Grotesk_Wide",
          }}
          onClick={() => props.goBack()}
        >
          GO BACK
        </Button>
      </div>
    </div>
  );
}

export default VcFormScreen;
