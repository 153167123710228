import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DELETE, GET } from "../../../utils/apiCalls";
import API from "../../../config/api";
import Loading from "../../../components/loading";
import DataTable from "./dataTable";
import { Button, Card, message } from "antd";
import MailForm from "./form";

function MailTemplate() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const Events = useSelector((state: any) => state.Events);
  const user = useSelector((state: any) => state.User.user);

  const [selectedItem, setSelectedItem] = useState<any>({});
  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      let api = API?.MAIL_TEMPLATE_LIST + `${Events?.defult?.id}/custom`;
      const response: any = await GET(api, null);
      if (response?.status) {
        setData(response?.data);
        setLoading(false);
      } else {
        setData([]);
        setLoading(false);
      }
    } catch (err) {
      setData([]);
      setLoading(false);
    }
  };

  const editItem = (value: any) => {
    setSelectedItem(value);
    setOpenForm(!openForm);
  };

  const deleteItem = async (id: any) => {
    try {
      let response: any = await DELETE(API.MAIL_TEMPLATE_DELETE + id);
      if (response?.status) {
        getData();
        message.error("Deleted");
      } else {
        message.error("Oops.something gone wrong.");
      }
    } catch (err) {
      message.error("Oops.something gone wrong.");
    }
  };

  return (
    <div>
      <Card
        title={"Email Templates"}
        extra={[
          <div className="settingScreen-pageHeader" style={{ marginTop: 10 }}>
              {Array.isArray(user.access[Events?.defult?.id]) &&
               user.access[Events?.defult?.id].includes(8) ? null :
            <Button type="primary" onClick={() => setOpenForm(true)}>
              Add New +{" "}
            </Button>
        }
          </div>,
        ]}
      >
        {loading ? (
          <Loading />
        ) : (
          <DataTable
            data={data}
            loading={null}
            delete={(id: any) => deleteItem(id)}
            onChange={(value: any) => editItem(value)}
          />
        )}
      </Card>
      {openForm ? (
        <MailForm
          visible={openForm}
          data={selectedItem}
          onClose={() => editItem({})}
          onChange={() => getData()}
        />
      ) : null}
    </div>
  );
}

export default MailTemplate;
