import { Image, Pagination, Table, Tag, Tooltip } from "antd";
import { CiEdit, CiRead } from "react-icons/ci";
import moment from "moment";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import DeleteModal from "./deleteModal";
import NoImage from "../../../assets/images/noimage.jpg";
function DataTable(props: any) {
  const navigation = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState("");

  const columns = [
    {
      title: "",
      dataIndex: "logo",
      key: "logo",
      render: (item: any) => (
        <Image
          src={item || NoImage}
          style={{
            width: 50,
            height: 50,
            objectFit: "contain",
            backgroundColor: "#f5f5f5",
            borderRadius: 2,
          }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 50,
      render: (item: any) => (
        <div>
          <Tag color={item ? "green" : "red"}>
            {item ? "Active" : "Inactive"}
          </Tag>
        </div>
      ),
    },
    // {
    //   title: "Expiry Date",
    //   dataIndex: "expiry_date",
    //   render: (item: any) => (
    //     <div style={{ fontSize: 12 }}>{moment(item).format("MMM D, YYYY h:mm:ss A")}</div>
    //   ),
    // },
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (item: any) => (
        <div style={{ fontSize: 12 }}>{moment(item).format("MMM D, YYYY h:mm:ss A")}</div>
      ),
    },
    {
      title: "Actions",
      render: (item: any) => (
        <div className="table-item">
          {/* <div onClick={() => navigation(`/admin/staupdetails/${item.id}`)}>
            <Tooltip
              placement="bottomLeft"
              title={<div style={{ fontSize: 10 }}>Company Details</div>}
            >
              <CiRead size={25} color="#000" />
            </Tooltip>
          </div> */}
          <div onClick={() => props.onChange(item)}>
            <Tooltip
              placement="bottomLeft"
              title={<div style={{ fontSize: 10 }}>Edit Company</div>}
            >
              <CiEdit size={25} />
            </Tooltip>
          </div>

          <div
            onClick={() => {
              setIsOpen(true);
              setId(item?.id);
            }}
          >
            <Tooltip
              placement="bottomLeft"
              title={<div style={{ fontSize: 10 }}>Delete Company</div>}
            >
              <IoIosCloseCircleOutline size={25} color="red" />
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={props?.data}
        columns={columns}
        pagination={false}
        loading={props?.loading}
        scroll={{ x: 1000 }} 
      />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.meta?.total_count}
          showSizeChanger
          showTotal={(total: any) =>
            `Total ${props?.meta?.total_count} Companys`
          }
          onChange={(page, pageSize) => props.onPageChange(page, pageSize)}
        />
      </div>
      {isOpen && (
        <DeleteModal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          deleteCompany={async () => await props?.delete(id)}
        />
      )}
    </>
  );
}

export default DataTable;
