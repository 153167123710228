import Logo from "../../assets/images/logo.svg";
import { PiBuildingOffice } from "react-icons/pi";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosLogOut } from "react-icons/io";
import { HiOutlineUsers } from "react-icons/hi2";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/userSlice";
import { Popconfirm } from "antd";
function SideBar() {
  const navigate = useNavigate();
  let location = useLocation();

  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
    sessionStorage.removeItem("sessionData");
  };

  return (
    <div className="SideBar">
      <div style={{ flex: 1 }}>
        <div
          onClick={() => navigate("/admin/companies?page=1&limit=10")}
          className="SideBar-logoBox"
        >
          <img src={Logo} className="SideBar-logo" alt="Logo" />
          <div>LinkUp</div>
        </div>
        <br />
        <br />
        <div
          className={`SideBar-item ${
            location.pathname === "/admin/companies" ? "active" : " "
          }`}
          onClick={() => navigate("/admin/companies?page=1&limit=10")}
        >
          <div>
            <PiBuildingOffice size={20} />
          </div>
          <div>Companies</div>
        </div>
        <div
          className={`SideBar-item ${
            location.pathname === "/admin/users" ? "active" : " "
          }`}
          onClick={() => navigate("/admin/users?page=1&limit=10")}
        >
          <div>
            <HiOutlineUsers size={20} />
          </div>
          <div>Users</div>
        </div>
      </div>
      <Popconfirm
            placement="right"
            title="Delete"
            trigger={["click"]}
            description="Are you sure to logout?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              logOut();
            }}
          >
      <div
        className={`SideBar-item`}
        style={{ color: "red" }}
      >
        <div>
          <IoIosLogOut size={20} />
        </div>
        <div>Logout</div>
      </div>
      </Popconfirm>
    </div>
  );
}

export default SideBar;
