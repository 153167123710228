import { Button, Popconfirm, Popover } from "antd";
import { LiaUserShieldSolid } from "react-icons/lia";
import Clock from "react-live-clock";
import Profile from "../../assets/images/profilepic.jpg";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/userSlice";

function AdminHeader() {
  const dispatch = useDispatch();
  const User = useSelector((state: any) => state.User);

  const logOut = () => {
    dispatch(logout());
    sessionStorage.removeItem("sessionData");
  };

  return (
    <div className="AdminHeader">
      <div className="AdminHeader-text1"> Hello, Admin</div>
      <div className="AdminHeader-timer">
        <Clock format={"h:mm:ss A"} ticking={true} />
      </div>
      <div>
        <Popover
          content={
            <div style={{ width: 150 }} className="AdminHeader-popover">
              {/* <LiaUserShieldSolid size={50} color="green" /> */}
           
              <div>{User?.user?.name}</div>
              <div style={{ fontSize: 10, color: "grey" }}>
                {User?.user?.email}
              </div>
              <br />
              <Popconfirm
            placement="bottomRight"
            title="Delete"
            description="Are you sure to logout?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              logOut();
            }}
          >
                <div>
                  <Button block danger >
                    LOGOUT
                  </Button>
                </div>
              </Popconfirm>
            </div>
          }
        >
          <div>
            <img
              src={Profile}
              style={{ height: 40, width: 40, borderRadius: 100 }}
            />
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default AdminHeader;
