import { Pagination, Popconfirm, Table, Tooltip } from "antd";
import moment from "moment";
import { CiEdit } from "react-icons/ci";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";

function RoomDataTable(props: any) {
  const Events = useSelector((state: any) => state.Events);
  const user = useSelector((state: any) => state.User.user);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Value",
    //   dataIndex: "value",
    //   key: "value",
    // },

    {
      title: "Created",
      dataIndex: "createdAt",
      render: (item: any) => (
        <div style={{ fontSize: 12, width: 120 }}>
          {moment(item).format("MMM D, YYYY h:mm:ss A")}
        </div>
      ),
    },
    {
      title: "Actions",
      width: 120,
      render: (item: any) => (
        <div className="table-item">
          {Array.isArray(user.access[Events?.defult?.id]) &&
               user.access[Events?.defult?.id].includes(8) ? null : 
         <>
         
          <div onClick={() => props.onChange(item)}>
            <Tooltip
              placement="top"
              title={<div style={{ fontSize: 10 }}>Edit Room</div>}
            >
              <CiEdit size={25} />
            </Tooltip>
          </div>
          <Popconfirm
            placement="bottomLeft"
            title="Delete"
            description="Are you sure to delete this?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              props?.delete(item?.id);
            }}
          >
            <div>
              <Tooltip
                placement="top"
                title={<div style={{ fontSize: 10 }}>Delete Room</div>}
              >
                <IoIosCloseCircleOutline size={25} color="red" />
              </Tooltip>
            </div>
          </Popconfirm>
          </>}
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        size="small"
        scroll={{ x: 1000 }}
        dataSource={props?.data}
        columns={columns}
        pagination={false}
        loading={props?.loading}
      />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.meta?.total_count}
          showSizeChanger
          showTotal={(total: any) => `Total ${props?.meta?.total_count} Rooms`}
          onChange={(page, pageSize) => props.onPageChange(page, pageSize)}
          defaultCurrent={props?.meta?.page}
          defaultPageSize={props?.meta?.take}
        />
      </div>
    </>
  );
}

export default RoomDataTable;
