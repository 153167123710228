import {
  Button,
  Pagination,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import { CiEdit, CiRead } from "react-icons/ci";
import moment from "moment";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { IoCopyOutline } from "react-icons/io5";
import API from "../../../config/api";
import NoImage from "../../../assets/images/noimage.jpg";
import { AiOutlineLogin, AiOutlineLogout } from "react-icons/ai";
import { useSelector } from "react-redux";
function DataTable(props: any) {
  const Events = useSelector((state: any) => state.Events);
  const user = useSelector((state: any) => state.User.user);
  const navigation = useNavigate();

  const copyToClipBoard = (token: any) => {
    let link = `${API.WEB_URL}/startup-details/${token}`;
    navigator.clipboard.writeText(link).then(() => {
      message.success("Link copied");
    });
  };

  const columns = [
    {
      title: "",
      dataIndex: "logo",
      key: "logo",
      onCell: (record: any) => ({
        onClick: (event: any) => handleCellClick(event, "name", record),
      }),
      render: (item: any) => (
        <div>
          <img
            src={item || NoImage}
            style={{
              width: 50,
              height: 50,
              objectFit: "contain",
              backgroundColor: "#f5f5f5",
              borderRadius: 2,
            }}
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      onCell: (record: any) => ({
        onClick: (event: any) => handleCellClick(event, "name", record),
      }),
    },
    {
      title: "Sector",
      dataIndex: "category",
      key: "category",
      onCell: (record: any) => ({
        onClick: (event: any) => handleCellClick(event, "name", record),
      }),
    },
    {
      title: "Founder",
      dataIndex: "founder",
      key: "founder",
      onCell: (record: any) => ({
        onClick: (event: any) => handleCellClick(event, "name", record),
      }),
    },
    {
      title: "Headquarters",
      dataIndex: "country",
      key: "country",
      onCell: (record: any) => ({
        onClick: (event: any) => handleCellClick(event, "name", record),
      }),
    },
    {
      title: "Slots",
      dataIndex: "slots",
      render: (item: any) => <div style={{ width: 50 }}>{item} / 12</div>,
      onCell: (record: any) => ({
        onClick: (event: any) => handleCellClick(event, "name", record),
      }),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (item: any) => (
        <div style={{ fontSize: 12, width: 120 }}>
          {moment(item).format("MMM D, YYYY h:mm:ss A")}
        </div>
      ),
      onCell: (record: any) => ({
        onClick: (event: any) => handleCellClick(event, "name", record),
      }),
    },
    {
      title: "Attendance",
      onCell: (record: any) => ({
        onClick: (event: any) => handleCellClick(event, "Actions", record),
      }),
      render: (item: any) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "10px",
            transition: "background 0.3s ease",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: "14px",
              color: item?.has_attended ? "#52c41a" : "#f5222d",
            }}
          >
            {item?.has_attended ? (
              <Tag
                color="green"
                style={{ width: 100, textAlign: "center", cursor: "default" }}
              >
                Attended
              </Tag>
            ) : (
              <Tag
                color="red"
                style={{ width: 100, textAlign: "center", cursor: "default" }}
              >
                Not Attended
              </Tag>
            )}
          </span>
          <Button
            size="small"
            type="primary"
            style={{
              fontSize: "12px",
              background: item?.has_attended ? "#ff4d4f" : "#52c41a",
              borderColor: item?.has_attended ? "#ff4d4f" : "#52c41a",
              color: "#fff",
              transition: "all 0.3s ease",
            }}
            onClick={() =>
              props.updateAttendance({
                id: item?.uuid,
                startup_id:item?.id,
                has_attended: !item?.has_attended,
              })
            }
          >
            {item?.has_attended ? (
              <Tooltip title="Check Out">
                <AiOutlineLogout size={20} />
              </Tooltip>
            ) : (
              <Tooltip title="Check In">
                <AiOutlineLogin size={20} />
              </Tooltip>
            )}
          </Button>
        </div>
      ),
    },
    {
      title: "Actions",
      onCell: (record: any) => ({
        onClick: (event: any) => handleCellClick(event, "Actions", record),
      }),
      render: (item: any) => (
        <div className="table-item justify-content-start">
          <div onClick={() => navigation(`/company/staupdetails/${item.id}`)}>
            <Tooltip
              placement="top"
              title={<div style={{ fontSize: 10 }}>Startup Details</div>}
            >
              <CiRead size={25} color="#000" />
            </Tooltip>
          </div>
          <div onClick={() => copyToClipBoard(item.uuid)}>
            <Tooltip
              placement="top"
              title={<div style={{ fontSize: 10 }}>Copy Profile Link</div>}
            >
              <IoCopyOutline size={20} color="green" />
            </Tooltip>
          </div>
          {Array.isArray(user.access[Events?.defult?.id]) &&
               user.access[Events?.defult?.id].includes(8) ? null : 
               <>
          <div onClick={() => props.onChange(item)}>
            <Tooltip
              placement="top"
              title={<div style={{ fontSize: 10 }}>Edit Startup</div>}
            >
              <CiEdit size={25} />
            </Tooltip>
          </div>
          <Popconfirm
            title="Delete"
            description="Are you sure to delete this?"
            okText="Yes"
            cancelText="No"
            placement="bottomLeft"
            onConfirm={() => {
              props?.delete(item?.id);
            }}
          >
            <div>
              <Tooltip
                placement="top"
                title={<div style={{ fontSize: 10 }}>Delete Startup</div>}
              >
                <IoIosCloseCircleOutline size={25} color="red" />
              </Tooltip>
            </div>
          </Popconfirm>
          </>
    }
        </div>
      ),
    },
  ];
  const handleRowClick = (item: any) => {
    navigation(`/company/staupdetails/${item.id}`);
  };
  const handleCellClick = (event: any, column: any, record: any) => {
    if (column !== "Actions") {
      handleRowClick(record);
    } else {
      event.stopPropagation();
    }
  };
  return (
    <>
      <Table
        size="small"
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        style={{ cursor: "pointer" }}
        scroll={{ x: 1000 }}
        dataSource={props?.data}
        columns={columns}
        pagination={false}
        loading={props?.loading}
      />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.meta?.total_count}
          showSizeChanger
          showTotal={(total: any) =>
            `Total ${props?.meta?.total_count} Startups`
          }
          defaultCurrent={props?.meta?.page}
          defaultPageSize={props?.meta?.take}
          onChange={(page, pageSize) => props.onPageChange(page, pageSize)}
        />
      </div>
    </>
  );
}

export default DataTable;
