import "./styles.scss";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Form, Input, message } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { login } from "../../redux/slices/userSlice";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { POST } from "../../utils/apiCalls";
import API from "../../config/api";
import { clear } from "../../redux/slices/eventsSlice";

function LoginScreen() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (val: any) => {
    try {
      setLoading(true);
      let obj: any = {
        email: val.username?.trim(),
        password: val.password,
      };
      let url = API.LOGIN;
      let res: any = await POST(url, obj);
      if (res.status) {
        dispatch(clear({}));
        const sessionId = uuidv4();
        dispatch(login(res?.data));
        sessionStorage.setItem(
          "sessionData",
          JSON.stringify({ ...res?.data, sessionId })
        );
        message.success("Login success");
        if (res?.data?.userType === "admin") {
          navigation("/admin/companies?page=1&limit=10");
        } else {
          navigation("/company/events");
        }
      } else {
        message.error(res.message);
      }
    } catch (err) {
      message.error("Login failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <div className="LoginScreen">
        <div className="LoginScreen-box1">
          <br />
          <br />
          <Container>
            <br />
            <div className="LoginScreen-text1">Login and Continue</div>
            <br />
          </Container>
        </div>
        <br />
        <Container>
          <Row>
            <Col sm={4}></Col>
            <Col sm={4}>
              <br />
              <Form onFinish={onFinish}>
                <div className="label">Username</div>
                <Form.Item
                  name={"username"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter username",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
                <div className="label">Password</div>
                <Form.Item
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter password",
                    },
                  ]}
                >
                  <Input.Password size="large" />
                </Form.Item>
                <br />
                <Form.Item>
                  <Button
                    block
                    type="primary"
                    style={{
                      height: 50,
                      fontSize: 16,
                    }}
                    htmlType="submit"
                    loading={loading}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col sm={4}></Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default LoginScreen;
