import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Input, Modal, message } from "antd";
import { Row, Col } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import API from "../../../config/api";
import { POST, PUT } from "../../../utils/apiCalls";

function MailForm(props: any) {
  const Events = useSelector((state: any) => state.Events);
  const [value, setValue] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setValue(props?.data?.content);
    }, 100);
  }, []);

  const submit = async (data: any) => {
    try {
      setLoading(true);
      let obj = {
        id: props?.data?.id,
        name: data?.name,
        subject: data?.subject,
        content: value,
        type: "custom",
        eventId: Events?.defult?.id,
      };
      let url = props?.data?.id
        ? API.MAIL_TEMPLATE_EDIT + props?.data?.id
        : API.MAIL_TEMPLATE_ADD;

      let response: any = props?.data?.id
        ? await PUT(url, obj)
        : await POST(url, obj);

      if (response.status) {
        props?.onChange();
        props.onClose();
        message.success("Successfully submitted");
      } else {
        message.error("Oops.something gone wrong.");
      }
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      message.error("Oops.something gone wrong.");
      setLoading(false);
    }
  };

  return (
    <Modal
      title={"Mail Template"}
      open={props.visible}
      onCancel={() => props.onClose()}
      footer={false}
      centered
      width={600}
    >
      <Form
        onFinish={submit}
        initialValues={{
          name: props?.data?.name,
          subject: props?.data?.subject,
          content: props?.data?.content,
        }}
      >
        <div className="label">Template Name</div>
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Missing Email" }]}
        >
          <Input value={props?.data?.email} size="large" />
        </Form.Item>
        <div className="label">Subject</div>
        <Form.Item
          name={"subject"}
          rules={[{ required: true, message: "Subject Required" }]}
        >
          <Input size="large" />
        </Form.Item>
        <div className="label">Content</div>
        <Form.Item name={"content"}>
          <div style={{ display: "none" }}>{value}</div>
          <ReactQuill
            style={{ height: 300 }}
            theme="snow"
            value={value}
            onChange={setValue}
          />
        </Form.Item>
        <br /> <br />
        <Row>
          <Col sm={6}></Col>
          <Col sm={3}>
            <Button size="large" block danger onClick={() => props.onClose()}>
              Close
            </Button>
          </Col>
          <Col sm={3}>
            <Button
              size="large"
              type="primary"
              block
              htmlType="submit"
              loading={loading}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default MailForm;
