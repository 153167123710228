const COMBINE_SLOTS = async (timeSlots: any, meetings: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const assignedSlotsDict: any = {};
      meetings.forEach((slot: any) => {
        assignedSlotsDict[slot.slot] = slot;
      });
      const mergedSlots = timeSlots.map((slot: any) => {
        if (assignedSlotsDict[slot]) {
          return assignedSlotsDict[slot];
        } else {
          return {
            id: null,
            uuid: null,
            slot: slot,
            investor_name: null,
            startup_name: null,
            status: false,
            investor_id: null,
            startup_id: null,
            createdAt: null,
            updatedAt: null,
            room: null,
            startups: {
              id: null,
              logo: null,
              name: null,
              country: null,
              category: null,
            },
          };
        }
      });
      resolve(mergedSlots);
    } catch (err) {
      resolve([]);
    }
  });
};

export { COMBINE_SLOTS };
