import { Pagination, Popconfirm, Table, Tooltip } from "antd";
import moment from "moment";
import { CiEdit } from "react-icons/ci";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
function DataTable(props: any) {
  const Events = useSelector((state: any) => state.Events);
  const user = useSelector((state: any) => state.User.user);
  const columns = [
    {
      title: "Template Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (item: any) => (
        <div style={{ fontSize: 12 }}>{moment(item).format("MMM D, YYYY h:mm:ss A")}</div>
      ),
    },
    {
      title: "Actions",
      render: (item: any) => (
        <div className="table-item">
            {Array.isArray(user.access[Events?.defult?.id]) &&
               user.access[Events?.defult?.id].includes(8) ? null : 
               <>
          <div onClick={() => props.onChange(item)}>
            <Tooltip
              placement="top"
              title={<div style={{ fontSize: 10 }}>Edit Template</div>}
            >
              <CiEdit size={25} />
            </Tooltip>
          </div>
          <Popconfirm
            title="Delete"
            description="Are you sure to delete this?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              props?.delete(item?.id);
            }}
          >
            <div>
              <IoIosCloseCircleOutline size={25} color="red" />
            </div>
          </Popconfirm>
          </>}
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={props?.data}
        columns={columns}
        pagination={false}
      />
    </>
  );
}

export default DataTable;
