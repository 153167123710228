import { useEffect, useState } from "react";
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import API from "../../config/api";
import { GET } from "../../utils/apiCalls";
import "./styles.scss";

const HomeScreen = () => {
  const [progress, setProgress] = useState(0);
  const [apiResponse, setApiResponse] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 99) {
          clearInterval(interval);
          return 99;
        }
        return prev + 1;
      });
    }, 30);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getLastestEvent();
  }, []);

  useEffect(() => {
    if (progress === 99 && apiResponse) {
      handleNavigation();
    }
  }, [progress, apiResponse]);

  const getLastestEvent = async () => {
    try {
      const response:any = await GET(API.EVENT_LATEST);
      setApiResponse(response);
    } catch (err) {
      setApiResponse({ status: false, error: err });
      console.log(err);
    }
  };

  const handleNavigation = () => {
    if (apiResponse?.status) {
      navigate(`/event/${apiResponse?.data?.uuid}/${apiResponse?.data?.slug}`);
    } else {
      navigate("/login");
    }
  };

  const progressBarStyle = {
    height: '1px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)'
  };

  const progressStyle = {
    backgroundColor: 'white',
    transition: 'width 300ms ease-out'
  };

  return (
    <Container fluid className="min-vh-100 d-flex align-items-center justify-content-center text-white p-0 m-0" style={{background: "#0026ab"}}>
      <Row className="text-center">
        <Col xs={12} className="mb-3">
          <h1 className="display-4 fw-light">{progress}%</h1>
        </Col>
        <Col xs={12} className="mb-4">
          <p className="text-light letter-spacing-2 opacity-75">
            Loading event...
          </p>
        </Col>
        <Col xs={12} style={{ maxWidth: '320px' }} className="mx-auto">
          <ProgressBar 
            now={progress} 
            style={progressBarStyle}
            variant="custom"
          >
            <ProgressBar 
              now={progress} 
              style={progressStyle}
            />
          </ProgressBar>
        </Col>
      </Row>
    </Container>
  );
};

const style = document.createElement('style');
style.textContent = `
  .letter-spacing-2 {
    letter-spacing: 2px;
  }
  .progress-bar-custom {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .progress {
    border-radius: 0;
  }
`;
document.head.appendChild(style);

export default HomeScreen;