import { Button, message, Tooltip } from "antd";
import { Col, Row } from "react-bootstrap";
import { BsCopy } from "react-icons/bs";
import { CiEdit, CiMail } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import { IoCheckbox, IoStopOutline } from "react-icons/io5";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import API from "../../config/api";
import NoImage from "./../../assets/images/noimage.jpg";

import { defult } from "../../redux/slices/eventsSlice";
import { useState } from "react";
import URLMailing from "../investorScreen/components/urlmailing";
import { POST } from "../../utils/apiCalls";
function EventMore(props: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const User = useSelector((state: any) => state.User);
  const Events = useSelector((state: any) => state.Events);

  const [formModel, setFormModel] = useState(false);
  const [mail, setMail] = useState(false);
  const [loading, setLoading] = useState(false);

  const copyEvent = (uuid: any, slug: any) => {
    let link = `${API.WEB_URL}/event/${uuid}/${slug}`;
    navigator.clipboard.writeText(link).then(() => {
      message.success("Link copied");
    });
  };

  const onFinish = async (data: any) => {
    try {
      setLoading(true);
      let link = `${API.WEB_URL}/event/${props?.data?.defult?.uuid}/${props?.data?.defult?.slug}`;
      let obj = {
        image: props?.data?.defult?.image,
        name: props?.data?.defult?.title,
        url: link,
        subject: "Scan the QRcode and fill out the form.",
        to: data?.mail,
      };
      let response: any = await POST(API.URL_SHARE_MAIL, obj);
      if (response.status) {
        setFormModel(false);
        message.success(response.message);
      } else {
        message.error("Oops.something gone wrong.");
      }
      setLoading(false);
    } catch (err) {
      message.error("Oops.something gone wrong.");
      setLoading(false);
    }
  };

  const sendWhatsapp = (val: any) => {
    const message = `Click here to fill out the form ${API.WEB_URL}/event/${props?.data?.defult?.uuid}/${props?.data?.defult?.slug}`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${val?.countryCode + val?.mail
      }?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="EventMore-Box">
      <div className="SideBar-eventBox" style={{ marginBottom: 5 }}>
        <div>
          <img
            src={props?.data?.defult?.image || NoImage}
            style={{ height: 30, width: 30 }}
          />
        </div>
        <div className="SideBar-txt">{props?.data?.defult?.slug}</div>
        {/* <div> */}
        {/* <IoCheckbox size={20} color="green" /> */}
        {Array.isArray(User?.user.access[Events?.defult?.id]) &&
               User?.user.access[Events?.defult?.id].includes(8) ? null :
        <Tooltip
          placement="right"
          title={<div style={{ fontSize: 10 }}>Edit event</div>}
        >
          <Button
            icon={<CiEdit size={20} color="grey" />}
            onClick={() =>
              navigate(`/company/events_from/${props?.data?.defult?.id}`)
            }
          />
        </Tooltip>
}
        {/* </div> */}
      </div>
      {Array.isArray(User?.user.access[Events?.defult?.id]) &&
               User?.user.access[Events?.defult?.id].includes(8) ? null :
               <>
      <div style={{ margin: 15 }} />
      <Row>
        
        <Col sm={4}>
          <Tooltip
            placement="bottom"
            title={<div style={{ fontSize: 10 }}>Copy event link</div>}
          >
            <Button
              block
              onClick={() =>
                copyEvent(props?.data?.defult?.uuid, props?.data?.defult?.slug)
              }
              icon={<BsCopy size={18} color="grey" />}
            />
          </Tooltip>
        </Col>
        <Col sm={4}>
          <Tooltip
            placement="bottom"
            title={<div style={{ fontSize: 10 }}>Share link via whatsapp</div>}
          >
            <Button
              block
              icon={<FaWhatsapp size={20} color="grey" />}
              onClick={() => setFormModel(true)}
            />
          </Tooltip>
        </Col>
        <Col sm={4}>
          <Tooltip
            placement="bottom"
            title={<div style={{ fontSize: 10 }}>Share link via mail</div>}
          >
            <Button
              block
              icon={<CiMail size={20} color="grey" />}
              onClick={() => {
                setFormModel(true);
                setMail(true);
              }}
            />
          </Tooltip>
        </Col>
      </Row>
      </>}
      <br />
      <div className="EventMore-text1">My Events</div>
      {props?.data?.events?.length
        ? props?.data?.events
            ?.filter((item: any) => {
              const userAccess = User?.user?.access[item.id] || [];
              const accessRoot = User?.user?.access?.root;
              return (
                item.id !== props?.data?.defult?.id &&
                (accessRoot === "admin" || userAccess.length > 0)
              );
            })
            .map((item: any) => {
              const isDisabled = !item.status;

              return (
                <div
                  key={item?.id}
                  className={`EventMore-item ${isDisabled ? "disabled" : ""}`}
                  onClick={() => {
                    if (!isDisabled) {
                      dispatch(defult(item));
                      navigate("/company/dashboard");
                    }
                  }}
                  style={{
                    pointerEvents: isDisabled ? "none" : "auto",
                    opacity: isDisabled ? 0.5 : 1,
                    cursor: isDisabled ? "not-allowed" : "pointer",
                  }}
                >
                  <div>
                    <img
                      src={item.image || NoImage}
                      style={{
                        height: 30,
                        width: 30,
                        filter: isDisabled ? "grayscale(100%)" : "none",
                      }}
                    />
                  </div>
                  <div className="SideBar-txt2">{item?.slug}</div>
                  <div>
                    <IoStopOutline
                      size={20}
                      color={isDisabled ? "lightgrey" : "grey"}
                    />
                  </div>
                </div>
              );
            })
        : null}
      <div
        className="SideBar-txt2 view-more"
        onClick={() => navigate("/company/events?page=1&limit=6")}
      >
        View more events&nbsp;
        <MdOutlineKeyboardDoubleArrowRight size={20} className="icon" />
      </div>

      {formModel ? (
        <URLMailing
          visible={formModel}
          title={mail ? "Send the URL via email" : "Send the URL on WhatsApp"}
          data={10}
          onClose={() => {
            setFormModel(!formModel);
            setMail(false);
          }}
          mail={mail}
          onFinish={(val: any) => (mail ? onFinish(val) : sendWhatsapp(val))}
          loading={loading}
        />
      ) : null}
    </div>
  );
}

export default EventMore;
