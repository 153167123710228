import "./styles.scss";
import moment from "moment";
import Dropzone from "react-dropzone";
import { RiImageAddLine } from "react-icons/ri";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { Input, message } from "antd";

const ImagePicker = (props: any) => {
  const handleDrop = (acceptedFiles: any) => {
    try {
      const maxFileSize = 3 * 1024 * 1024;
      var myFile = acceptedFiles[0];
      if (!myFile.type.startsWith("image/")) {
        message.error("Only image files are allowed.");
      } else {
        if (myFile.size > maxFileSize) {
          message.error("File size exceeded the 3MB limit.");
        } else {
          let name = moment(new Date()).unix();
          const myNewFile = new File([myFile], name + "G.png", {
            type: myFile.type,
          });
          const url = URL.createObjectURL(myNewFile);
          let obj = {
            file: myNewFile,
            url: url,
          };
          props?.onChange(obj);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div>
      <Dropzone onDrop={handleDrop}
        accept={props?.formats?.reduce((acc: any, format: string) => {
          acc[format] = [];
          return acc;
        }, {})}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps({
                className: "ImagePicker-Box",
              })}
            >
              <input {...getInputProps()} />
              <div>
                {props?.fileURL ? (
                  <div className="imagePicker_text"> 
                    <IoCheckmarkCircleOutline size={20} color="green" />
                    Change Logo
                  </div>
                ) : (
                  <div className="imagePicker_placeholder">Choose Logo</div>
                )}
              </div>
              <div style={{ flex: 1 }} />
              <div>
                {props?.fileURL ? (
                  <img src={props?.fileURL} style={{ width: 20, height: 20 }} />
                ) : (
                  <RiImageAddLine size={20} color="#000" />
                )}
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};

export default ImagePicker;
