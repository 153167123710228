function AssociateItem(props: any) {
  return (
    <div className="VcDetailsScreen-AssociateItem" key={props?.key}>
      <div className="VcDetailsScreen-text4">{props?.item?.company}</div>
      <div className="VcDetailsScreen-text2">{props?.item?.fullName}</div>
      <div className="VcDetailsScreen-text2">{props?.item?.jobTitle}</div>
    </div>
  );
}

export default AssociateItem;
