import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Modal,
  Result,
  Select,
  Tag,
  Empty,
  message,
} from "antd";
import { Col, Row } from "react-bootstrap";
import Slots from "../../../config/timeSlots.json";
import Defult from "../../settingScreen/meetingMail/defult.json";
import TimeSlots from "./timeSlots";
import { GENERATE_SLOTS } from "../helpers/generateSlots";

import { GET, POST } from "../../../utils/apiCalls";
import API from "../../../config/api";
import { COMBINE_SLOTS } from "../helpers/combineSlots";
import StartupItem from "./startupItem";
import ReactQuill from "react-quill";
import EmailContent from "../helpers/mailTemplate";
import NoImage from "../../../assets/images/noimage.jpg";
import { FrownOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
function Meetings(props: any) {
  const [loading, setloading] = useState(true);
  const Events = useSelector((state: any) => state.Events);
  const [loading2, setloading2] = useState(false);
  const [loading3, setloading3] = useState(false);
  const [loading4, setloading4] = useState(false);
  const [checkedSlot, setCheckedSlot] = useState<any>(null);
  const [slots, setSlots] = useState([]);
  const [meetings, setMeetings] = useState<any>([]);
  const [counts, setCounts] = useState({ total: 0, assigned: 0 });
  const [roomData, setRoomData] = useState([]) as any;
  const [sendMail, setSendMail] = useState(false);
  const [sendIcs, setSendIcs] = useState(true);
  const [sendMailcc, setSendMailcc] = useState(true);

  const [defultMail, setDefultMail] = useState<any>(Defult);
  const [mailTemplate, setMailTemplate] = useState(false);
  const [mailData, setMailData] = useState<any>(null);
  const [meetingRoom, setMeetingRoom] = useState<any>(
    props?.data?.meeting_room
  );

  const showSlot = (value: any) => {
    let slot = Slots?.filter((i: any) => i.value === value);
    return slot[0]?.label;
  };

  useEffect(() => {
    loadData();
    getMail();
    getMeetingRooms();
  }, []);

  const getMail = async () => {
    try {
      let api = API?.MAIL_TEMPLATE_LIST + `${Events?.defult?.id}/meeting`;
      const response: any = await GET(api, null);
      if (response?.status) {
        if (response?.data?.length) {
          setDefultMail(response?.data[0]);
        }
      }
    } catch (err) {}
  };

  const loadData = async () => {
    try {
      let url =
        API.INVESTORS_DETAILS +
        props?.data?.uuid +
        `?eventId=${Events?.defult?.id}`;
      var res: any = await GET(url, null);
      if (res.status) {
        createSlots(
          res.data?.data?.timeSlots,
          30,
          "13-14",
          res?.data?.meetings
        );
      } else {
        message.error("Something went wrong...!");
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setloading(false);
    }
  };

  const createSlots = async (
    slots: any,
    period: any,
    exclusions: any,
    meetings: any
  ) => {
    try {
      let slotData: any = await GENERATE_SLOTS(slots, period, exclusions);
      let combine: any = await COMBINE_SLOTS(slotData, meetings);
      setSlots(combine);
      setCounts({ total: slotData.length, assigned: meetings.length });
      setloading(false);
    } catch (err) {
      console.log("err", err);
    }
  };

  const checkMeetings = async (slot: any) => {
    try {
      setCheckedSlot(slot);
      setloading2(true);
      if (slot?.startups?.id) {
        setMeetings([slot?.startups]);
      } else {
        let url = API.STARTUPS_FREESLOT + `${Events?.defult?.id}/` + slot?.slot;
        var response: any = await GET(url, null);
        if (response.status) {
          const filteredStartups = response?.data?.filter(
            (startup: any) =>
              !slots?.some(
                (meeting: any) => meeting?.startup_id === startup?.id
              )
          );
          setMeetings(filteredStartups);
        } else {
          setMeetings([]);
        }
      }
      setloading2(false);
    } catch (err) {
      console.log("err", err);
      setloading2(false);
    }
  };

  const selectStartups = (item: any, method: any) => {
    try {
      setloading2(true);
      var slotso: any = slots;
      let findInxed = slotso.findIndex(
        (i: any) => i?.slot === checkedSlot?.slot
      );
      if (findInxed >= 0) {
        var obj = slotso[findInxed];
        if (method === "add") {
          obj = {
            id: null,
            uuid: null,
            slot: checkedSlot?.slot,
            investor_name: props?.data?.fullname,
            startup_name: item?.name,
            status: true,
            investor_id: props?.data?.id,
            startup_id: item?.id,
            createdAt: null,
            updatedAt: null,
            room: null,
            startups: {
              id: item?.id,
              logo: item?.logo,
              name: item?.name,
              country: item?.country,
              category: item?.category,
            },
          };
        } else {
          obj = {
            id: null,
            uuid: null,
            slot: checkedSlot?.slot,
            investor_name: null,
            startup_name: null,
            status: false,
            investor_id: null,
            startup_id: null,
            createdAt: null,
            updatedAt: null,
            room: null,
            startups: {
              id: null,
              logo: item?.logo,
              name: item?.name,
              country: item?.country,
              category: item?.category,
            },
          };
          checkMeetings(checkedSlot);
        }
        slotso[findInxed] = obj;
        setSlots(slotso);
        setMeetings([obj?.startups]);
        setCheckedSlot(checkedSlot);
        setTimeout(() => {
          setloading2(false);
        }, 10);
      }
    } catch (err) {
      console.log("err", err);
      setloading2(false);
    }
  };

  const emailEditor = async () => {
    try {
      let obj = {
        user: props?.data,
        meetings: slots,
        eventId: Events?.defult?.id,
        location: Events?.defult?.location,
        event_date: Events?.defult?.event_date,
        room: meetingRoom,
        content: defultMail?.content,
      };
      let mailData = await EmailContent(obj);
      console.log(mailData);
      setMailData(mailData);
      setMailTemplate(true);
    } catch (err) {
      console.log("err", err);
    }
  };
  const submit = async () => {
    try {
      setloading3(true);
      let vc = {
        ...props?.data,
        date:Events?.defult?.event_date ?  dayjs(Events?.defult?.event_date).format("DD-MM-YYYY") : "" ,
        timeSlots: props?.data?.timeSlots,
        prodId: "vcmeets.hub71.com",
        id: props?.data?.eventId,
        profileId: props?.data?.id,
        domain: "vcmeets.hub71.com",
        organizerName: "VC Meets",
        organizerEmail: "donotreply@vcmeets.hub71.com",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, 
        summary:Events?.defult?.name||"VC Meets",
        location: Events?.defult?.location,
        description:Events?.defult?.sub_title,
      };
      let obj = {
        id: props?.data?.id,
        vc: vc,
        slots: slots,
        sendMail: sendMail,
        mailData: mailData,
        to: props?.data?.email,
        subject: defultMail?.subject,
        contect: mailData,
        sendIcs: sendIcs,
        sendMailcc: sendMailcc,
        eventId: Events?.defult?.id,
        link: `${API.WEB_URL}/vc-details/${props?.data?.uuid}?event=${Events?.defult?.id}`,
      };
      let response: any = await POST(API.MEETING_BULK_CREATE, obj);
      if (response?.status) {
        message.success("Meetings scheduled successfully ");
        loadData();
        props.onClose();
      } else {
        message.error("Something went wrong...!");
      }
      setloading3(false);
    } catch (err) {
      console.log("err", err);
      message.error("Something went wrong...!");
      setloading3(false);
    }
  };

  const updateRoom = async (room: any) => {
    try {
      setloading4(true);
      setMeetingRoom(room);
      let obj = {
        id: props?.data?.id,
        room_id: room,
        room: roomData.find((item: any) => item.id === room)?.name,
      };
      let response: any = await POST(API.INVESTORS_SET_ROOM, obj);
      if (response?.status) {
        message.success("Room updated successfully ");
      } else {
        message.error("Something went wrong...!");
      }
      setloading4(false);
    } catch (err) {
      message.error("Something went wrong...!");
      setloading4(false);
    }
  };
  const getMeetingRooms = async () => {
    try {
      setloading2(true);
      let url = API.ROOMS_PICKER + Events?.defult?.id;
      const response: any = await GET(url, null);
      if (response?.status) {
        setRoomData(response?.data);
      } else {
        setRoomData([]);
      }
    } catch (err) {
      console.log(err, "err");
    } finally {
      setloading2(false);
    }
  };
  return (
    <Modal
      title={"Schedule Meetings"}
      open={props.visible}
      onCancel={() => props.onClose()}
      footer={false}
      centered
      closable={!loading3}
      maskClosable={!loading3}
      width={900}
    >
      <div className="investorScreen-Meetings">
        <div className="investorScreen-Box">
          <div>
            <img
              src={props?.data?.logo || NoImage}
              style={{ width: 100, height: 70, objectFit: "contain" }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <div className="investorScreen-text1">{props?.data?.company}</div>
            <div className="investorScreen-text2">{props?.data?.fullname}</div>
            <div className="investorScreen-text2">{props?.data?.jobTitle}</div>
          </div>
          <div className="investorScreen-Box2">
            <Tag style={{ width: 170 }}>{showSlot(props?.data?.timeSlots)}</Tag>
            <div>
              Meeting Room :{" "}
              <Select
                showSearch
                style={{ width: 170, marginRight: 8 }}
                placeholder="Select Room"
                onChange={(value: any) => updateRoom(value)}
                allowClear
                loading={loading4}
                value={meetingRoom}
                optionFilterProp="children"
                defaultValue={meetingRoom}
              >
                {roomData?.map((item: any) => {
                  return (
                    <Select.Option key={item?.id} value={item?.id}>
                      {item?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
        <br />
        {mailTemplate ? (
          <Form>
            <Form.Item>
              <ReactQuill
                style={{ height: 300 }}
                theme="snow"
                value={mailData}
                onChange={setMailData}
              />
            </Form.Item>
            <br />
            <div style={{ margin: 10 }} />
            <Row>
              <Col sm={6}></Col>
              <Col sm={3}>
                <Form.Item
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <Checkbox
                  disabled={loading3}
                    checked={sendMailcc}
                    onChange={(value: any) =>
                      setSendMailcc(value?.target?.checked)
                    }
                  >
                    Send To Attendees
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col sm={3}>
                <Form.Item
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <Checkbox
                  disabled={loading3}
                    checked={sendIcs}
                    onChange={(value: any) =>
                      setSendIcs(value?.target?.checked)
                    }
                  >
                    Attach .ics
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          <Row style={{ marginBottom: 20 }}>
            <Col sm={6}>
              <Card
                size="small"
                title={"Slots"}
                extra={slots?.length}
                loading={loading}
              >
                <div className="investorScreen-scrollBox">
                  {slots?.length
                    ? slots.map((item: any, index: any) => {
                        return (
                          <TimeSlots
                            key={index}
                            item={item}
                            checked={checkedSlot}
                            onChange={(value: any) => checkMeetings(value)}
                          />
                        );
                      })
                    : null}
                </div>
              </Card>
            </Col>
            <Col sm={6}>
              <Card
                size="small"
                title={"Startups"}
                loading={loading2}
                extra={meetings?.length}
              >
                <div className="investorScreen-scrollBox">
                  {meetings?.length ? (
                    meetings?.map((item: any, index: any) => {
                      return (
                        <StartupItem
                          key={index}
                          item={item}
                          checked={slots.filter(
                            (i: any) =>
                              i?.startups?.id === item?.id &&
                              i?.slot === checkedSlot?.slot
                          )}
                          onChange={(value: any) =>
                            selectStartups(value, "add")
                          }
                          remove={(value: any) =>
                            selectStartups(value, "remove")
                          }
                        />
                      );
                    })
                  ) : (
                    <div className="investorScreen-text5">
                      {checkedSlot ? (
                        <Empty description={"No startup available"} />
                      ) : (
                        "Select a time slot"
                      )}
                    </div>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={6}>
            <Checkbox
            disabled={loading3}
              onChange={(value: any) => {
                setSendMail(value?.target?.checked);
                value?.target?.checked
                  ? setSendMail(value?.target?.checked)
                  : setMailTemplate(value?.target?.checked);
              }}
            >
              Send Mail Notification
            </Checkbox>
          </Col>
          <Col sm={3}>
            <Button size="large" block danger disabled={loading3} onClick={() => props.onClose()}>
              Close
            </Button>
          </Col>
          <Col sm={3}>
            <Button
              size="large"
              type="primary"
              block
              loading={loading3}
              onClick={() =>
                sendMail ? (mailTemplate ? submit() : emailEditor()) : submit()
              }
            >
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default Meetings;
