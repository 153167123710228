import { Button, Popconfirm, Tooltip, message } from "antd";
import { DELETE } from "../../../utils/apiCalls";
import API from "../../../config/api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function SlotsItem(props: any) {
  const Events = useSelector((state: any) => state.Events);
  const user = useSelector((state: any) => state.User.user);
  const [start, end] = props?.item?.slot?.split(" - ");
  const navigation = useNavigate();
  const deleteItem = async (id: any) => {
    try {
      let response: any = await DELETE(API.MEETINGS_DELETE + id);
      if (response?.status) {
        message.success("Meeting is deleted");
        props.onChange();
      } else {
        message.error("Oops.something gone wrong.");
      }
    } catch (err) {
      message.error("Oops.something gone wrong.");
    }
  };

  return (
    <div className="investorScreen-SlotsItem">
      <div
        className="investorScreen-Slotstext1"
        style={{ borderLeftColor: props?.item?.id ? "#3dd966" : "#d93d3d" }}
      >
        <div>{start}</div>
        <div style={{ margin: 19 }} />
        <div>{end}</div>
      </div>
      <div className="investorScreen-SlotsItem2" style={{ cursor: "pointer" }} onClick={()=> navigation(`/company/staupdetails/${props?.item?.startups?.id}`)}>
        {props?.item?.startups?.logo ? (
          <div>
            <img
              src={props?.item?.startups?.logo}
              className="investorScreen-SlotsImg"
            />
          </div>
        ) : null}

        <div>
          <div className="investorScreen-Slotstext2">
            {props?.item?.startup_name}
          </div>
          {props?.item?.startups?.category ? (
            <div className="investorScreen-Slotstext3">
              {props?.item?.startups?.category}
            </div>
          ) : null}
        </div>
      </div>
      <div>
     {Array.isArray(user.access[Events?.defult?.id]) &&
               user.access[Events?.defult?.id].includes(8) ? null : 
        props?.item?.id ? (
          <Popconfirm
            title="Delete the meeting"
            description="Are you sure to delete this meeting?"
            onConfirm={() => deleteItem(props?.item?.id)}
            okText="Yes"
            cancelText="No"
            placement="leftBottom"
          >
            <Button size="small" type="default" danger>
              Delete Meeting
            </Button>
          </Popconfirm>
        ) : (
          <Button
            size="small"
            type="link"
            onClick={() => props?.Allocate(props?.item?.slot)}
          >
            Assign Startup
          </Button>
        )}
      </div>
    </div>
  );
}

export default SlotsItem;
