import { Checkbox } from "antd";
import { BsClockHistory } from "react-icons/bs";
import { IoCheckmarkCircle } from "react-icons/io5";
function TimeSlots(props: any) {
  return (
    <div
      className="investorScreen-timeSlot"
      onClick={() => props?.onChange(props?.item)}
    >
      <div>
        {props?.item?.startups?.id ? (
          <IoCheckmarkCircle color="#3dd966" size={20} />
        ) : (
          <BsClockHistory />
        )}
      </div>
      <div style={{ flex: 1 }}>
        <div>{props?.item.slot}</div>
      </div>
      <div>
        <Checkbox
          checked={props?.item.slot === props?.checked?.slot ? true : false}
        />
      </div>
    </div>
  );
}

export default TimeSlots;
