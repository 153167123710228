import { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  message,
  DatePicker,
  Switch,
  Select,
  Tooltip,
  Image,
} from "antd";
import { Col, Row } from "react-bootstrap";
import countries from "../../../components/countries.json";
import API from "../../../config/api";
import { COMPRESS_IMAGE, POST } from "../../../utils/apiCalls";
import ImagePicker from "../../../components/imagePicker";
import { IoIosCloseCircleOutline } from "react-icons/io";
import dayjs from "dayjs";
function Forms(props: any) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [image_url, setImageUrl] = useState<any>(props?.data?.logo);
  const [file, setFile] = useState<any>();

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      let url = props?.data?.id ? API.COMPANIES_EDIT : API.COMPANIES_ADD;
      let images: any = await COMPRESS_IMAGE(file);
      let obj = {
        id: props?.data?.id,
        name: values.name,
        logo: file?.name ? images?.url : image_url,
        description: values?.description,
        owner: values?.owner,
        country: values?.country,
        email: values?.email,
        phoneNumber: values?.phoneNumber,
        subscription: values?.subscription,
        amount: values?.amount,
        expiry_date: dayjs(values?.expiry_date),
        status: values.status === false ? false : true,
        password: values?.password,
        countryCode: values?.countryCode,
      };
      let response: any = await POST(url, obj);
      if (response.status) {
        props.onChange();
        props.onClose();
        message.success("Successfully submitted");
      } else {
        message.error(response.message);
      }
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      message.error("Oops.something gone wrong.");
      setLoading(false);
    }
  };
  const cascaderOptions = countries?.map((country) => ({
    flag: country?.flag,
    value: country?.dial_code,
    label: `${country.dial_code}`,
    name: country?.name,
  }));
  const disablePastDates = (current: any) => {
    return current && current < dayjs().startOf("day");
  };
  return (
    <Modal
      title={`${props?.data?.id ? "Edit" : "New"} Company`}
      open={props.visible}
      onCancel={() => props.onClose()}
      footer={false}
      centered
      width={800}
      maskClosable={false}
      closable={!loading} 
    >
      <Form
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        initialValues={{
          id: props?.data?.id,
          name: props?.data?.name,
          logo: props?.data?.logo,
          description: props?.data?.description,
          owner: props?.data?.owner,
          country: props?.data?.country,
          email: props?.data?.email,
          countryCode: props?.data?.countrycode || "+971",
          phoneNumber: props?.data?.phoneNumber,
          subscription: props?.data?.subscription,
          amount: props?.data?.amount,
          expiry_date: props?.data?.expiry_date
            ? dayjs(props?.data?.expiry_date)
            : null,
          status: props?.data?.status,
        }}
      >
        <Row>
          <Col sm={6}>
            <div className="label">Company Name *</div>
            <Form.Item
              name={"name"}
              rules={[{ required: true, message: "Please enter company name" }]}
            >
              <Input placeholder="Company Name" type="text" size="large" />
            </Form.Item>

            <div className="label">Email *</div>
            <Form.Item
              name={"email"}
              rules={[
                { type: "email", message: "Enter a valid email" },
                { required: true, message: "Plese enter email" },
              ]}
            >
              <Input placeholder="Email" size="large" type="email" />
            </Form.Item>
            <div className="label">Phone Number *</div>
            <Form.Item
              name={"phoneNumber"}
              rules={[
                { required: true, message: "Please enter phone number" },
                {
                  pattern:
                    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
                  message: "Enter a valid phone number!",
                },
              ]}
            >
              <Input
                type="tel"
                style={{ width: "100%" }}
                placeholder="Phone Number"
                onKeyPress={(event) => {
                  if (!/^[0-9]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                addonBefore={
                  <Form.Item
                    noStyle
                    name="countryCode"
                    rules={[{ required: true, message: "" }]}
                  >
                    <Select
                      // size="large"
                      style={{ width: 100 }}
                      showSearch
                      dropdownStyle={{ width: 100 }}
                      allowClear
                    >
                      {cascaderOptions?.map((item: any, index: number) => {
                        return (
                          <Select.Option key={index} value={item?.value}>
                            <div className="d-flex align-items-center gap-2">
                              <Image
                                preview={false}
                                width={31}
                                alt={item?.code}
                                src={`https://flagsapi.com/${item?.flag}/flat/24.png`}
                              />
                              {item?.label}
                            </div>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                }
                size="large"
              />
            </Form.Item>

            <div className="label d-flex justify-content-between">
              Company Logo
              {image_url ? (
                <Tooltip placement="top" title={"Remove logo"}>
                  <IoIosCloseCircleOutline
                    color="red"
                    size={20}
                    onClick={() => {
                      setImageUrl(null);
                      setFile(null);
                    }}
                  />
                </Tooltip>
              ) : null}
            </div>

            <Form.Item name={"logo"}>
              <ImagePicker
                onChange={(value: any) => {
                  setImageUrl(value?.url);
                  setFile(value?.file);
                }}
                fileURL={image_url}
              />
            </Form.Item>
            <div className="label">Description *</div>
            <Form.Item
              name={"description"}
              rules={[
                { required: true, message: "Please enter description" },
                {
                  max: 255,
                  message: "Description cannot exceed 255 characters",
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder="Description"
                size="large"
                maxLength={255}
              />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <div className="label">Country *</div>
            <Form.Item
              name="country"
              rules={[
                {
                  required: true,
                  message: "Please select a country",
                },
              ]}
            >
              <Select placeholder={"Country"} showSearch={true} size="large">
                {cascaderOptions?.map((item: any, index: number) => {
                  return (
                    <Select.Option key={index} value={item?.name}>
                      <div className="d-flex align-items-center gap-2">
                        <Image
                          preview={false}
                          width={31}
                          alt={item?.code}
                          src={`https://flagsapi.com/${item?.flag}/flat/24.png`}
                        />
                        {item?.name}
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <div className="label">Owner *</div>
            <Form.Item
              name={"owner"}
              rules={[
                { required: true, message: "Please enter owner name" },
                {
                  pattern: /^[a-zA-Z ]+$/,
                  message: "Enter a valid name",
                },
              ]}
            >
              <Input
                placeholder="Owner"
                size="large"
                type="text"
                onKeyPress={(event) => {
                  if (!/^[a-zA-Z ]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            {/* <div className="label">Subscription *</div>
            <Form.Item
              name={"subscription"}
              rules={[{ required: true, message: "Please select a subscription" }]}
            >
              
               <Select
                placeholder={"Subscription"}
                showSearch={true} 
               
                size="large"
              >
                
                  <Select.Option value={"Plan 1"}>
                  Plan 1
                  </Select.Option>
              
              </Select>
            </Form.Item> */}
            {/* <div className="label">Amount *</div>
            <Form.Item
              name={"amount"}
              rules={[{ required: true, message: "Please enter an amount" }]}
            >
              <Input
                placeholder="Amount"
                size="large"
                onKeyPress={(event) => {
                  if (!/^[a-zA-Z0-9,.₹£$€¥₩₽₪₹₦د.إDH]$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item> */}
            {/* <div className="label">Expiry Date *</div>
            <Form.Item
              name={"expiry_date"}
              rules={[{ required: true, message: "Please select an expiry date" }]}
            >
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                disabledDate={disablePastDates}
              />
            </Form.Item> */}

            {props?.data?.id ? null : (
              <>
                <div className="label">Password *</div>
                <Form.Item
                  name={"password"}
                  rules={[
                    { required: true, message: "Please enter password" },
                    {
                      min: 8,
                      message: "Password must be at least 8 characters long",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" size="large" />
                </Form.Item>
                <div className="label">Confirm Password *</div>
                <Form.Item
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: props?.data?.id ? false : true,
                      message: "Please confirm your password",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("The passwords you entered do not match!")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" size="large" />
                </Form.Item>
              </>
            )}
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <div className="label">Status</div>
            <Form.Item name={"status"}>
              <Switch
              disabled={loading}
                defaultChecked={props?.data?.status === false ? false : true}
              />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <br />
            <Row>
              <Col sm={6}>
                <Button block size="large" onClick={() => props.onClose()} disabled={loading}>
                  Close
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  htmlType="submit"
                  block
                  size="large"
                  type="primary"
                  loading={loading}
                  disabled={loading}
                >
                  Done
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Forms;
