import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Input, Modal, message, Select } from "antd";
import { IoSendOutline, IoMailOutline } from "react-icons/io5";
import { Row, Col } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import API from "../../../config/api";
import { GET, POST } from "../../../utils/apiCalls";

function Mailing(props: any) {
  const Events = useSelector((state: any) => state.Events);
  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const [loading2, setLoading2] = useState(true);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      let api = API?.MAIL_TEMPLATE_LIST + `${Events?.defult?.id}/custom`;
      const response: any = await GET(api, null);
      if (response?.status) {
        setTemplates(response?.data);
        setLoading2(false);
      } else {
        setTemplates([]);
        setLoading2(false);
      }
    } catch (err) {
      setTemplates([]);
      setLoading2(false);
    }
  };

  const submit = async (data: any) => {
    try {
      setLoading(true);
      let obj = {
        id: props?.data?.id,
        to: data?.mail,
        subject: data?.subject,
        contect: value,
      };
      let response: any = await POST(API.INVESTORS_MAILING, obj);
      if (response.status) {
        props.onClose();
        message.success("Successfully submitted");
      } else {
        message.error("Oops.something gone wrong.");
      }
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      message.error("Oops.something gone wrong.");
      setLoading(false);
    }
  };

  const selectTemplate = (value: any) => {
    try {
      var index = templates.findIndex((i: any) => i.id === value);
      if (index >= 0) {
        var obj: any = templates[index];
        form.setFieldsValue({
          subject: obj?.subject,
          contect: obj?.content,
        });
        setValue(obj?.content);
        setLoading2(true);
        setTimeout(() => {
          setLoading2(false);
        }, 10);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <Modal
      title={"Send Email"}
      open={props.visible}
      onCancel={() => props.onClose()}
      footer={false}
      centered
      width={700}
    >
      <Form
        form={form}
        scrollToFirstError
        onFinish={submit}
        initialValues={{
          mail: props?.data?.email,
        }}
      >
        <div className="label">To</div>
        <Form.Item
          name="mail"
          rules={[
            { required: true, message: "Missing Email" },
            {
              type: "email",
              message: "Email not valid email",
            },
          ]}
        >
          <Input
            value={props?.data?.email}
            size="large"
            prefix={
              <IoMailOutline
                style={{ marginRight: 10 }}
                size={20}
                color="blue"
              />
            }
          />
        </Form.Item>
        <div className="label">Select Template</div>
        <Form.Item>
          <Select
            size="large"
            placeholder="Mail Templates"
            loading={loading2}
            showSearch
            allowClear
            onChange={(value) => selectTemplate(value)}
          >
            {templates?.map((item: any) => {
              return (
                <Select.Option key={item?.id} value={item?.id}>
                  {item?.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <div className="label">Subject</div>
        <Form.Item
          name={"subject"}
          rules={[{ required: true, message: "Subject Required" }]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name={"contect"}
          rules={[{ required: true, message: "Contect Required" }]}
        >
          {/* <div style={{ display: "block" }}>{value}</div> */}
          <ReactQuill
            style={{ height: 250 }}
            theme="snow"
            value={value}
            onChange={(val) => {
              setValue(val);
              form.setFieldsValue({ contect: val });
            }}
          />
        </Form.Item>
        <br /> <br />
        <Row>
          <Col sm={6}></Col>
          <Col sm={3}>
            <Button size="large" block danger onClick={() => props.onClose()}>
              Close
            </Button>
          </Col>
          <Col sm={3}>
            <Button
              size="large"
              type="primary"
              block
              htmlType="submit"
              icon={<IoSendOutline />}
              loading={loading}
            >
              Send
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Mailing;
