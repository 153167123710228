const COMBINE_SLOTS = async (timeSlots: any, meetings: any) => {
  try {
    const assignedSlotsDict: any = {};
    const slotCount: any = {};
    meetings.forEach((slot: any) => {
      if (!assignedSlotsDict[slot.slot]) {
        assignedSlotsDict[slot.slot] = slot;
      } else {
        assignedSlotsDict[slot.slot].repeat = true;
      }
      slotCount[slot.slot] = (slotCount[slot.slot] || 0) + 1;
    });
    const duplicateSlots = Object.keys(slotCount).filter(
      (slot) => slotCount[slot] > 1
    );
    if (duplicateSlots.length > 0) {
      console.log("Duplicate slots found:", duplicateSlots);
    }
    const mergedSlots = timeSlots.map((slot: any) => {
      if (assignedSlotsDict[slot]) {
        return assignedSlotsDict[slot];
      } else {
        return {
          id: null,
          uuid: null,
          slot: slot,
          investor_name: null,
          startup_name: null,
          status: false,
          repeat: false,
          investor_id: null,
          startup_id: null,
          createdAt: null,
          updatedAt: null,
          room: null,
          investors: {
            id: null,
            logo: null,
            company: null,
            jobTitle: null,
          },
        };
      }
    });

    return mergedSlots;
  } catch (err) {
    return [];
  }
};

export { COMBINE_SLOTS };
