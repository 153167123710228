import { Checkbox } from "antd";
import NoImage from "../../../assets/images/noimage.jpg";
function StartupItem(props: any) {
  return (
    <div
      className="investorScreen-timeSlot"
      onClick={() =>
        props?.checked?.length
          ? props?.remove(props?.item)
          : props?.onChange(props?.item)
      }
    >
      <div>
        <img
          src={props?.item?.logo || NoImage}
          style={{
            width: 40,
            height: 40,
            objectFit: "contain",
            border: "1px solid rgb(216, 216, 216)",
          }}
        />
      </div>
      <div>
        <div className="investorScreen-Slotstext2">{props?.item?.name}</div>
        <div style={{ margin: -5 }} />
        {props?.item?.category ? (
          <div className="investorScreen-Slotstext3">
            {props?.item?.category}
          </div>
        ) : null}
      </div>
      <div style={{ flex: 1 }} />
      <div>
        <Checkbox
          checked={
            props?.checked?.length && props?.item?.id != null ? true : false
          }
        />
      </div>
    </div>
  );
}

export default StartupItem;
