import { Pagination, Popconfirm, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Rooms from "../../../config/rooms.json";
import { useNavigate } from "react-router-dom";
import { CiRead } from "react-icons/ci";
import { useSelector } from "react-redux";
function DataTable(props: any) {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.User.user);
  const Events = useSelector((state: any) => state.Events);

  const columns: any = [
    {
      title: "VC",
      dataIndex: "investor_name",
      key: "investor_name",
      render: (val: any, record: any) => {
        return (
          <div>
            {record?.investors?.company}
            <div className="DashboardScreen-text3">{record?.investor_name}</div>
          </div>
        );
      },
    },
    {
      title: "VC Status",
      render: (val: any, record: any) => {
        if (record?.vc_checkout) {
          return (
            <Tag color="red" style={{ minWidth: 110, textAlign: "center" }}>
              Checked Out
            </Tag>
          );
        } else if (record?.vc_checkin) {
          return (
            <Tag color="green" style={{ minWidth: 110, textAlign: "center" }}>
              Checked In
            </Tag>
          );
        } else {
          return (
            <Tag color="#cfc89b" style={{ minWidth: 110, textAlign: "center" }}>
              Not yet
            </Tag>
          );
        }
      },
    },
    {
      title: "Time Slot",
      dataIndex: "slot",
      key: "slot",
    },
    {
      title: "Room",
      dataIndex: "meeting_room",
      key: "meeting_room",
      // filters: Rooms.map((room) => ({ text: room?.value, value: room?.value })),
      // onFilter: (value: any, record: any) =>
      //   record?.investors?.meeting_room === value,
      // render: (val: any, record: any) => (
      //   <div>{record?.investors?.meeting_room}</div>
      // ),
    },

    // {
    //   title: "Attendee",
    //   dataIndex: "startup_name",
    //   key: "startup_name",
    //   render: (val: any, record: any) => (
    //     <div className="meetingScreen-tableCell">
    //       {record?.investors?.Attendees &&
    //         record?.investors?.Attendees.map((item: any, index: number) => (
    //           <span key={index}>
    //             {item?.fullName}{" "}
    //             {index < record?.investors?.Attendees?.length - 1 ? ", " : ""}
    //           </span>
    //         ))}
    //     </div>
    //   ),
    // },

    {
      title: "Startup",
      dataIndex: "startup_name",
      key: "startup_name",
    },
    {
      title: "Startup Status",
      render: (val: any, record: any) => {
        if (record?.su_checkout) {
          return (
            <Tag color="red" style={{ minWidth: 110, textAlign: "center" }}>
              Checked Out
            </Tag>
          );
        } else if (record?.su_checkin) {
          return (
            <Tag color="green" style={{ minWidth: 110, textAlign: "center" }}>
              Checked In
            </Tag>
          );
        } else {
          return (
            <Tag color="#cfc89b" style={{ minWidth: 110, textAlign: "center" }}>
              Not yet
            </Tag>
          );
        }
      },
    },
    {
      title: "Meeting Status",
      render: (val: any, record: any) => {
        if (record?.meeting_end) {
          return (
            <Tag color="red" style={{ minWidth: 110, textAlign: "center" }}>
              Ended
            </Tag>
          );
        } else if (record?.meeting_start) {
          return (
            <Tag
              color="green-inverse"
              style={{ minWidth: 110, textAlign: "center" }}
            >
              Started
            </Tag>
          );
        } else {
          return (
            <Tag color="#cfc89b" style={{ minWidth: 110, textAlign: "center" }}>
              Not yet started
            </Tag>
          );
        }
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (item: any) => (
        <div style={{ fontSize: 12 }}>
          {moment(item).format("MMM D, YYYY h:mm:ss A")}
        </div>
      ),
    },
    {
      title: "Actions",
      onCell: (record: any) => ({
        onClick: (event: any) => handleCellClick(event, "Actions", record),
      }),
      render: (item: any, record: any) => (
        <div className="table-item">
          <div onClick={() => navigate(`/company/meetings/${item.uuid}`)}>
            <Tooltip
              placement="top"
              title={<div style={{ fontSize: 10 }}>Meeting Details</div>}
            >
              <CiRead size={25} color="#000" />
            </Tooltip>
          </div>
          {Array.isArray(user.access[Events?.defult?.id]) &&
          user.access[Events?.defult?.id].includes(8) ? null : (
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                props?.delete(item?.id);
              }}
            >
              <div>
                <IoIosCloseCircleOutline size={25} color="red" />
              </div>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];
  const handleRowClick = (item: any) => {
    navigate(`/company/meetings/${item.uuid}`);
  };
  const handleCellClick = (event: any, column: any, record: any) => {
    if (column !== "Actions") {
      handleRowClick(record);
    } else {
      event.stopPropagation();
    }
  };
  return (
    <>
      <Table
        size="small"
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        style={{ cursor: "pointer" }}
        dataSource={props?.data || []}
        columns={columns}
        pagination={false}
        loading={props?.loading}
        scroll={{ x: 1000 }}
      />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.meta?.total_count}
          showSizeChanger
          showTotal={(total: any) =>
            `Total ${props?.meta?.total_count} Meetings`
          }
          onChange={(page, pageSize) => props.onPageChange(page, pageSize)}
          defaultCurrent={props?.meta?.page}
          defaultPageSize={props?.meta?.take}
        />
      </div>
    </>
  );
}

export default DataTable;
