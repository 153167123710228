import { Button, message, Popover, Switch } from "antd";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BsCopy } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { GrMoreVertical } from "react-icons/gr";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import API from "../../../config/api";
import { defult } from "../../../redux/slices/eventsSlice";
import NoImage from "../../../assets/images/noimage.jpg";
import EventDeleteModal from "./deleteModal";

function EventCard(props: any) {
  const dispatch = useDispatch();
  const navigate: any = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const User = useSelector((state: any) => state.User);
  const Events = useSelector((state: any) => state.Events);

  const selectEvent = () => {
    dispatch(defult(props?.item));

    const userAccess = User?.user?.access[props?.item?.id] || [];
    const accessRoot = User?.user?.access?.root;

    if (accessRoot === "admin") {
      navigate("/company/dashboard");
    } else if (userAccess.includes(1)) {
      navigate("/company/dashboard");
    } else if (userAccess.includes(2)) {
      navigate("/company/meetings?page=1&limit=10");
    } else if (userAccess.includes(3)) {
      navigate("/company/investors?page=1&limit=10");
    } else if (userAccess.includes(4)) {
      navigate("/company/startups?page=1&limit=10");
    } else if (userAccess.includes(5)) {
      navigate("/company/rooms?page=1&limit=10");
    } else if (userAccess.includes(6)) {
      navigate("/company/users?page=1&limit=10");
    } else if (userAccess.includes(7)) {
      navigate("/company/settings");
    } else {
      navigate("/company/invalid-link");
    }
  };
  const copyEvent = (uuid: any, slug: any) => {
    let link = `${API.WEB_URL}/event/${uuid}/${slug}`;
    navigator.clipboard.writeText(link).then(() => {
      message.success("Link copied");
    });
  };

  return (
    <div className="Event_Card h-100">
      <Row className="h-100 gy-3 d-flex flex-column justify-content-between">
        <Col
          sm={12}
          className="d-flex gap-4"
          onClick={() => (props?.item?.status ? selectEvent() : undefined)}
        >
          <div className="image-container">
            <img src={props?.item?.image || NoImage} alt={props?.item?.name} />
          </div>
          <div
            onClick={() => (props?.item?.status ? selectEvent() : undefined)}
            className="pt-1"
          >
            <div className="EventCard-text1">{props?.item?.name}</div>
            <div className="EventCard-text2">{props?.item?.title}</div>
          </div>
        </Col>
        <Col sm={12}>
          <div className="EventCard-Box1">
            <div>
              {Array.isArray(User?.user.access[props?.item?.id]) &&
              User?.user.access[props?.item?.id].includes(8) ? (
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => selectEvent()}
                    type="primary"
                    disabled={!props?.item?.status}
                  >
                    Open
                  </Button>
                </div>
              ) : (
                <div className="d-flex gap-4">
                  <div style={{ flex: 1 }}>
                    <div>
                      <Button type="text">
                        <Switch
                          onClick={() => props.toggleStatus(props?.item)}
                          checked={props?.item?.status}
                        />
                      </Button>
                    </div>
                  </div>
                  <div>
                    <Button
                      onClick={() => selectEvent()}
                      type="primary"
                      disabled={!props?.item?.status}
                    >
                      Open
                    </Button>
                  </div>
                  <div>
                    <Popover
                      placement="bottomRight"
                      content={
                        <div style={{ width: 120 }}>
                          <Button
                            block
                            icon={<IoIosCloseCircleOutline />}
                            onClick={() => setIsOpen(true)}
                            danger
                            disabled={!props?.item?.status}
                          >
                            Delete
                          </Button>
                          <Button
                            block
                            icon={<CiEdit />}
                            disabled={!props?.item?.status}
                            onClick={() =>
                              navigate(
                                `/company/events_from/${props?.item?.id}`
                              )
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            disabled={!props?.item?.status}
                            onClick={() =>
                              copyEvent(props?.item?.uuid, props?.item?.slug)
                            }
                            block
                            icon={<BsCopy />}
                          >
                            Link
                          </Button>
                        </div>
                      }
                    >
                      <Button
                        disabled={!props?.item?.status}
                        icon={<GrMoreVertical />}
                      ></Button>
                    </Popover>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      {isOpen && (
        <EventDeleteModal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          delete={() => props?.delete(props?.item?.id)}
        />
      )}
    </div>
  );
}

export default EventCard;
