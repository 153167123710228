import "../styles.scss";
function ProfileItem(props: any) {
  return (
    <div className="VcDetailsScreen-item">
      <div>{props?.icon}</div>
      <div>
        <div className="VcDetailsScreen-text3">{props?.label}</div>
        <div className="VcDetailsScreen-text4">{props?.value}</div>
      </div>
    </div>
  );
}

export default ProfileItem;
