import { Modal, message } from "antd";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import API from "../../../config/api";
import { GET } from "../../../utils/apiCalls";
import { BsPerson } from "react-icons/bs";
import { IoMdTime } from "react-icons/io";
import ProfileItem from "./profileItem";
import { BiCategoryAlt } from "react-icons/bi";
import { GoGlobe } from "react-icons/go";
import { LiaUserFriendsSolid } from "react-icons/lia";
import Loading from "../../../components/loading";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { PiUsersFour } from "react-icons/pi";

function CompanyInfo(props: any) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]) as any;

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      let url = API.STARTUPS_DETAILS + props?.item?.startups?.id;
      let res: any = await GET(url, null);
      if (res.status) {
        setData(res?.data?.data);
      } else {
        message.error("Something went wrong...!");
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={props.visible} onCancel={props.onCancel} footer={false}>
      <br />
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="VcDetailsScreen-text4" style={{ fontSize: 20 }}>
            {data?.name}
          </div>
          <div className="CompanyInfo-box1">
            <div style={{ border: "1px solid #f2f2f2", padding: 10 }}>
              <img
                src={data?.logo}
                style={{ width: 100, height: 80, objectFit: "contain" }}
              />
            </div>
          </div>
          <div className="startupScreen-text2">{data?.description}</div>
          <br />
          <Row>
            <Col xs={6}>
              <ProfileItem
                icon={<PiUsersFour size={20} color="#0026ab" />}
                label={"Members"}
                value={data?.members}
              />
            </Col>
            <Col xs={6}>
              <ProfileItem
                icon={<FaRegMoneyBillAlt size={20} color="#0026ab" />}
                label={"Fund Raised"}
                value={data?.fund_raised}
              />
            </Col>
          </Row>
          <hr />
          <ProfileItem
            icon={<BsPerson size={20} color="#0026ab" />}
            label={"Founder"}
            value={data?.founder}
          />
          <br /> <br />
          <ProfileItem
            icon={<BiCategoryAlt size={20} color="#0026ab" />}
            label={"Category"}
            value={data?.category}
          />
          <br /> <br />
          <ProfileItem
            icon={<GoGlobe size={20} color="#0026ab" />}
            label={"Country"}
            value={data?.country}
          />
          <br /> <br />
          <ProfileItem
            icon={<IoMdTime size={20} color="#0026ab" />}
            label={"Stage"}
            value={data?.Stage}
          />
          <br /> <br />
          <ProfileItem
            icon={<LiaUserFriendsSolid size={20} color="#0026ab" />}
            label={"Notable Investors"}
            value={data?.notable_inverstors}
          />
          <br /> <br />
        </>
      )}
    </Modal>
  );
}

export default CompanyInfo;
