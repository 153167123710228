import { useEffect, useState } from "react";
import "./styles.scss";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import PageHeader from "../routes/pageHeader";

import MailTemplate from "./mailTemplate";
import MasterTemplate from "./meetingMail";

function SettingScreen() {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Email Templates",
      children: <MailTemplate />,
    },
    {
      key: "2",
      label: "Meeting Mail Template",
      children: <MasterTemplate />,
    },
  ];

  return (
    <div>
      <PageHeader title={"Settings"}></PageHeader>
      <Tabs
        defaultActiveKey="1"
        size={"small"}
        style={{ marginBottom: 32 }}
        items={items}
      />
    </div>
  );
}

export default SettingScreen;
