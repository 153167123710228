import { useEffect, useState } from "react";
import { Button, Select, message } from "antd";
import { Row, Col, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { IoMdTime } from "react-icons/io";
import { IoMailOutline, IoPeopleOutline, IoCopyOutline } from "react-icons/io5";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { LuCalendarCheck, LuMailPlus } from "react-icons/lu";
import { MdOutlineMeetingRoom } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import QRCode from "react-qr-code";
import moment from "moment";

import PageHeader from "../routes/pageHeader";
import "./styles.scss";
import Loading from "../../components/loading";
import Slots from "../../config/timeSlots.json";

import { GET, POST } from "../../utils/apiCalls";
import API from "../../config/api";
import AssociateItem from "../../website/vcDetailsScreen/components/associateItem";
import ProfileItem from "../../website/vcDetailsScreen/components/profileItem";
import Mailing from "./components/mailing";
import { GENERATE_SLOTS } from "./helpers/generateSlots";
import { COMBINE_SLOTS } from "./helpers/combineSlots";
import SlotsItem from "./components/slotsItem";
import AssignModal from "./components/assignModal";
import EditVcModal from "./components/edit";
import { useSelector } from "react-redux";

function InvestorDetails() {
  const params = useParams();

  const Events = useSelector((state: any) => state.Events);
  const user = useSelector((state: any) => state.User.user);
  const [loading, setLoading] = useState(true);
  const [loading2, setloading2] = useState(false);
  const [data, setData] = useState([]) as any;
  const [roomData, setRoomData] = useState([]) as any;
  const [openMailing, setOpenMailing] = useState(false);
  const [editVc, setEditVc] = useState(false);
  const [slots, setSlots] = useState([]);
  const [counts, setCounts] = useState({ total: 0, assigned: 0 });

  const [selectedItem, setSelectedItem] = useState<any>({});
  const [assign, setAssign] = useState(false);

  useEffect(() => {
    loadData();
    getMeetingRooms();
  }, []);

  const loadData = async () => {
    try {
      let url =
        API.INVESTORS_DETAILS + params?.id + `?eventId=${Events?.defult?.id}`;
      let res: any = await GET(url, null);
      if (res.status) {
        setData(res.data.data);
        createSlots(
          res.data?.data?.timeSlots,
          30,
          "13-14",
          res?.data?.meetings
        );
      } else {
        message.error("Something went wrong...!");
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  const selectItem = (value: any) => {
    setSelectedItem(value);
    setAssign(!assign);
  };

  const createSlots = async (
    slots: any,
    period: any,
    exclusions: any,
    meetings: any
  ) => {
    try {
      let slotData: any = await GENERATE_SLOTS(slots, period, exclusions);
      let combine: any = await COMBINE_SLOTS(slotData, meetings);
      setSlots(combine);
      setCounts({ total: slotData.length, assigned: meetings.length });
    } catch (err) {
      console.log("err", err);
    }
  };

  const showSlot = (value: any) => {
    let slot = Slots?.filter((i: any) => i.value === value);
    return slot[0]?.label;
  };

  const copyToClipBoard = (token: any) => {
    let link = `${API.WEB_URL}/vc-details/${token}/?event=${Events?.defult?.id}`;
    navigator.clipboard.writeText(link).then(() => {
      message.success("Link copied");
    });
  };

  const updateRoom = async (room: any) => {
    try {
      setloading2(true);
      let obj = {
        id: data?.id,
        room_id: room,
        room: roomData.find((item: any) => item.id === room)?.name,
      };
      let response: any = await POST(API.INVESTORS_SET_ROOM, obj);
      if (response?.status) {
        loadData();
        message.success("Room updated successfully ");
      } else {
        message.error("Something went wrong...!");
      }
      setloading2(false);
    } catch (err) {
      message.error("Something went wrong...!");
      setloading2(false);
    }
  };
  const getMeetingRooms = async () => {
    try {
      setloading2(true);
      let url = API.ROOMS_PICKER + Events?.defult?.id;
      const response: any = await GET(url, null);
      if (response?.status) {
        setRoomData(response?.data);
      } else {
        setRoomData([]);
      }
    } catch (err) {
      console.log(err, "err");
    } finally {
      setloading2(false);
    }
  };

  return (
    <div>
      <PageHeader title={"VC Details"} loading={loading}>
        <div className="AdminHeader-extra">
          {loading ? null : (
            <>
              {data?.id ? (
                <div>
                  Meeting Rooms :{" "}
                  <Select
                    showSearch
                    style={{ width: 170, marginRight: 8 }}
                    placeholder="Select Room"
                    onChange={(value: any) => updateRoom(value)}
                    allowClear
                    loading={loading2}
                    optionFilterProp="children"
                    defaultValue={data?.meeting_room}
                  >
                    {roomData?.map((item: any) => {
                      return (
                        <Select.Option key={item?.id} value={item?.id}>
                          {item?.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              ) : null}

              <Button
                type="primary"
                onClick={() => copyToClipBoard(data?.uuid)}
                icon={<IoCopyOutline />}
              >
                Copy Profile
              </Button>
              {Array.isArray(user.access[Events?.defult?.id]) &&
              user.access[Events?.defult?.id].includes(8) ? null : (
                <>
                  <Button
                    type="primary"
                    onClick={() => setOpenMailing(true)}
                    icon={<LuMailPlus />}
                  >
                    Send Mail
                  </Button>

                  <Button onClick={() => setEditVc(true)} icon={<FiEdit />}>
                    Edit
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      </PageHeader>
      <Container>
        {loading ? (
          <Loading />
        ) : (
          <Row>
            <Col sm={3}>
              <br />
              <div>
                {data?.logo ? (
                  <img src={data?.logo} className="investorScreen-logo" />
                ) : (
                  <QRCode
                    size={100}
                    value={`${API.WEB_URL}/vc-details/${data?.uuid}/?event=${Events?.defult?.id}`}
                    viewBox={`0 0 256 256`}
                    bgColor={"transparent"}
                    fgColor={"#0026ab"}
                  />
                )}
              </div>
              <br />
              <div className="investorScreen-text1">{data?.company}</div>
              <div className="investorScreen-text2">{data?.fullname}</div>
              <hr />
              <ProfileItem
                icon={<HiOutlineBuildingOffice2 size={20} color="#0026ab" />}
                label={"Position"}
                value={data?.jobTitle}
              />
              <br /> <br />
              <ProfileItem
                icon={<IoMailOutline size={20} color="#0026ab" />}
                label={"Email Address"}
                value={data?.email}
              />
              <br /> <br />
              <ProfileItem
                icon={<IoMdTime size={20} color="#0026ab" />}
                label={"Meeting Slots"}
                value={showSlot(data?.timeSlots)}
              />
              <br /> <br />
              <ProfileItem
                icon={<MdOutlineMeetingRoom size={20} color="#0026ab" />}
                label={"Meeting Room"}
                value={data?.meeting_room}
              />
              <br /> <br />
              <ProfileItem
                icon={<LuCalendarCheck size={20} color="#0026ab" />}
                label={"Registered On"}
                value={moment(data?.data?.createdAt).format(
                  "MMM D, YYYY h:mm:ss A"
                )}
              />
              <hr />
              <div className="VcDetailsScreen-item">
                <div>
                  <IoPeopleOutline size={20} color="#0026ab" />
                </div>
                <div style={{ width: "100%" }}>
                  <div className="VcDetailsScreen-text3">Attendees</div>
                  <div style={{ margin: 10 }} />
                  {data?.Attendees?.length ? (
                    data?.Attendees.map((item: any, index: any) => {
                      return <AssociateItem item={item} key={index} />;
                    })
                  ) : (
                    <div className="VcDetailsScreen-text4">
                      You didn't add any attendees
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col sm={1}>
              <div className="devider" />
            </Col>
            <Col sm={8}>
              <br />
              <div>
                <div className="investorScreen-row">
                  <div className="investorScreen-heading">Meetings</div>
                  <div>
                    {counts?.assigned}/{counts?.total}
                  </div>
                </div>
                <div style={{ margin: 20 }} />
                {slots?.length ? (
                  slots?.map((item: any) => {
                    return (
                      <SlotsItem
                        item={item}
                        Allocate={(data: any) => selectItem(data)}
                        onChange={() => loadData()}
                      />
                    );
                  })
                ) : (
                  <div>No slots Available</div>
                )}
              </div>
            </Col>
          </Row>
        )}
      </Container>
      {openMailing ? (
        <Mailing
          visible={openMailing}
          data={data}
          onClose={() => setOpenMailing(!openMailing)}
        />
      ) : null}
      {assign ? (
        <AssignModal
          visible={assign}
          data={data}
          slots={slots}
          item={selectedItem}
          onChange={() => loadData()}
          onClose={() => selectItem({})}
        />
      ) : null}
      {editVc ? (
        <EditVcModal
          visible={editVc}
          data={data}
          onClose={() => setEditVc(!editVc)}
          onFinish={() => loadData()}
          roomData={roomData}
        />
      ) : null}
    </div>
  );
}

export default InvestorDetails;
