import { useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import Loading from "../../components/loading";
import PageHeader from "../routes/pageHeader";
import DataTable from "./components/dataTable";
import Forms from "./components/form";

import API from "../../config/api";
import { DELETE, POST } from "../../utils/apiCalls";

function CompanyScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [data, setData] = useState([]);

  const [selectedItem, setSelectedItem] = useState<any>({});
  const [openForm, setOpenForm] = useState(false);

  const [page, setPage] = useState(query ? query?.get("page") : 1);
  const [take, setTake] = useState(query ? query?.get("limit") : 10);
  const [meta, setMeta] = useState<any>({});

  useEffect(() => {
    getData();
  }, [page, take]);

  useEffect(() => {
    setPage(query ? query?.get("page") : 1);
    setTake(query ? query?.get("limit") : 10);
  }, [query]);

  const getData = async () => {
    try {
      let obj = {
        startDate: form.getFieldValue("date"),
        query: form.getFieldValue("query"),
        page: page,
        take: take,
      };
      let api = API.COMPANIES_LIST;
      const response: any = await POST(api, obj);
      if (response?.status) {
        setData(response?.data);
        setMeta(response?.meta);
        setIsLoading2(false);
        setLoading(false);
      } else {
        setData([]);
        setIsLoading2(false);
        setLoading(false);
      }
    } catch (err) {
      setIsLoading2(false);
      setLoading(false);
    }
  };

  const changePagination = (page: any, pageSize: any) => {
    query.set("page", page);
    query.set("limit", pageSize);
    navigate({ search: query.toString() });
    setIsLoading2(true);
    setPage(page);
    setTake(pageSize);
  };

  const editItem = (value: any) => {
    setSelectedItem(value);
    setOpenForm(!openForm);
  };

  const deleteItem = async (id: any) => {
    try {
      setIsLoading2(true);
      let response: any = await DELETE(API.COMPANIES_DELETE + id);
      if (response?.status) {
        getData();
        message.error("Deleted");
      } else {
        message.error("Oops.something gone wrong.");
      }
      setIsLoading2(false);
    } catch (err) {
      message.error("Oops.something gone wrong.");
      setIsLoading2(false);
    }
  };

  return (
    <div>
      <PageHeader title={"Companies"}>
        <Form form={form} onValuesChange={getData}>
          <div className="AdminHeader-extra">
            <Form.Item name={"query"} noStyle>
              <Input style={{ width: 200 }} placeholder="Search" allowClear />
            </Form.Item>
            <Form.Item noStyle>
              <Button type="primary" onClick={() => setOpenForm(true)}>
                ADD COMPANY +
              </Button>
            </Form.Item>
          </div>
        </Form>
      </PageHeader>
      {loading ? (
        <Loading />
      ) : (
        <DataTable
          data={data}
          meta={meta}
          loading={isLoading2}
          onChange={(data: any) => editItem(data)}
          delete={(id: any) => deleteItem(id)}
          onPageChange={(page: number, pageSize: number) =>
            changePagination(page, pageSize)
          }
        />
      )}
      {openForm ? (
        <Forms
          visible={openForm}
          data={selectedItem}
          onClose={() => editItem({})}
          onChange={() => getData()}
        />
      ) : null}
      <br />
    </div>
  );
}

export default CompanyScreen;
