import { useEffect, useState } from "react";
import "./styles.scss";
import { BsPerson } from "react-icons/bs";
import { BiCategoryAlt } from "react-icons/bi";
import { GoGlobe } from "react-icons/go";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { PiUsersFour } from "react-icons/pi";
import { LiaUserFriendsSolid } from "react-icons/lia";
import PageHeader from "../routes/pageHeader";
import { useParams } from "react-router-dom";
import API from "../../config/api";
import { GET } from "../../utils/apiCalls";
import { Button, message } from "antd";
import Loading from "../../components/loading";
import { Col, Container, Row } from "react-bootstrap";
import ProfileItem from "../../website/vcDetailsScreen/components/profileItem";
import { IoMdTime } from "react-icons/io";
import MeetingItem from "./components/meetingItem";
import { GENERATE_SLOTS } from "./helpers/generateSlots";
import { COMBINE_SLOTS } from "./helpers/combineSlots";
import Forms from "./components/form";
import { FiEdit } from "react-icons/fi";
import NoImage from "../../assets/images/noimage.jpg";
import { useSelector } from "react-redux";

function StartupDetails() {
  const Events = useSelector((state: any) => state.Events);
  const user = useSelector((state: any) => state.User.user);
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]) as any;
  const [slots, setSlots] = useState([]);
  const [counts, setCounts] = useState({ total: 0, assigned: 0 });
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      let url = API.STARTUPS_DETAILS + params?.id;
      let res: any = await GET(url, null);
      if (res.status) {
        setData(res.data);
        createSlots("10 - 17", 30, "13-14", res?.data?.meetings);
      } else {
        message.error("Something went wrong...!");
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  const createSlots = async (
    slots: any,
    period: any,
    exclusions: any,
    meetings: any
  ) => {
    try {
      let slotData: any = await GENERATE_SLOTS(slots, period, exclusions);
      let combine: any = await COMBINE_SLOTS(slotData, meetings);
      setSlots(combine);
      setCounts({ total: slotData.length, assigned: meetings.length });
    } catch (err) {
      console.log("err", err);
    }
  };

  const editItem = (value: any) => {
    setSelectedItem(value);
    setOpenForm(!openForm);
  };

  return (
    <div>
      <PageHeader title={"Startup Details"}>
        {loading ? null : Array.isArray(user.access[Events?.defult?.id]) &&
          user.access[Events?.defult?.id].includes(8) ? null : (
          <Button onClick={() => editItem(data?.data)} icon={<FiEdit />}>
            Edit
          </Button>
        )}
      </PageHeader>
      <Container>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col sm={3}>
                <div>
                  <br />
                  <br />
                  <div>
                    <img
                      src={data?.data?.logo || NoImage}
                      style={{ width: 150, height: 150, objectFit: "contain" }}
                    />
                  </div>
                  <div className="startupScreen-heading">
                    {data?.data?.name}
                  </div>
                  <br />
                  <div className="startupScreen-text2">
                    {data?.data?.description}
                  </div>
                  <hr />
                  <br />
                  <ProfileItem
                    icon={<BsPerson size={20} color="#0026ab" />}
                    label={"Founder"}
                    value={data?.data?.founder}
                  />
                  <br /> <br />
                  <ProfileItem
                    icon={<BiCategoryAlt size={20} color="#0026ab" />}
                    label={"Category"}
                    value={data?.data?.category}
                  />
                  <br /> <br />
                  <ProfileItem
                    icon={<GoGlobe size={20} color="#0026ab" />}
                    label={"Country"}
                    value={data?.data?.country}
                  />
                  <br /> <br />
                  <ProfileItem
                    icon={<IoMdTime size={20} color="#0026ab" />}
                    label={"Stage"}
                    value={data?.data?.Stage}
                  />
                  <br /> <br />
                  <ProfileItem
                    icon={<LiaUserFriendsSolid size={20} color="#0026ab" />}
                    label={"Notable Investors"}
                    value={data?.data?.notable_inverstors}
                  />
                  <br /> <br />
                  <ProfileItem
                    icon={<PiUsersFour size={20} color="#0026ab" />}
                    label={"Members"}
                    value={data?.data?.members}
                  />
                  <br /> <br />
                  <ProfileItem
                    icon={<FaRegMoneyBillAlt size={20} color="#0026ab" />}
                    label={"Fund Raised"}
                    value={data?.data?.fund_raised}
                  />
                  <br /> <br />
                </div>
              </Col>
              <Col sm={1}>
                <div className="devider" />
              </Col>
              <Col sm={8}>
                <br />
                <div className="startupScreen-heading">
                  Meetings - ({data?.meetings?.length})
                </div>
                <div style={{ margin: 20 }} />
                {slots?.length ? (
                  slots?.map((item: any) => {
                    return (
                      <MeetingItem
                        delete={true}
                        key={item?.id}
                        item={item}
                        showCreate={true}
                        onChange={() => loadData()}
                      />
                    );
                  })
                ) : (
                  <div>No slots Available</div>
                )}
              </Col>
            </Row>
          </div>
        )}
      </Container>
      {openForm ? (
        <Forms
          visible={openForm}
          data={selectedItem}
          onClose={() => editItem({})}
          onChange={() => loadData()}
        />
      ) : null}
    </div>
  );
}

export default StartupDetails;
