
import {
  Button,
  Checkbox,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  Image,
  Select,
  Switch,
} from "antd";
import { POST } from "../../../utils/apiCalls";
import API from "../../../config/api";
import { useCallback, useEffect, useMemo, useState } from "react";
import countries from "../../../components/countries.json";
import { Col, Row } from "react-bootstrap";

interface AccessItem {
  id: number;
  name: string;
  image: string;
}

interface UserData {
  id?: number;
  name: string;
  userType: string;
  role: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
  status: boolean;
  companyId?: string;
  access: Record<number, number[]>;
}

interface FormValues {
  name: string;
  role: string;
  email: string;
  phoneNumber: string;
  password?: string;
  confirmPassword?: string;
  country_code: string;
  status: boolean;
}

interface FormsProps {
  visible: boolean;
  data?: UserData;
  companyId?: string;
  item: AccessItem[];
  onClose: () => void;
  onChange: () => void;
}

const ACCESS_TYPES = {
  DASHBOARD: 1,
  MEETING: 2,
  VENTURE_CAPITALISTS: 3,
  STARTUPS: 4,
  MEETING_ROOMS: 5,
  USERS: 6,
  SETTINGS: 7,
  HOST: 8,
} as const;

const DETAILS = [
  { id: ACCESS_TYPES.DASHBOARD, name: "Dashboard" },
  { id: ACCESS_TYPES.MEETING, name: "Meeting" },
  { id: ACCESS_TYPES.VENTURE_CAPITALISTS, name: "Venture Capitalists" },
  { id: ACCESS_TYPES.STARTUPS, name: "Startups" },
  { id: ACCESS_TYPES.MEETING_ROOMS, name: "Meeting Rooms" },
  { id: ACCESS_TYPES.USERS, name: "Users" },
  { id: ACCESS_TYPES.SETTINGS, name: "Settings" },
  { id: ACCESS_TYPES.HOST, name: "Host" },
] as const;

const useAccessControl = (initialAccess: Record<number, number[]> = {}) => {
  const [openItems, setOpenItems] = useState<Record<number, boolean>>({});
  const [selectedDetails, setSelectedDetails] =
    useState<Record<number, number[]>>(initialAccess);

  const isHostSelected = useCallback(
    (details: Record<number, number[]>) =>
      Object.values(details).flat().includes(ACCESS_TYPES.HOST),
    []
  );

  const hasOtherFeatures = useCallback((details: Record<number, number[]>) => {
    const allSelectedDetails = Object.values(details).flat();
    const nonHostFeatures = allSelectedDetails.filter(
      (id) => id !== ACCESS_TYPES.HOST && id !== ACCESS_TYPES.MEETING
    );
    return nonHostFeatures.length > 0;
  }, []);

  const handleToggleDetail = useCallback((itemId: number, detailId: number) => {
    setSelectedDetails((prev) => {
      const updatedDetails = { ...prev };
      const currentDetails = updatedDetails[itemId] || [];

      if (currentDetails.includes(detailId)) {
        updatedDetails[itemId] = currentDetails.filter((id) => id !== detailId);
      } else {
        updatedDetails[itemId] = [...currentDetails, detailId];
      }

      if (updatedDetails[itemId].length === 0) {
        delete updatedDetails[itemId];
      }

      return updatedDetails;
    });
  }, []);

  const handleToggle = useCallback((id: number) => {
    setOpenItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  }, []);

  return {
    openItems,
    setOpenItems, 
    selectedDetails,
    setSelectedDetails,
    isHostSelected,
    hasOtherFeatures,
    handleToggleDetail,
    handleToggle,
  };
};

const PhoneNumberInput: React.FC<{
  form: FormInstance;
  disabled?: boolean;
}> = ({ form, disabled }) => {
  const cascaderOptions = useMemo(
    () =>
      countries?.map((country) => ({
        flag: country?.flag,
        value: country?.dial_code,
        label: country?.dial_code,
      })),
    []
  );

  return (
    <>
      <div className="label">Phone Number</div>
      <Form.Item
        name="phoneNumber"
        rules={[
          { required: true, message: "Please enter phone number" },
          {
            pattern:
              /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
            message: "Enter a valid phone number",
          },
        ]}
      >
        <Input
          type="tel"
          disabled={disabled}
          style={{ width: "100%" }}
          placeholder="Phone Number"
          onKeyPress={(event) => {
            if (!/^[0-9]+$/.test(event.key)) {
              event.preventDefault();
            }
          }}
          addonBefore={
            <Form.Item noStyle name="country_code" rules={[{ required: true,message:"" }]}>
              <Select
                placeholder=""
                style={{ width: 100 }}
                showSearch
                disabled={disabled}
                dropdownStyle={{ width: 100 }}
                allowClear
              >
                {cascaderOptions?.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    <div className="d-flex align-items-center gap-2">
                      <Image
                        preview={false}
                        width={31}
                        alt={item.flag}
                        src={`https://flagsapi.com/${item.flag}/flat/24.png`}
                      />
                      {item.label}
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          }
          size="large"
        />
      </Form.Item>
    </>
  );
};

const AccessControlItem: React.FC<{
  item: AccessItem;
  isOpen: boolean;
  selectedDetails: Record<number, number[]>;
  isHostSelected: boolean;
  onToggle: () => void;
  onDetailToggle: (detailId: number) => void;
}> = ({
  item,
  isOpen,
  selectedDetails,
  isHostSelected,
  onToggle,
  onDetailToggle,
}) => {
  return (
    <div className="mb-3">
      <div className="access_title border-bottom-0">
        <img className="access_img" src={item.image} alt={item.name} />
        <div>{item.name}</div>
        <Checkbox checked={isOpen} onClick={onToggle} />
      </div>
      {isOpen && (
        <div className="d-flex flex-column row-gap-4 p-3 border border-top-0">
          {DETAILS.map((detail) => (
            <div className="d-flex justify-content-between" key={detail.id}>
              <div>{detail.name}</div>
              <Checkbox
                checked={selectedDetails[item.id]?.includes(detail.id)}
                onClick={() => onDetailToggle(detail.id)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Forms: React.FC<FormsProps> = ({
  visible,
  data,
  companyId,
  item,
  onClose,
  onChange,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const {
    openItems,
    setOpenItems,
    selectedDetails,
    setSelectedDetails,
    isHostSelected,
    hasOtherFeatures,
    handleToggleDetail,
    handleToggle,
  } = useAccessControl(data?.access);

  useEffect(() => {
    if (data?.id) {
      const initialData = data.access || {};
      const openItemsState: Record<number, boolean> = {};

      Object.entries(initialData).forEach(([key, value]) => {
        openItemsState[Number(key)] = value.length > 0;
      });

      setOpenItems(openItemsState);
      setSelectedDetails(initialData);
    }
  }, [data, setOpenItems, setSelectedDetails]); 

  const handleSubmit = async (values: FormValues) => {
    try {
      if (!Object.keys(selectedDetails).length) {
        message.error("Please select at least one access.");
        return;
      }

      if (
        isHostSelected(selectedDetails) &&
        !hasOtherFeatures(selectedDetails)
      ) {
        message.error(
          "When host is selected, you must select at least one additional access ."
        );
        return;
      }

      setLoading(true);
      const url = data?.id ? API.USERS_EDIT : API.USERS_ADD;

      const payload = {
        id: data?.id,
        name: values?.name,
        userType: "user",
        role: values?.role,
        email: values?.email,
        phoneNumber: values?.phoneNumber,
        countryCode: values?.country_code,
        status: values?.status === false ? false : true,
        companyId,
        access: selectedDetails,
        ...(values.password && { password: values.password }),
      };

      const res = await POST(url, payload);
      if (res) {
        message.success(
          data?.id ? "User updated successfully." : "User created successfully."
        );
        onChange();
        form.resetFields();
        onClose();
      }
    } catch (error) {
      message.error("Error occurred, please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title={data?.id ? "Edit User" : "Create User"}
      onCancel={onClose}
      footer={null}
      width={800}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        scrollToFirstError
        initialValues={{
          name: data?.name || "",
          role: data?.role || "",
          email: data?.email || "",
          phoneNumber: data?.phoneNumber || "",
          country_code: data?.countryCode || "+971",
          status: data?.status,
        }}
      >
        <Row>
          <Col xs={12} md={6}>
            <div className="label">Name</div>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "Please enter name" },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: "Enter valid name",
                },
              ]}
            >
              <Input
                placeholder="Enter name"
                size="large"
                onKeyPress={(event) => {
                  if (!/^[a-zA-Z ]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <div className="label">Role</div>
            <Form.Item
              name="role"
              rules={[
                { required: true, message: "Please enter role" },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: "Enter valid role",
                },
              ]}
            >
              <Input
                placeholder="Role"
                size="large"
                onKeyPress={(event) => {
                  if (!/^[a-zA-Z ]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <div className="label">Email</div>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please enter email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input placeholder="Enter email" type="email" size="large" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <PhoneNumberInput form={form} />
            <div className="label">Password</div>
            <Form.Item
              name="password"
              rules={[
                { required: !data?.id, message: "Please enter password" },
                { min: 6, message: "Password must be at least 6 characters" },
              ]}
            >
              <Input.Password placeholder="Enter password" size="large" />
            </Form.Item>

            <div className="label">Confirm Password</div>
            <Form.Item
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: !data?.id,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Passwords do not match"));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm password" size="large" />
            </Form.Item>
          </Col>
        </Row>

        <div className="pt-3">
          {item.map((accessItem) => (
            <AccessControlItem
              key={accessItem.id}
              item={accessItem}
              isOpen={openItems[accessItem.id]}
              selectedDetails={selectedDetails}
              isHostSelected={isHostSelected(selectedDetails)}
              onToggle={() => handleToggle(accessItem.id)}
              onDetailToggle={(detailId) =>
                handleToggleDetail(accessItem.id, detailId)
              }
            />
          ))}
        </div>
        <Row>
          <Col sm={6}>
            <div className="label">Status</div>
            <Form.Item name="status" valuePropName="checked">
              <Switch disabled={loading}  defaultChecked={data?.status === false ? false : true}/>
            </Form.Item>
          </Col>
          <Col sm={6}>
          <br />
            <Row>
              <Col sm={6}>
                <Button
                  block
                  size="large"
                  onClick={() => onClose()}
                  disabled={loading}
                >
                  {" "}
                  Close
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loading}
                  loading={loading}
                  size="large"
                  block
                >
                  {data?.id ? "Update User" : "Create User"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Forms;
