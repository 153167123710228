import { ConfigProvider } from "antd";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import HomeScreen from "./website/homeScreen";
import LoginScreen from "./website/loginScreen";
import VcFormScreen from "./website/vcFormScreen";
import VcDetailsScreen from "./website/vcDetailsScreen";
import StartupDetailScreen from "./website/startupDetailScreen";
import EventFormScreen from "./website/eventForm";

import ProtectedRoute from "./utils/protectedRoute";
import AdminRoutes from "./admin/routes";
import CompanyRoute from "./company/routes";

function App() {
  const User = useSelector((state: any) => state.User);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#124ef5",
          fontFamily: "Poppins-Medium",
          borderRadius: 0,
        },
      }}
    >
      <Routes>
        <Route index element={<HomeScreen />} />;
        <Route path="/" element={<HomeScreen />} />;
        <Route path="/hub71" element={<VcFormScreen />} />;
        <Route path="/event/:uuid/:eventId" element={<EventFormScreen />} />;
        <Route path="/vc-details/:id" element={<VcDetailsScreen />} />;
        <Route path="/startup-details/:id" element={<StartupDetailScreen />} />;
        <Route path="/login" element={<LoginScreen />} />;
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute
              role={"admin"}
              roleIn={User?.user?.userType}
              isSignedIn={User.auth}
            >
              <AdminRoutes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/company/*"
          element={
            <ProtectedRoute
              role={"company"}
              roleIn={User?.user?.userType}
              isSignedIn={User.auth}
            >
              <CompanyRoute />
            </ProtectedRoute>
          }
        />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
