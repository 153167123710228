import { createSlice } from "@reduxjs/toolkit";
const EventsSlice = createSlice({
  name: "Events",
  initialState: {
    events: [],
    defult: {},
  },
  reducers: {
    store: (state, action) => {
      state.events = action.payload;
    },
    defult: (state, action) => {
      state.defult = action.payload;
    },
    clear: (state, action) => {
      state.events = [];
      state.defult = {};
    },
  },
});

export const { store, defult, clear } = EventsSlice.actions;
export default EventsSlice.reducer;
