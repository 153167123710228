import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
function Loading() {
  return (
    <div
      style={{
        // height: "50vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
      <br />
      <div style={{ fontFamily: "Roc_Grotesk_Wide", color: "grey" }}>
        Loading
      </div>
    </div>
  );
}

export default Loading;
