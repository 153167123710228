import { Button, Form, Input, message, Modal } from "antd";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import API from "../../../config/api";
import { POST } from "../../../utils/apiCalls";

const RoomForm = (props: any) => {
  const [form] = Form.useForm();
  const Events = useSelector((state: any) => state.Events);

  const [loading, setLoading] = useState(false);
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      let url = props?.data?.id ? API.ROOMS_EDIT : API.ROOMS_ADD;
      let obj = {
        id: props?.data?.id,
        name: values.name,
        value: values?.name,
        eventId: Events?.defult?.id,
      };
      let response: any = await POST(url, obj);
      if (response.status) {
        props.onClose();
        props.reload();
        message.success("Successfully added");
      } else {
        message.error(response.message);
      }
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      message.error("Oops.something gone wrong.");
      setLoading(false);
    }
  };
  return (
    <div>
      <Modal
        title={`${props?.data?.id ? "Edit" : "New"} Room`}
        open={props.open}
        onCancel={() => props.onClose()}
        footer={false}
        centered
        width={500}
        closable={!loading}
      >
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            id: props?.data?.id,
            name: props?.data?.name,
            value: props?.data?.value,
          }}
        >
          <Row>
            <Col sm={12}>
              <div className="label">Room Name</div>
              <Form.Item
                name={"name"}
                rules={[{ required: true, message: "Please enter room name" }]}
              >
                <Input placeholder="Room name" size="large" type="text" />
              </Form.Item>
            </Col>
            <Col sm={6}>
              <Button block size="large" onClick={() => props.onClose()} disabled={loading}>
                Close
              </Button>
            </Col>
            <Col sm={6}>
              <Button
              disabled={loading}
                htmlType="submit"
                block
                size="large"
                type="primary"
                loading={loading}
              >
                Done
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default RoomForm;
