import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({
  access,
  children,
}: {
  access: number;
  children: JSX.Element;
}) => {
  const User = useSelector((state: any) => state.User);
  const Events = useSelector((state: any) => state.Events);

  const hasAccess =
    User?.user?.access?.root === "admin" ||
    (Array.isArray(User?.user?.access[Events?.defult?.id]) &&
      User?.user?.access[Events?.defult?.id].includes(access));

  // If no access, redirect to 404 page
  if (!hasAccess) {
    return <Navigate to="/company/invalid-link" />;
  }

  return children; // Render the children component if access is allowed
};

export default ProtectedRoute;
