import { Table, Tooltip } from "antd";
import moment from "moment";
import { CiRead } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import NoImage from "../../assets/images/noimage.jpg"

function DataTable(props: any) {
  const navigation = useNavigate();
  const columns = [
    {
      title: "",
      dataIndex: "logo",
      key: "logo",
      render: (item: any) => (
        <div style={{ paddingLeft: 10 }}>
          <img
            src={item || NoImage}
            style={{
              width: 50,
              height: 50,
              objectFit: "contain",
              backgroundColor: "#f5f5f5",
              borderRadius: 100,
            }}
          />
        </div>
      ),
    },
    {
      title: "Contact Person",
      render: (item: any) => (
        <div>
          <div style={{ fontSize: 15 }}>{item?.company}</div>
          <div className="DashboardScreen-text3">
          {item?.fullname}
          </div>
        </div>
      ),
    },
    {
      title: "Registerd On",
      dataIndex: "createdAt",
      render: (item: any) => (
        <div style={{ fontSize: 12 }}>{moment(item).format("MMM D, YYYY h:mm:ss A")}</div>
      ),
    },
    {
      title: "Actions",
      render: (item: any) => (
        <div className="table-item" style={{ paddingRight: 10 }}>
          <div onClick={() => navigation(`/company/ivsdetails/${item.uuid}`)}>
            <Tooltip
              placement="top"
              title={<div style={{ fontSize: 10 }}>Profile Details</div>}
            >
              <CiRead size={20} color="#0026ab" />
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];
  return (
    <Table
      size="small"
      dataSource={props?.data?.investors}
      columns={columns}
      bordered={false}
      pagination={false}
      scroll={{ x: 1000 }}       
    />
  );
}

export default DataTable;
