import { Button, DatePicker, Form, Input, Select, message } from "antd";
import { useEffect, useState } from "react";
import { SiMicrosoftexcel } from "react-icons/si";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as XLSX from "xlsx";
import Loading from "../../components/loading";
import API from "../../config/api";
import { DELETE, GET } from "../../utils/apiCalls";
import PageHeader from "../routes/pageHeader";
import DataTable from "./components/dataTable";
import "./styles.scss";
import dayjs from "dayjs";

function MeetingScreen() {
  const [form] = Form.useForm();
  const Events = useSelector((state: any) => state.Events);
  const user = useSelector((state: any) => state.User.user);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState({
    data: true,
    startups: true,
    excel: false,
  });

  const [startups, setStartups] = useState([]);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const [data1, setData1] = useState<any>(1);

  useEffect(() => {
    getData();
    loadStartups();
  }, [data1]);

  const getData = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, data: true }));
      const params = {
        ...(searchParams.get("query") && { query: searchParams.get("query") }),
        ...(searchParams.get("startup_id") && {
          startup_id: searchParams.get("startup_id"),
        }),
        ...(searchParams.get("startDate") && {
          startDate: searchParams.get("startDate"),
        }),
        ...(searchParams.get("start") && { start: searchParams.get("start") }),
        ...(searchParams.get("pending") && {
          pending: searchParams.get("pending"),
        }),
        ...(searchParams.get("page") && { page: searchParams.get("page") }),
        ...(searchParams.get("limit") && { take: searchParams.get("limit") }),
        eventId: Events?.defult?.id,
      };
      const url = API.MEETINGS_LIST_SEARCH;
      const res: any = await GET(url, params);
      if (res.status) {
        setData(res.data);
        setMeta(res.meta);
      } else {
        message.error("Failed to fetch meetings data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("An error occurred while fetching meetings.");
    } finally {
      setIsLoading((prev) => ({ ...prev, data: false }));
      setLoading(false);
    }
  };

  const loadStartups = async () => {
    try {
      const response: any = await GET(
        `${API.STARTUPS_PICKER}${Events?.defult?.id}`,
        null
      );
      if (response?.status) {
        setStartups(response.data);
      }
    } catch (err) {
      console.error("Error loading startups:", err);
    } finally {
      setIsLoading((prev) => ({ ...prev, startups: false }));
    }
  };

  const deleteItem = async (id: any) => {
    try {
      const response: any = await DELETE(`${API.MEETINGS_DELETE}${id}`);
      if (response?.status) {
        message.success("Meeting deleted successfully.");
        getData();
      } else {
        message.error("Failed to delete the meeting.");
      }
    } catch (err) {
      console.error("Error deleting meeting:", err);
      message.error("An error occurred while deleting the meeting.");
    }
  };

  const generateExcel = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, excel: true }));
      const url = `${API.MEETINGS_LIST_EVENT_ID}${Events?.defult?.id}`;
      const response: any = await GET(url, null);
      if (response?.status) {
        const arr = response.data.map((item: any) => ({
          Id: item.id,
          uuid: item.uuid,
          Slot: item.slot,
          "Investor Name": item.investor_name,
          "Startup Name": item.startup_name,
          "Investors Company": item?.investors?.company,
          Room: item?.investors?.meeting_room,
          Status: item?.status,
          "Created At": item.createdAt,
        }));

        const worksheet = XLSX.utils.json_to_sheet(arr);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Meetings Report");

        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const dataBlob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(dataBlob);
        link.download = `Meetings-Report-${new Date().toLocaleDateString()}.xlsx`;
        link.click();
      } else {
        message.error("Failed to generate the excel report.");
      }
    } catch (err) {
      console.error("Error generating excel:", err);
      message.error("An error occurred while generating the excel report.");
    } finally {
      setIsLoading((prev) => ({ ...prev, excel: false }));
    }
  };

  const changeParams = (data: any) => {
    const queryParams = [
      { key: "start", value: searchParams.get("start") },
      { key: "pending", value: searchParams.get("pending") },
      {
        key: "query",
        value: data?.query || (data?.val && searchParams.get("query")),
      },
      {
        key: "startup_id",
        value:
          data?.startup_id || (data?.val && searchParams.get("startup_id")),
      },
      {
        key: "startDate",
        value: data?.startDate || (data?.val && searchParams.get("startDate")),
      },
    ]
      .filter((param) => param.value)
      .map((param) => `&${param.key}=${param.value}`)
      .join("");
    const baseUrl = `/company/meetings?page=${
      data?.page || searchParams.get("page")
    }&limit=${data?.pageSize || searchParams.get("limit")}`;
    navigate(`${baseUrl}${queryParams}`);
    setData1(data1 + 1);
  };

  return (
    <div>
      <PageHeader title="Meetings" loading={isLoading.data}>
        <Form
          form={form}
          onValuesChange={(changingItem, allData) => {
            const obj = {
              ...allData,
              page: 1,
              startDate: changingItem?.startDate
                ? dayjs(allData?.startDate).format("YYYY-MM-DD")
                : allData?.startDate,
            };
            changeParams(obj);
          }}
          initialValues={{
            query: searchParams.get("query"),
            startDate:
              searchParams.get("startDate") &&
              dayjs(searchParams.get("startDate")),
            startup_id: searchParams.get("startup_id"),
          }}
        >
          <div className="AdminHeader-extra">
            <Form.Item name="query" noStyle>
              <Input style={{ width: 200 }} placeholder="Search" allowClear />
            </Form.Item>
            <Form.Item name="startDate" noStyle>
              <DatePicker placeholder="Date" style={{ minWidth: 100 }} />
            </Form.Item>
            { Array.isArray(user.access[Events?.defult?.id]) &&
                user.access[Events?.defult?.id].includes(8) ? null : 
            <Button
            loading={isLoading.excel}
            onClick={generateExcel}
            style={{ padding: 5, background: "#fff", cursor: "pointer" }}
            >
              EXPORT <SiMicrosoftexcel size={20} color="green" />
            </Button>
            }
          </div>
        </Form>
      </PageHeader>
      {loading ? (
        <Loading />
      ) : (
        <DataTable
          data={data}
          meta={meta}
          loading={isLoading.data}
          delete={deleteItem}
          onPageChange={(page: number, pageSize: number) =>
            changeParams({ page, pageSize, val: true })
          }
        />
      )}
    </div>
  );
}

export default MeetingScreen;
