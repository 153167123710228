import {
  Button,
  Card,
  ColorPicker,
  DatePicker,
  Form,
  Image,
  Input,
  message,
  Select,
  Steps,
  Switch,
  TimePicker,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Diamond from "../../assets/images/Arrows.svg";
import countries from "../../components/countries.json";
import ImagePicker from "../../components/imagePicker";
import Loading from "../../components/loading";
import API from "../../config/api";
import { defult } from "../../redux/slices/eventsSlice";
import { COMPRESS_IMAGE, GET, POST } from "../../utils/apiCalls";
import PageHeader from "../routes/pageHeader";

function CreateEventScreen() {
  const Events = useSelector((state: any) => state.Events);
  const User = useSelector((state: any) => state.User);
  const cardRefs = useRef<any>([]); // Create an array of refs for the cards
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [description, setDescription] = useState<any>(null);
  const [image_url, setImageUrl] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const [eventData, setEventData] = useState<any>();
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const { id }: any = useParams();
  const [image, setImage] = useState<any>(null);
  const [data, setData] = useState<any>({});
  const formWatchData = Form.useWatch((values) => values, {
    form,
    preserve: true,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (id != 0) {
      getData();
    } else {
      form.setFieldsValue({ countryCode: "+971", status: true });
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    if (selectedIndex !== null && cardRefs.current[selectedIndex]) {
      cardRefs.current[selectedIndex].scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedIndex]);

  const disablePastDates = (current: any) => {
    return current && current < dayjs().startOf("day");
  };
  const getData = async () => {
    try {
      setLoading(true);
      let api = API.EVENTS_DETAILS + id;
      const response: any = await GET(api, null);
      if (response?.status) {
        setEventData(response?.data);
        let manipulationAgendas = response?.data?.agendas;
        if (response?.data?.agendas?.length) {
          manipulationAgendas = response?.data?.agendas.map(
            (item: any, index: any) => ({
              ...item,
              startTime: item?.startTime ? dayjs(item.startTime, "h:mm A") : "",
              endTime: item?.endTime ? dayjs(item.endTime, "h:mm A") : "",
            })
          );
        }

        let obj = {
          ...response?.data,
          start_date: dayjs(response?.data?.start_date),
          end_date: dayjs(response?.data?.end_date),
          event_date: dayjs(response?.data?.event_date),
          agendas: manipulationAgendas,
          countryCode: response?.data?.countrycode,
        };

        form.setFieldsValue(obj);
        setImage(response?.data?.image);
        setDescription(response?.data.description);
        setData(response?.data);
      } else {
        setEventData([]);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  const submit = async (data: any) => {
    try {

      if (!image_url && !image) {
        form.validateFields(['image'])
          .catch(() => {
            form.setFields([
              {
                name: 'image',
                errors: ['Please upload an image'],
                touched: true,
                validating: false
              }
            ]);
            form.scrollToField(['parentField', 'image']);
          });
        return;
      }
      setLoading2(true);
      let images: any = await COMPRESS_IMAGE(file);
      let obj = {
        id: id,
        name: data?.name,
        image: file?.name ? images?.url : image,
        title: data?.title,
        sub_title: data?.sub_title,
        slug: data?.slug,
        countryCode: data?.countryCode,
        description: data?.description,
        notes: data?.notes,
        start_date: dayjs(data?.start_date),
        end_date: dayjs(data?.end_date),
        event_date: dayjs(data?.event_date),
        start_time: data?.start_time
          ? dayjs(data?.start_time).format("h:mm A")
          : null,
        end_time: data?.end_time
          ? dayjs(data?.end_time).format("h:mm A")
          : null,
        location: data?.location,
        map_link: data?.map_link,
        phone: data?.phone,
        email: data?.email,
        companyId: User?.user?.companyId,
        agendas:
          (data?.agendas?.length &&
            data?.agendas?.map((item: any) => {
              return {
                ...item,
                startTime: item?.startTime
                  ? item?.startTime.format("h:mm A")
                  : null,
                endTime: item?.endTime ? item?.endTime.format("h:mm A") : null,
              };
            })) ||
          [],
        bg_color:
          typeof data?.bg_color === "string"
            ? data?.bg_color
            : data?.bg_color?.toHexString
            ? data?.bg_color?.toHexString()
            : "#0026ab",
        status: data?.status === false ? false : true,
      };
      let url = eventData?.id ? API.EVENTS_EDIT : API.EVENTS_ADD;
      var response: any = await POST(url, obj);
      if (response.status) {
        message.success("Successfully submitted");
        navigate(-1);
        if (Events?.defult?.id === id) {
          dispatch(defult(response?.data));
        }
      } else {
        if (
          typeof response?.message === "string" &&
          response.message.includes("This room is already in use")
        ) {
          message.error("Title must be unique");
        } else {
          message.error(response?.message || "An unknown error occurred");
        }
      }
      setLoading2(false);
    } catch (err) {
      console.log("err", err);
      message.error("Oops.something gone wrong.");
      setLoading2(false);
    }
  };
  const blendColor = (baseColor: string, factor: number): string => {
    const hexToRgb = (hex: string): [number, number, number] => {
      let r = 0,
        g = 0,
        b = 0;

      // 3 digits
      if (hex?.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
      }
      // 6 digits
      else if (hex?.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
      }

      return [r, g, b];
    };

    const rgbToHex = (r: number, g: number, b: number): string => {
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    };

    const [r, g, b] = hexToRgb(baseColor);

    // Blend towards white based on the factor (0 = original color, 1 = white)
    const blendedR = Math.round(r + (255 - r) * factor);
    const blendedG = Math.round(g + (255 - g) * factor);
    const blendedB = Math.round(b + (255 - b) * factor);

    return rgbToHex(blendedR, blendedG, blendedB);
  };
  const progressDot = (dot: any, { status, index }: any) => {
    return (
      <div
        style={{
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          backgroundColor: blendColor(
            typeof formWatchData?.bg_color === "string"
              ? formWatchData.bg_color // use directly if it's already a hex string
              : formWatchData?.bg_color?.toHexString
              ? formWatchData.bg_color.toHexString()
              : "#FFFFFF",
            0.3
          ),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    );
  };

  const scrollToCard = (index: number) => {
    if (cardRefs.current[index]) {
      cardRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const cascaderOptions = countries?.map((country) => ({
    flag: country?.flag,
    value: country?.dial_code,
    label: `${country.dial_code}`,
  }));
  const disabledEndHours: any = (startTime: any) => {
    const startHour = startTime ? startTime.hour() : null;
    const hours = [];
    for (let i = 0; i < 24; i++) {
      if (i < startHour) {
        hours.push(i);
      }
    }
    return hours;
  };
  const disabledEndMinutes: any = (selectedHour: any, startTime: any) => {
    const startHour = startTime ? startTime.hour() : null;
    const startMinute = startTime ? startTime.minute() : null;

    if (selectedHour === startHour) {
      return Array.from({ length: 60 }, (_, i) => i).filter(
        (minute) => minute < startMinute
      );
    }
    return [];
  };
  const checkSlugUnique = async (slug: any) => {
    try {
      const response: any = await GET(
        API.CHECK_SLUG + slug + "/" + User?.user?.companyId
      );
      if (response?.status) {
        return response.data.isUnique;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error checking slug uniqueness", error);
      return false;
    }
  };
  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <Form
          form={form}
          onFinish={submit}
          scrollToFirstError
          initialValues={{
            countryCode: id != 0 ? data?.countrycode : "+971",
          }}
        >
          <PageHeader
            title={`${eventData?.id ? "Edit Event" : "Create Event"}`}
          ></PageHeader>
          <Row>
            <Col>
              <Card>
                <Row>
                  <Col sm={6}>
                    {/* <div className="label">Title 1</div>
                   s <Form.Item name={"title"}>
                      <Input.TextArea
                        rows={2}
                        maxLength={255}
                        size="large"
                        placeholder="Title 1"
                      />
                    </Form.Item> */}

                    <div className="label">Title 1 *</div>
                    <Form.Item name={"sub_title"} rules={[
                        { required: true, message: "Please enter title 1" },
                      ]}>
                      <Input.TextArea
                        rows={2}
                        maxLength={255}
                        size="large"
                        placeholder="Title 1"
                      />
                    </Form.Item>

                    <div className="label">Title 2 </div>
                    <Form.Item
                      name={"notes"}
                     
                    >
                      <Input.TextArea
                        rows={2}
                        maxLength={255}
                        size="large"
                        placeholder="Title 2"
                      />
                    </Form.Item>
                    <div className="label">Name *</div>
                    <Form.Item name={"name"}  rules={[
                        { required: true, message: "Please enter name " },
                      ]}>
                      <Input.TextArea
                        size="large"
                        maxLength={255}
                        placeholder="Title 3"
                      />
                    </Form.Item>
                    <div className="label">Location *</div>
                    <Form.Item name={"location"} rules={[{ required: true,message:"Please enter location" }]}>
                      <Input.TextArea
                        rows={2}
                        maxLength={255}
                        size="large"
                        placeholder="Location"
                      />
                    </Form.Item>
                    <div className="label">Background color</div>
                    <Form.Item name={"bg_color"} style={{ padding: 3 }}>
                      <ColorPicker defaultValue="#0026ab" />
                    </Form.Item>
                  </Col>
                  <Col sm={6}>
                    <div className="label">Slug *</div>
                    <Form.Item
                      name={"slug"}
                      rules={[
                        { required: true, message: "Please enter slug" },
                        {
                          pattern: /^[a-zA-Z-_]+$/,
                          message:
                            "Letters, hyphens, and underscores are allowed.",
                        },
                        {
                          validator: async (_, value) => {
                            if (!value) {
                              return Promise.resolve();
                            }
                            if (id != 0) {
                              return Promise.resolve();
                            }
                            const isUnique = await checkSlugUnique(value);

                            if (!isUnique) {
                              return Promise.reject(
                                new Error("Slug is already taken")
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Slug"
                        onKeyPress={(event) => {
                          if (!/^[a-zA-Z-_]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                    <div className="label">Phone Number *</div>
                    <Form.Item
                      name={"phone"}
                      rules={[
                        {
                          required: true,
                          message: "Please enter phone number",
                        },
                        {
                          pattern:
                            /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
                          message: "Please enter valid phone number",
                        },
                      ]}
                    >
                      <Input
                        type="tel"
                        style={{ width: "100%" }}
                        placeholder="Phone Number"
                        onKeyPress={(event) => {
                          if (!/^[0-9]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        addonBefore={
                          <Form.Item
                            noStyle
                            name="countryCode"
                            rules={[{ required: true, message: "" }]}
                          >
                            <Select
                              style={{ width: 100 }}
                              showSearch
                              dropdownStyle={{ width: 100 }}
                              allowClear
                              placeholder=""
                            >
                              {cascaderOptions?.map(
                                (item: any, index: number) => {
                                  return (
                                    <Select.Option
                                      key={index}
                                      value={item?.value}
                                    >
                                      <div className="d-flex align-items-center gap-2">
                                        <Image
                                          preview={false}
                                          width={31}
                                          alt={item?.code}
                                          src={`https://flagsapi.com/${item?.flag}/flat/24.png`}
                                        />
                                        {item?.label}
                                      </div>
                                    </Select.Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        }
                        size="large"
                      />
                    </Form.Item>
                    <div className="label d-flex justify-content-between">
                      Event Logo *
                      {image_url || image ? (
                        <Tooltip placement="top" title={"Remove logo"}>
                          <IoIosCloseCircleOutline
                            color="red"
                            size={20}
                            onClick={() => {
                              setImageUrl(null);
                              setFile(null);
                              setImage(null);
                            }}
                          />
                        </Tooltip>
                      ) : null}
                    </div>
                    <Form.Item name={"image"} rules={[{ required: true ,message:"Please upload logo" }]}>
                      <ImagePicker
                        onChange={(value: any) => {
                          setImageUrl(value?.url);
                          setImage(value?.url);
                          setFile(value?.file);
                        }}
                        fileURL={eventData?.id ? image : image_url}
                      />
                    </Form.Item>

                    <div className="label">Google Map Link </div>
                    <Form.Item
                      name={"map_link"}
                      rules={[
                        {
                          pattern:
                            /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.[a-zA-Z]{2,}(\/[^\s]*)?$/,
                          message: "Please enter a valid google map link",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Google Map Link" />
                    </Form.Item>
                    <div className="label">Support Mail *</div>

                    <Form.Item
                      name={"email"}
                      rules={[
                        {
                          type: "email",
                          message: "Please enter a valid email",
                        },
                        {
                          required: true,
                          message: "Please enter support email",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter support email"
                        type="email"
                      />
                    </Form.Item>

                    <div className="label">Event Date *</div>
                    <Form.Item
                      name={"event_date"}
                      rules={[
                        {
                          required: true,
                          message: "Please select event date",
                        },
                      ]}
                    >
                      <DatePicker
                        disabledDate={disablePastDates}
                        style={{ width: "100%" }}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="label">Description</div>
                <Form.Item name={"description"} style={{ height: 250 }}>
                  <div style={{ display: "none" }}>{description}</div>
                  <ReactQuill
                    style={{ height: 200 }}
                    theme="snow"
                    value={description}
                    onChange={(text: any) => {
                      setDescription(text);
                      form.setFieldValue("description", text);
                    }}
                  />
                </Form.Item>
                <Form.List
                  name="agendas"
                  rules={[
                    {
                      validator: async (_, steps) => {
                        if (!steps || steps.length < 1) {
                          message.error("At least one agenda is required");
                          return Promise.reject(
                            new Error("At least one agenda is required")
                          );
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#f1f1f1",
                          marginTop: 16,
                          padding: 10,
                        }}
                      >
                        <div>
                          {formWatchData?.agendas?.length
                            ? "Agenda "
                            : "Add Agenda *"}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: 10,
                          }}
                        >
                          {formWatchData?.agendas?.length ? (
                            <Button
                              disabled={loading2}
                              type="dashed"
                              style={{
                                color: "red",
                                borderColor: "red",
                              }}
                              onClick={() => {
                                form.setFieldsValue({ agendas: [] });
                              }}
                            >
                              Remove All
                            </Button>
                          ) : null}
                          {!formWatchData?.agendas?.length ||
                          selectedIndex === null ? (
                            <Button
                              type="primary"
                              disabled={loading2}
                              onClick={() => {
                                const newIndex = fields.length;
                                add();
                                setSelectedIndex(newIndex);
                              }}
                            >
                              Add Agenda
                            </Button>
                          ) : null}
                        </div>
                      </div>

                      {fields.length > 0 &&
                        fields.map(
                          (
                            { key, name, fieldKey, ...restField }: any,
                            index
                          ) => {
                            if (index === selectedIndex) {
                              const titleValue = form.getFieldValue([
                                "agendas",
                                index,
                                "title",
                              ]);

                              return (
                                <Card
                                  key={key}
                                  style={{ marginBottom: 16 }}
                                  ref={(el) => {
                                    if (el) {
                                      cardRefs.current[index] = el;
                                    }
                                  }}
                                >
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="label">{`Agenda ${
                                        name + 1
                                      } Title`}</div>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "title"]}
                                        fieldKey={[fieldKey, "title"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Please enter agenda ${
                                              name + 1
                                            } title`,
                                          },
                                        ]}
                                      >
                                        <Input
                                          size="large"
                                          placeholder={`Agenda ${
                                            name + 1
                                          } title`}
                                        />
                                      </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="label">{`Agenda ${
                                        name + 1
                                      } Description`}</div>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "description"]}
                                        fieldKey={[fieldKey, "description"]}
                                      >
                                        <Input.TextArea
                                          rows={2}
                                          placeholder={`Agenda ${
                                            name + 1
                                          } description`}
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="label">Start Time</div>
                                      <Form.Item
                                        name={[name, "startTime"]}
                                        fieldKey={[fieldKey, "startTime"]}
                                      >
                                        <TimePicker
                                          style={{ width: "100%" }}
                                          use12Hours
                                          format="h:mm A"
                                          placeholder="Start Time"
                                          disabledHours={() => [7, 8]}
                                        />
                                      </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="label">End Time</div>
                                      <Form.Item name={[name, "endTime"]}>
                                        <TimePicker
                                          style={{ width: "100%" }}
                                          use12Hours
                                          format="h:mm A"
                                          placeholder="End Time"
                                          disabledHours={() =>
                                            disabledEndHours(
                                              formWatchData?.agendas[name]
                                                ?.startTime
                                            )
                                          }
                                          disabledMinutes={(val) =>
                                            disabledEndMinutes(
                                              val,
                                              formWatchData?.agendas[name]
                                                ?.startTime
                                            )
                                          }
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>

                                  <div
                                    className="row"
                                    style={{ marginTop: 16 }}
                                  >
                                    <div className="col-4"></div>
                                    <div className="col-4">
                                      {" "}
                                      <Button
                                        block
                                        disabled={loading2}
                                        type="dashed"
                                        style={{
                                          color: "red",
                                          borderColor: "red",
                                        }}
                                        onClick={() => {
                                          remove(name);
                                          setSelectedIndex(null);
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                    <div className="col-4">
                                      <Button
                                        block
                                        disabled={!titleValue || loading2}
                                        onClick={() => {
                                          setSelectedIndex(null);
                                        }}
                                      >
                                        Save Agenda
                                      </Button>
                                    </div>
                                  </div>
                                </Card>
                              );
                            }
                            return null;
                          }
                        )}

                      {formWatchData?.agendas?.length > 0 ? (
                        <>
                          <br />
                          <div
                            style={{
                              border: "1px solid #f1f1f1",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "#f1f1f1",
                                padding: 10,
                              }}
                            >
                              {`Agendas(${formWatchData?.agendas?.length - 1})`}
                            </div>

                            <Table bordered hover>
                              <thead>
                                <tr>
                                  <th style={{ width: "70%" }}>Title</th>
                                  <th style={{ width: "30%" }}>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {formWatchData.agendas.map(
                                  (step: any, index: number) =>
                                    selectedIndex === index ? null : (
                                      <tr key={index}>
                                        <td>{step?.title}</td>
                                        <td>
                                          <Button
                                            type="link"
                                            disabled={
                                              selectedIndex !== null || loading2
                                            }
                                            onClick={() => {
                                              setSelectedIndex(index);
                                              scrollToCard(index); // Scroll to the card on edit
                                            }}
                                          >
                                            <CiEdit
                                              size={20}
                                              color={
                                                selectedIndex !== null
                                                  ? "grey"
                                                  : ""
                                              }
                                            />
                                          </Button>
                                          <Button
                                            type="link"
                                            disabled={
                                              selectedIndex !== null || loading2
                                            }
                                            onClick={() => {
                                              form.setFieldsValue({
                                                agendas: form
                                                  .getFieldValue("agendas")
                                                  .filter(
                                                    (_: any, i: any) =>
                                                      i !== index
                                                  ),
                                              });
                                              setSelectedIndex(
                                                form.getFieldValue("agendas")
                                                  ?.length
                                                  ? form.getFieldValue(
                                                      "agendas"
                                                    )?.length - 1
                                                  : 0
                                              );
                                            }}
                                          >
                                            <MdDelete
                                              size={20}
                                              color={
                                                selectedIndex !== null
                                                  ? "grey"
                                                  : "red"
                                              }
                                            />
                                          </Button>
                                        </td>
                                      </tr>
                                    )
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </>
                      ) : null}
                    </>
                  )}
                </Form.List>
                <div className="d-flex justify-content-between mt-3">
                  <Form.Item
                    label={"Publish"}
                    name={"status"}
                    style={{ marginBottom: -15 }}
                  >
                    <Switch
                      disabled={loading2}
                      defaultChecked={data?.status === false ? false : true}
                    />
                  </Form.Item>
                  <Form.Item noStyle>
                    <Button
                      htmlType="submit"
                      type="primary"
                      style={{ width: 150 }}
                      loading={loading2}
                      disabled={loading2}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </Card>
            </Col>

            <Col
              md={5}
              xs={12}
              style={{ padding: 0 }}
              className="VcFormScreen-sticky"
            >
              <div
                className="VcFormScreen-Box2"
                style={{
                  backgroundColor:
                    typeof formWatchData?.bg_color === "string"
                      ? formWatchData.bg_color
                      : formWatchData?.bg_color?.toHexString
                      ? formWatchData.bg_color.toHexString()
                      : "#0026ab",
                }}
              >
                <Row style={{ padding: 0, margin: 0 }}>
                  {image || image_url ? (
                    <Col sm={12} xs={12} style={{ padding: 0, margin: 0 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          paddingLeft: 20,
                          alignItems: "center",
                          height: 100,
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <img
                          src={eventData?.id ? image : image_url}
                          alt={eventData?.name}
                          className="event-img"
                        />
                      </div>
                    </Col>
                  ) : null}
                  <Col sm={2} xs={12}></Col>
                  <Col sm={10} md={10} xs={12}>
                    <br />
                    <div className="VcFormScreen-Box3">
                      <div className="VcFormScreen-box5">
                        {/* <h1 className="VcFormScreen-txt1">
                          {formWatchData?.title}
                        </h1> */}

                        <h6 className="VcFormScreen-txt2">
                          {formWatchData?.sub_title}
                        </h6>
                        <br />
                        <h6 className="VcFormScreen-txt2">
                          {formWatchData?.name}
                        </h6>
                        <div className="VcFormScreen-line" />

                        <br />
                        <h5
                          className="VcFormScreen-txt4"
                          style={{ fontSize: 30 }}
                        >
                          {formWatchData?.notes}
                        </h5>

                        <h5
                          className="VcFormScreen-txt4"
                          style={{ fontSize: 20 }}
                        >
                          {formWatchData?.location}
                        </h5>
                        <br />
                        <Steps
                          direction="vertical"
                          current={formWatchData?.agendas?.length || 1}
                          progressDot={progressDot}
                          items={
                            formWatchData?.agendas &&
                            formWatchData?.agendas?.map((item: any) => ({
                              title: (
                                <div className="VcFormScreen-txt6">
                                  {item?.startTime || item?.endTime ? (
                                    <strong className="VcFormScreen-text7">
                                      {item?.startTime && item?.endTime
                                        ? dayjs(item?.startTime)?.format(
                                            "hh:mm a"
                                          ) +
                                          " to " +
                                          dayjs(item?.endTime)?.format(
                                            "hh:mm a"
                                          )
                                        : dayjs(item?.startTime)?.format(
                                            "hh:mm a"
                                          ) ||
                                          dayjs(item?.endTime)?.format(
                                            "hh:mm a"
                                          )}
                                      &nbsp;/&nbsp;
                                    </strong>
                                  ) : null}
                                  {item?.title ? item?.title : null}
                                  {item?.description
                                    ? ` (${item?.description})`
                                    : null}
                                </div>
                              ),
                            }))
                          }
                        />

                        <br />
                        <div className="VcFormScreen-line" />
                        <br />
                        <h6 className="VcFormScreen-txt2">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: formWatchData?.description,
                            }}
                          />
                        </h6>
                      </div>

                      <img src={Diamond} className="VcFormScreen-img1" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
      )}

      <br />
    </div>
  );
}

export default CreateEventScreen;
