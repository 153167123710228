import { Button, Form, Image, Input, message, Modal, Select, Tooltip } from "antd";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import countries from "../../../components/countries.json";
import ImagePicker from "../../../components/imagePicker";
import API from "../../../config/api";
import { COMPRESS_IMAGE, POST, PUT } from "../../../utils/apiCalls";

function Forms(props: any) {
  const [form] = Form.useForm();
  const Events = useSelector((state: any) => state.Events);
  const [loading, setLoading] = useState(false);
  const [image_url, setImageUrl] = useState<any>(props?.data?.logo);
  const [file, setFile] = useState<any>();

  const cascaderOptions = countries?.map((country) => ({
    flag: country?.flag,
    value: country?.dial_code,
    label: `${country.dial_code}`,
  }));
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      let url = props?.data?.id ? API.STARTUPS_EDIT : API.STARTUPS_ADD;
      let images: any = await COMPRESS_IMAGE(file);
      let obj = {
        id: props?.data?.id,
        name: values.name,
        category: values.category,
        logo: file?.name ? images?.url : image_url,
        description: values.description,
        founder: values.founder,
        country: values.country,
        Stage: values.Stage,
        fund_raised: values.fund_raised,
        notable_inverstors: values.notable_inverstors,
        members: values?.members,
        eventId: Events?.defult?.id,
        countrycode: values?.countrycode || '+971',
        email: values?.email,
        phone: values?.phone,
      };
      const METHORD = props?.data?.id ? PUT : POST
      let response: any = await METHORD(url, obj);
      if (response.status) {
        props.onChange();
        props.onClose();
        message.success("Successfully submitted");
      } else {
        message.error(response.message);
      }
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      message.error("Oops.something gone wrong.");
      setLoading(false);
    }
  };

  return (
    <Modal
      title={`${props?.data?._id ? "Edit" : "New"} Startups`}
      open={props.visible}
      onCancel={() => props.onClose()}
      footer={false}
      maskClosable={false}
      centered
      closable={!loading}
      width={800}
    >
      <Form
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        initialValues={{
          id: props?.data?.id,
          name: props?.data?.name,
          category: props?.data?.category,
          logo: props?.data?.logo,
          description: props?.data?.description,
          founder: props?.data?.founder,
          country: props?.data?.country,
          Stage: props?.data?.Stage,
          fund_raised: props?.data?.fund_raised,
          notable_inverstors: props?.data?.notable_inverstors,
          members: props?.data?.members,
          countrycode: props?.data?.countrycode || '+971',
          email: props?.data?.email,
          phone: props?.data?.phone,
        }}
      >
        <Row>
          <Col sm={6}>
            <div className="label">Startup Name *</div>
            <Form.Item
              name={"name"}
              rules={[{ required: true, message: "Please enter startup name" }]}
            >
              <Input
                placeholder="Startup Name"
                size="large"
                type="text"
                
              />
            </Form.Item>
            <div className="label">Phone Number *</div>
            <Form.Item
              name={"phone"}
              rules={[
                // {
                //   required: true,
                //   message: "Please enter phone number",
                // },
                {
                  pattern:
                    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
                  message: "Enter a valid phone number",
                },
              ]}
            >
              <Input
                type="tel"
                style={{ width: "100%" }}
                placeholder="Phone number"
                onKeyPress={(event) => {
                  if (!/^[0-9]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                addonBefore={
                  <Form.Item
                    noStyle
                    name="countrycode"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "",
                    //   },
                    // ]}
                  >
                    <Select
                      placeholder=""
                      style={{ width: 100 }}
                      showSearch
                      dropdownStyle={{ width: 100 }}
                      allowClear
                    >
                      {cascaderOptions?.map(
                        (item: any, index: number) => {
                          return (
                            <Select.Option
                              key={index}
                              value={item?.value}
                            >
                              <div className="d-flex align-items-center gap-2">
                                <Image
                                  width={31}
                                  alt={item?.code}
                                  preview={false}
                                  src={`https://flagsapi.com/${item?.flag}/flat/24.png`}
                                />
                                {item?.label}
                              </div>
                            </Select.Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                }
                size="large"
              />
            </Form.Item>

            <div className="label">Founder *</div>
            <Form.Item
              name={"founder"}
              rules={[{ required: true, message: "Please enter founder" }]}
            >
              <Input
                placeholder="Founder"
                size="large"
                type="text"
                onKeyPress={(event) => {
                  if (!/^[a-zA-Z ]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <div className="label">Headquarters</div>
            <Form.Item
              name={"country"}
            >
              <Input
                placeholder="Headquarters"
                size="large"
                type="text"
                onKeyPress={(event) => {
                  if (!/^[a-zA-Z ]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <div className="label">Sector *</div>
            <Form.Item
              name={"category"}
             
            >
              <Input
                placeholder="Sector"
                size="large"
                type="text"
                onKeyPress={(event) => {
                  if (!/^[a-zA-Z ]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>


            <div className="label">Description *</div>
            <Form.Item
              name={"description"}
              rules={[
                {
                  max: 255,
                  message: "Description cannot exceed 255 characters",
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                maxLength={255}
                placeholder="Description"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <div className="label">Investment Stage *</div>
            <Form.Item
              name={"Stage"}
                     >
              <Input
                placeholder="Investment Stage"
                size="large"
                type="text"
                onKeyPress={(event) => {
                  if (!/^[a-zA-Z ]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <div className="label">Email *</div>
            <Form.Item
              name={"email"}
              rules={[
               
                { type: "email", message: "Enter a valid email" },
              ]}
            >
              <Input
                placeholder="Email"
                size="large"
                type="email"

              />
            </Form.Item>
            <div className="label">Fund Raised *</div>
            <Form.Item
              name={"fund_raised"}
            >
              <Input
                placeholder="Fund Raised"
                size="large"
                type="text"
                onKeyPress={(event) => {
                  if (!/^[a-zA-Z0-9,.₹£$€¥₩₽₪₹₦د.إDH]$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <div className="label">Team Members</div>
            <Form.Item
              name={"members"}
             
            >
              <Input placeholder="Team Members" size="large" type="text" />
            </Form.Item>

            <div className="label d-flex justify-content-between">
              Startup Logo
              {image_url ? (
                <Tooltip placement="top" title={"Remove logo"}>
                  <IoIosCloseCircleOutline
                    color="red"
                    size={20}
                    onClick={() => {
                      setImageUrl(null);
                      setFile(null);
                    }}
                  />
                </Tooltip>
              ) : null}
            </div>

            <Form.Item
              name={"logo"}

            >
              <ImagePicker

                onChange={(value: any) => {

                  setImageUrl(value?.url);
                  setFile(value?.file);
                }}
                fileURL={image_url}
                formats={["image/png", "image/jpeg", "image/jpg"]}
              />
            </Form.Item>
            <div className="label">Notable Investors</div>
            <Form.Item
              name={"notable_inverstors"}
              
            >
              <Input.TextArea
                rows={4}
                placeholder="Notable Inverstors"
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col sm={6}></Col>
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <Button block size="large" onClick={() => props.onClose()} disabled={loading}>
                  Close
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  htmlType="submit"
                  block
                  size="large"
                  type="primary"
                  loading={loading}
                  disabled={loading}
                >
                  Done
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Forms;
