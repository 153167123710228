import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Button, Form, message, Pagination } from "antd";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Empty } from "antd";

import Loading from "../../components/loading";
import EventCard from "./components/eventCard";

import API from "../../config/api";
import { DELETE, POST } from "../../utils/apiCalls";
import { defult, store } from "../../redux/slices/eventsSlice";

function EventScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const Events = useSelector((state: any) => state.Events);
  const User = useSelector((state: any) => state.User);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(query ? query?.get("page") : 1);
  const [take, setTake] = useState(query ? query?.get("limit") : 6);
  const [meta, setMeta] = useState<any>({});
  useEffect(() => {
    getData();
  }, [page, take]);

  useEffect(() => {
    setPage(query?.get("page") ? query?.get("page") : 1);
    setTake(query?.get("limit") ? query?.get("limit") : 6);
  }, [query]);
  const getData = async () => {
    try {
      setLoading(true);
      let obj = {
        startDate: form.getFieldValue("date"),
        query: form.getFieldValue("query"),
        page: page ? page : 1,
        take: take ? take : 6,
        companyId: User?.user?.companyId,
      };
      let url = API.EVENTS_LIST;
      const response: any = await POST(url, obj);
      if (response?.status) {
        const filteredData = response?.data.filter(
          (item: any) =>
            User?.user?.access?.root === "admin" || User?.user?.access[item?.id]
        );
        setData(filteredData);
        setMeta(response?.meta);
        if (page === "1") {
          dispatch(store(response?.data));
        }
        setIsLoading2(false);
        setLoading(false);
      } else {
        setData([]);
        setIsLoading2(false);
        setLoading(false);
      }
    } catch (err) {
      setIsLoading2(false);
      setLoading(false);
    }
  };

  const changePagination = (page: any, pageSize: any) => {
    query.set("page", page);
    query.set("limit", pageSize);
    navigate({ search: query.toString() });
    setIsLoading2(true);
    setPage(page);
    setTake(pageSize);
  };

  const deleteItem = async (id: any) => {
    try {
      setIsLoading2(true);
      let response: any = await DELETE(API.EVENTS_DELETE + id);
      if (response?.status) {
        if (Events?.defult?.id === id) {
          dispatch(defult({}));
        }
        getData();
        message.error("Deleted");
      } else {
        message.error("Oops.something gone wrong.");
      }
      setIsLoading2(false);
    } catch (err) {
      message.error("Oops.something gone wrong.");
      setIsLoading2(false);
    }
  };

  const toggleStatus = async (value: any) => {
    try {
      setIsLoading2(true);
      let url = API.EVENTS_EDIT;
      let obj = { ...value, status: value.status ? false : true };
      dispatch(
        defult(
          value.status ? {} : { ...value, status: value.status ? false : true }
        )
      );

      let response: any = await POST(url, obj);
      if (response.status) {
        getData();
        message.success("Successfully updated");
      } else {
        message.error("Oops.something gone wrong.");
      }
      setIsLoading2(false);
    } catch (err) {
      console.log("err", err);
      message.error("Oops.something gone wrong.");
      setIsLoading2(false);
    }
  };
  return (
    <div className="EventScreen">
      <div className="EventScreen-headerBox">
        <div>Your Events ( {data?.length} )</div>
        <div style={{ flex: 1 }} />
        {Array.isArray(User?.user.access[Events?.defult?.id]) &&
         User?.user.access[Events?.defult?.id].includes(8) ? null :  
                 
        <div>
          <Button
            type="primary"
            onClick={() => navigate("/company/events_from/0")}
            >
            ADD EVENTS +
          </Button>
        </div>
          }
      </div>
      <hr />
      {loading ? (
        <Loading />
      ) : (
        <div>
          <div className="EventScreen-Box">
            <Row>
              {data?.length > 0 ? (
                data?.map((item: any) => (
                  <Col sm={6} key={item?.id} className="mb-3">
                    <EventCard
                      item={item}
                      delete={(id: any) => deleteItem(id)}
                      toggleStatus={(item: any) => toggleStatus(item)}
                    />
                  </Col>
                ))
              ) : (
                <div style={{ marginTop: 100 }}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </Row>
            <br />
          </div>
          {meta?.total_count ? (
            <div className="d-flex justify-content-end">
              <Pagination
                defaultCurrent={Number(page)}
                defaultPageSize={Number(take)}
                total={meta?.total_count}
                pageSizeOptions={["6", "12", "24", "48"]}
                showSizeChanger
                showTotal={(total: any) => `Total ${meta?.total_count} Events`}
                onChange={(page, pageSize) => changePagination(page, pageSize)}
              />
            </div>
          ) : null}
        </div>
      )}
      <br />
    </div>
  );
}

export default EventScreen;
