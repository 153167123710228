import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET, PUT } from "../../../utils/apiCalls";
import API from "../../../config/api";
import Loading from "../../../components/loading";
import { Col, Row, Image, Card } from "react-bootstrap";
import { Button, Form, message, Switch, Tag } from "antd";
import PageHeader from "../../routes/pageHeader";
import { IoMailOutline, IoPeopleOutline } from "react-icons/io5";
import ProfileItem from "../../../website/vcDetailsScreen/components/profileItem";
import { MdOutlineMeetingRoom } from "react-icons/md";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { IoMdTime } from "react-icons/io";
import { on } from "events";
import { CiLogin, CiLogout } from "react-icons/ci";
import AssociateItem from "../../../website/vcDetailsScreen/components/associateItem";
import NoImage from "../../../assets/images/noimage.jpg";
const MeetingDetails = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const { uuid } = useParams();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      const url = API.MEETING + uuid;
      const res: any = await GET(url, null);
      if (res.status) {
        setData(res.data);
      } else {
        message.error("Something went wrong...");
      }
    } catch (err) {
      console.log("err", err);
      message.error("Error fetching meeting details.");
    } finally {
      setLoading(false);
    }
  };
  const updateMeeting = async (value: any) => {
    try {
      const url = API.UPDATE_MEETING_STATUS + data?.uuid;
      const obj = value;
      const res: any = await PUT(url, obj);
      if (res.status) {
        setData((prev: any) => ({ ...prev, ...value }));
        message.success("Meeting status updated successfully.");
      } else {
        message.error("Failed to update meeting status.");
      }
    } catch (error) {
      console.log("updateMeeting error", error);
    }
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <PageHeader title={"Meeting"}></PageHeader>
          <Card className="p-5">
            <Row>
              <Col sm={9}>
                <ProfileItem
                  icon={<MdOutlineMeetingRoom size={20} color="#0026ab" />}
                  label={"Meeting Room"}
                  value={data?.investors?.meeting_room}
                />
                <br />
                <ProfileItem
                  icon={<IoMdTime size={20} color="#0026ab" />}
                  label={"Meeting Slots"}
                  value={data?.slot}
                />
                <br />
                <ProfileItem
                  label={"Meeting Status"}
                  value={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 4,
                        padding: "8px 16px",
                        backgroundColor: data?.meeting_end
                          ? "#f0f0f0"
                          : data?.meeting_start
                          ? "#e0ffe0"
                          : "#ffe0e0",
                        borderRadius: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      <div
                        style={{
                          width: "12px",
                          height: "12px",
                          borderRadius: "50%",
                          backgroundColor: data?.meeting_end
                            ? "grey"
                            : data?.meeting_start
                            ? "green"
                            : "red",
                          marginRight: "10px",
                        }}
                      />
                      <span>
                        {data?.meeting_end
                          ? "Ended"
                          : data?.meeting_start
                          ? "In Progress"
                          : "Not Started"}
                      </span>
                    </div>
                  }
                />
              </Col>
              <Col sm={3} className="d-flex align-items-end">
                <Button
                  block
                  type={"primary"}
                  className={data?.meeting_start && !data?.meeting_end ? "btn-meeting-out" : ""}
                  onClick={() =>
                    data?.meeting_start && data?.meeting_end
                      ? updateMeeting({
                          meeting_start: true,
                          meeting_start_time: new Date(),
                          meeting_end: false,
                          meeting_end_time: new Date(),
                          vc_checkin: true,
                          vc_checkin_time: new Date(),
                          su_checkin: true,
                          su_checkin_time: new Date(),
                          vc_checkout: false,
                          vc_checkout_time: new Date(),
                          su_checkout: false,
                          su_checkout_time: new Date(),
                        })
                      : data?.meeting_start
                      ? updateMeeting({
                          meeting_end: true,
                          meeting_end_time: new Date(),
                          vc_checkout: true,
                          vc_checkout_time: new Date(),
                          su_checkout: true,
                          su_checkout_time: new Date(),
                        })
                      : updateMeeting({
                          meeting_start: true,
                          meeting_start_time: new Date(),
                          vc_checkin: true,
                          vc_checkin_time: new Date(),
                          su_checkin: true,
                          su_checkin_time: new Date(),
                        })
                  }
                >
                  {data?.meeting_start && !data?.meeting_end ? "End Meeting" : "Start Meeting"}
                </Button>
              </Col>
              <br /> <br />
            </Row>
            <hr />
            <Row>
              <Col sm={6}>
                <span className="investorScreen-text2 text-dark">
                  {" "}
                  Venture Capitalist
                </span>
                <br />
                <br />
                <div
                  style={{
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <Image
                    width="100%"
                    height="100%"
                    src={data?.investors?.logo || NoImage}
                    alt="Investor Logo"
                    rounded
                    style={{
                      objectFit: "contain",
                    }}
                  />
                </div>
                <br />
                <div className="investorScreen-text1">
                  {data?.investors?.company}
                </div>
                <div className="investorScreen-text2 meting-detailsDescription">
                  {data?.investors?.fullname}
                </div>
                <br></br>
                <div className="row align-items-center ">
                  <div className="col-8">
                    <Tag
                      color={
                        data?.vc_checkout
                          ? "volcano-inverse"
                          : data?.vc_checkin
                          ? "lime-inverse"
                          : "orange"
                      }
                    >
                      {data?.vc_checkout
                        ? "Checked Out"
                        : data?.vc_checkin
                        ? "Checked In"
                        : "Waiting"}
                    </Tag>
                  </div>

                  <div className="col-4">
                    <Button
                      block
                      onClick={() =>
                        data?.vc_checkin && data?.vc_checkout
                          ? updateMeeting({
                              vc_checkin: true,
                              vc_checkin_time: new Date(),
                              vc_checkout: false,
                            })
                          : data?.vc_checkin
                          ? updateMeeting({
                              vc_checkout: true,
                              vc_checkout_time: new Date(),
                            })
                          : updateMeeting({
                              vc_checkin: true,
                              vc_checkin_time: new Date(),
                            })
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        {data?.vc_checkin && !data?.vc_checkout ? (
                          <>
                            {"Check Out "}
                            <CiLogout size={20} />
                          </>
                        ) : (
                          <>
                            {"Check In "}
                            <CiLogin size={20} />
                          </>
                        )}
                      </div>
                    </Button>
                  </div>
                </div>
                <hr />
                <ProfileItem
                  icon={<HiOutlineBuildingOffice2 size={20} color="#0026ab" />}
                  label={"Position"}
                  value={data?.investors?.jobTitle}
                />
                <br /> <br />
                <ProfileItem
                  icon={<IoMailOutline size={20} color="#0026ab" />}
                  label={"Email Address"}
                  value={data?.investors?.email}
                />
                <br /> <br />
                <hr />
                <div className="VcDetailsScreen-item">
                  <div>
                    <IoPeopleOutline size={20} color="#0026ab" />
                  </div>
                  <div style={{ width: "100%" }}>
                    <div className="VcDetailsScreen-text3">Attendees</div>
                    <div style={{ margin: 10 }} />
                    {data?.investors?.Attendees?.length ? (
                      data?.investors?.Attendees.map((item: any) => {
                        return <AssociateItem item={item} />;
                      })
                    ) : (
                      <div className="VcDetailsScreen-text4">
                        You didn't add any attendees
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <span className="investorScreen-text2 text-dark"> Startup</span>
                <br />
                <br />
                <div
                  style={{
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <Image
                    width={"100%"}
                    src={data?.startups?.logo || NoImage}
                    alt="Company Logo"
                    rounded
                    style={{
                      objectFit: "contain",
                    }}
                  />
                </div>
                <br />
                <div className="investorScreen-text1">
                  {data?.startups?.name}
                </div>
                <div className="investorScreen-text2 meting-detailsDescription">
                  {data?.startups?.description}
                </div>
                <br></br>
                <div className="row align-items-center ">
                  <div className="col-8">
                    <Tag
                      color={
                        data?.su_checkout
                          ? "volcano-inverse"
                          : data?.su_checkin
                          ? "lime-inverse"
                          : "orange"
                      }
                    >
                      {data?.su_checkout
                        ? "Checked Out"
                        : data?.su_checkin
                        ? "Checked In"
                        : "Waiting"}
                    </Tag>
                  </div>

                  <div className="col-4">
                    <Button
                      block
                      onClick={() =>
                        data?.su_checkin && data?.su_checkout
                          ? updateMeeting({
                              su_checkin: true,
                              su_checkin_time: new Date(),
                              su_checkout: false,
                            })
                          : data?.su_checkin
                          ? updateMeeting({
                              su_checkout: true,
                              su_checkout_time: new Date(),
                            })
                          : updateMeeting({
                              su_checkin: true,
                              su_checkin_time: new Date(),
                            })
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        {data?.su_checkin && !data?.su_checkout ? (
                          <>
                            {"Check Out "}
                            <CiLogout size={20} />
                          </>
                        ) : (
                          <>
                            {"Check In "}
                            <CiLogin size={20} />
                          </>
                        )}
                      </div>
                    </Button>
                  </div>
                </div>
                <hr />
                <ProfileItem
                  icon={<HiOutlineBuildingOffice2 size={20} color="#0026ab" />}
                  label={"Category"}
                  value={data?.startups?.category}
                />
                <br /> <br />
                <ProfileItem
                  icon={<IoMailOutline size={20} color="#0026ab" />}
                  label={"Founder"}
                  value={data?.startups?.founder}
                />
                <br /> <br />
                <hr />
              </Col>
            </Row>
          </Card>
        </>
      )}
    </>
  );
};

export default MeetingDetails;
