import { useEffect } from "react";
import { Container } from "react-bootstrap";
import "./styles.scss";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Layout } from "antd";
import { useSelector } from "react-redux";

import SideBar from "./sideBar";
import CompanyHeader from "./Header";

import EventScreen from "../eventScreen";
import CreateEventScreen from "../eventScreen/create";

import DashboardScreen from "../dashboardScreen";
import SettingScreen from "../settingScreen";

import MeetingScreen from "../meetingScreen";
import StartupScreen from "../startupScreen";
import StartupDetails from "../startupScreen/details";
import InvestorScreen from "../investorScreen";
import InvestorDetails from "../investorScreen/details";
import MeetingRooms from "../meetingRooms";
import UserScreen from "../userScreen";
import MeetingDetails from "../meetingScreen/components/meetingDetails";
import InvalidLink from "./invalidLink";
import ProtectedRoute from "./protectedRoute";

function CompanyRoute() {
  const navigate = useNavigate();
  const Events = useSelector((state: any) => state.Events);

  useEffect(() => {
    if (!Events?.defult?.id) {
      navigate("/company/events?page=1&limit=6");
    }
  }, [Events?.defult?.id]);

  return (
    <>
      <CompanyHeader />
      <Layout>
        {Events?.defult?.id && (
          <Layout.Sider
            style={{ backgroundColor: "#f5f8fc", height: "92vh" }}
            width={260}
          >
            <SideBar />
          </Layout.Sider>
        )}
        <Layout>
          <Layout.Content
            style={{
              background: "#f5f8fc",
              height: "92vh",
              overflow: "scroll",
              overflowX: "hidden",
            }}
          >
            <div className="CompanyRoute-box">
              <Container>
                <Routes>
                  <Route path="/events" element={<EventScreen />} />
                  <Route
                    path="/events_from/:id"
                    element={<CreateEventScreen />}
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedRoute access={1}>
                        <DashboardScreen />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/meetings/:uuid"
                    element={
                      <ProtectedRoute access={2}>
                        <MeetingDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <ProtectedRoute access={7}>
                        <SettingScreen />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/meetings"
                    element={
                      <ProtectedRoute access={2}>
                        <MeetingScreen />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/startups"
                    element={
                      <ProtectedRoute access={4}>
                        <StartupScreen />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/staupdetails/:id"
                    element={
                      <ProtectedRoute access={4}>
                        <StartupDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/investors"
                    element={
                      <ProtectedRoute access={3}>
                        <InvestorScreen />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/ivsdetails/:id"
                    element={
                      <ProtectedRoute access={3}>
                        <InvestorDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/rooms"
                    element={
                      <ProtectedRoute access={5}>
                        <MeetingRooms />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/users"
                    element={
                      <ProtectedRoute access={6}>
                        <UserScreen />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/invalid-link" element={<InvalidLink />} />
                </Routes>
              </Container>
            </div>
          </Layout.Content>
        </Layout>
      </Layout>
    </>
  );
}

export default CompanyRoute;
