import { Button, Popconfirm, Tag, message } from "antd";
import { DELETE } from "../../../utils/apiCalls";
import API from "../../../config/api";
import moment from "moment";
import NoImage from "../../../assets/images/noimage.jpg";
import { useNavigate } from "react-router-dom";

function MeetingItem(props: any) {
  const [start, end] = props?.item?.slot?.split(" - ");
  const navigate = useNavigate();
  const deleteItem = async (id: any) => {
    try {
      let response: any = await DELETE(API.MEETINGS_DELETE + id);
      if (response?.status) {
        message.success("Meeting is deleted");
        props?.onChange?.();
      } else {
        message.error("Oops.something gone wrong.");
      }
    } catch (err) {
      console.log(err);
      message.error("Oops.something gone wrong.");
    }
  };

  return (
    <div className="startupScreen-SlotsItem">
      <div
        className="startupScreen-Slotstext1"
        style={{ borderLeftColor: props?.item?.id ? "#3dd966" : "#d93d3d" }}
      >
        <div>{start}</div>
        <div style={{ margin: 19 }} />
        <div>{end}</div>
      </div>
      <div className="startupScreen-SlotsItem2" >
        {props?.item?.investors?.id ? (
          <div>
            <img
              src={props?.item?.investors?.logo || NoImage}
              className="startupScreen-SlotsImg"
            />
          </div>
        ) : null}

        <div>
          <div className="startupScreen-Slotstext2">
            {props?.item?.investors?.company}
          </div>

          {props?.item?.investors?.id ? (
            <div className="startupScreen-Slotstext3">
              {props?.item?.investors?.fullname}
            </div>
          ) : null}
          {props?.item?.repeat ? (
            <Tag
              color="red"
              style={{ height: 20, fontSize: 10, marginTop: 10 }}
            >
              Repeated
            </Tag>
          ) : null}
        </div>
      </div>
      <div>
        <Tag>{props?.item?.investors?.meeting_room}</Tag>
      </div>
      <div className="startupScreen-SlotsItem3">
        {props?.delete ? (
          props?.item?.id ? (
            <Popconfirm
              title="Delete the meeting"
              description="Are you sure to delete this meeting?"
              onConfirm={() => deleteItem(props?.item?.id)}
              okText="Yes"
              cancelText="No"
              placement="leftBottom"
            >
              <Button size="small" type="default" danger>
                Delete Meeting
              </Button>
            </Popconfirm>
          ) : null
        ) : null}
        <div>
          {props?.showCreate ? (
            props?.item?.id ? (
              <div style={{ marginTop: 20, fontSize: 12, color: "grey" }}>
                Created :{" "}
                {moment(props?.item?.createdAt).format("MMM D, YYYY h:mm:ss A")}
              </div>
            ) : null
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default MeetingItem;
