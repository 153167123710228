import { Pie } from "react-chartjs-2";
import { Chart, ScriptableContext, registerables } from "chart.js";
Chart.register(...registerables);
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right" as const,
    },
  },
};

export default function BarChart(props: any) {
  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: ["Total Meetings", "Total VCs", "Total Startups"],
    datasets: [
      {
        data: [
          props?.data?.totalMeetings,
          props?.data?.TotalInvestors,
          props?.data?.totalStartups,
        ],
        backgroundColor: [
          "rgba(18,78,245,1)",
          "#3f7beb",
          "rgba(20,80,246,0.5)",
        ],
      },
    ],
  };
  return (
    <div className="DashboardScreen-box1">
      <div style={{ overflow: "hidden", height: 355, padding: 30 }}>
        <Pie options={options} data={data} width={"100%"} height={"100%"} />
      </div>
    </div>
  );
}
