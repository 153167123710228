import "./styles.scss";
import { Container } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import { Layout } from "antd";

import SideBar from "./sideBar";
import AdminHeader from "./Header";

import CompanyScreen from "../companyScreen";
import UsersScreen from "../usersScreen";

function AdminRoute() {
  return (
    <Layout>
      <Layout.Sider
        style={{ backgroundColor: "#f5f8fc", height: "100vh" }}
        width={250}
      >
        <SideBar />
      </Layout.Sider>
      <Layout>
        <AdminHeader />
        <Layout.Content
          style={{ background: "#f5f8fc", height: "70vh", overflow: "scroll" }}
        >
          <div className="AdminRoute-box">
            <Container>
              <Routes>
                <Route path="/companies" element={<CompanyScreen />} />
                <Route path="/users" element={<UsersScreen />} />
              </Routes>
            </Container>
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default AdminRoute;
