import { Button, DatePicker, Form, Input, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { SiMicrosoftexcel } from "react-icons/si";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import Loading from "../../components/loading";
import API from "../../config/api";
import { DELETE, GET, POST, PUT } from "../../utils/apiCalls";
import PageHeader from "../routes/pageHeader";
import DataTable from "./components/dataTable";
import EditVcModal from "./components/edit";
import Meetings from "./components/meetings";
import "./styles.scss";

function InvestorScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const Events = useSelector((state: any) => state.Events);
  const user = useSelector((state: any) => state.User.user);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState<any>({});

  const [selectedItem, setSelectedItem] = useState<any>({});
  const [openMeetings, setOpenMeetings] = useState(false);
  const [editVc, setEditVc] = useState(false);
  const [roomData, setRoomData] = useState([]) as any;

  const [data1, setData1] = useState<any>(1);

  useEffect(() => {
    getData();
    getMeetingRooms();
  }, [data1]);

  const getData = async () => {
    try {
      setIsLoading2(true);
      const params = {
        eventId: Events?.defult?.id,
        ...(query.get("query") && { query: query.get("query") }),
        ...(query.get("startDate") && { startDate: query.get("startDate") }),
        ...(query.get("page") && { page: query.get("page") }),
        ...(query.get("limit") && { take: query.get("limit") }),
        ...(query.get("nomeeting") && { nomeeting: true }),
        ...(query.get("noroom") && { noroom: true }),
      };
      const url = API.INVESTORS_LIST_SEARCH;
      const res: any = await GET(url, params);
      if (res.status) {
        setData(res.data);
        setMeta(res.meta);
      } else {
        message.error("Failed to fetch investors data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("An error occurred while fetching investors.");
    } finally {
      setIsLoading2(false);
      setLoading(false);
    }
  };

  const generateExcel = async () => {
    try {
      setIsLoading3(true);
      let url = API.INVESTORS_LIST_EVENT_ID + Events?.defult?.id;
      const response: any = await GET(url, null);
      if (response?.status) {
        const worksheet = XLSX.utils.json_to_sheet(response?.data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        const dataBlob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(dataBlob);
        link.download = "Report.xlsx";
        link.click();
      }
    } catch (err) {
      console.log(err);
      message.error("Couldn't generate Reports");
    } finally {
      setIsLoading3(false);
    }
  };

  const itemMeetings = (value: any) => {
    setSelectedItem(value);
    setOpenMeetings(!openMeetings);
  };

  const editItem = (value: any) => {
    setSelectedItem(value);
    setEditVc(!editVc);
  };
  const getMeetingRooms = async () => {
    try {
      let url = API.ROOMS_PICKER + Events?.defult?.id;
      const response: any = await GET(url, null);
      if (response?.status) {
        setRoomData(response?.data);
      } else {
        setRoomData([]);
      }
    } catch (err) {
      console.log(err, "err");
    } finally {
      setIsLoading2(false);
    }
  };

  const changeParams = (data: any) => {
    const queryParams = [
      { key: "query", value: data?.query || (data?.val && query.get("query")) },
      {
        key: "startDate",
        value: data?.startDate || (data?.val && query.get("startDate")),
      },
      { key: "nomeeting", value: data?.nomeeting || query.get("nomeeting") },
      { key: "noroom", value: data?.noroom || query.get("noroom") },
    ]
      .filter((param) => param.value)
      .map((param) => `&${param.key}=${param.value}`)
      .join("");
    const baseUrl = `/company/investors?page=${
      data?.page || query.get("page")
    }&limit=${data?.pageSize || query.get("limit")}`;
    navigate(`${baseUrl}${queryParams}`);
    setData1(data1 + 1);
  };
  const handleDeleteItem = async (id: any) => {
    try {
      const response: any = await DELETE(API.INVESTORS_DELETE + id);
      if (response?.status) {
        getData();
        message.success("Deleted successfully.");
      } else {
        message.error("Failed to delete.");
      }
    } catch (err) {
      message.error("An error occurred while deleting.");
    }
  };
  const updateAttendance = async (data: {
    id: string;
    has_attended: boolean;
    investor_id: string;
  }) => {
    try {
      const response: any = await PUT(
        `${API.INVESTORS_UPDATE_ATTENDANCE}${data.id}`,
        { has_attended: data?.has_attended,investor_id:data?.investor_id }
      );
      if (response?.status) {
        getData();
        message.success("Attendance updated successfully.");
      } else {
        message.error("Failed to update.");
      }
    } catch (err) {
      message.error("An error occurred while deleting.");
    }
  };
  return (
    <div>
      <PageHeader title={"Venture Capitalists ( VCs )"}>
        {loading ? null : (
          <Form
            form={form}
            onValuesChange={(_, allData) =>
              changeParams({
                ...allData,
                page: 1,
              })
            }
            initialValues={{
              query: query.get("query"),
              startDate:
                query.get("startDate") && dayjs(query.get("startDate")),
            }}
          >
            <div className="AdminHeader-extra">
              <Form.Item name={"query"} noStyle>
                <Input style={{ width: 200 }} placeholder="Search" allowClear />
              </Form.Item>
              <Form.Item name={"startDate"} noStyle>
                <DatePicker placeholder="Date" style={{ minWidth: 100 }} />
              </Form.Item>
              { Array.isArray(user.access[Events?.defult?.id]) &&
                user.access[Events?.defult?.id].includes(8) ? null :<>

                  <Button type="primary" onClick={() => editItem({}) }>
                ADD VC +
              </Button>
              <Button
                loading={isLoading3}
                onClick={() => generateExcel()}
                disabled={Array.isArray(user.access[Events?.defult?.id]) &&
                  user.access[Events?.defult?.id].includes(8)}
                  style={{ padding: 5, background: "#fff", cursor: "pointer" }}
                  >
                EXPORT <SiMicrosoftexcel size={20} color="green" />
              </Button>
                    </>
             }
              
            </div>
          </Form>
        )}
      </PageHeader>
      {loading ? (
        <Loading />
      ) : (
        <DataTable
          data={data}
          meta={meta}
          loading={isLoading2}
          meetings={(data: any) => itemMeetings(data)}
          onPageChange={(page: number, pageSize: number) =>
            changeParams({ page, pageSize, val: true })
          }
          delete={(id: any) => handleDeleteItem(id)}
          updateAttendance={(data: any) => updateAttendance(data)}
        />
      )}
      {openMeetings ? (
        <Meetings
          visible={openMeetings}
          data={selectedItem}
          onClose={() => {
            getData();
            itemMeetings({});
          }}
        />
      ) : null}
      {editVc ? (
        <EditVcModal
          visible={editVc}
          roomData={roomData}
          data={data}
          onClose={() => setEditVc(!editVc)}
          onFinish={() => getData()}
        />
      ) : null}
      <br />
    </div>
  );
}

export default InvestorScreen;
