import { useNavigate } from "react-router-dom";
import { Button, Popconfirm, Popover } from "antd";
import Clock from "react-live-clock";
import Profile from "../../assets/images/profilepic.jpg";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/userSlice";
import { clear } from "../../redux/slices/eventsSlice";

function CompanyHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const User = useSelector((state: any) => state.User);
  const Events = useSelector((state: any) => state.Events);

  const logOut = () => {
    dispatch(logout());
    dispatch(clear(null));
    sessionStorage.removeItem("sessionData"); // Clear sessionStorage
  };
  const selectEvent = () => {
    const userAccess = User?.user?.access[Events?.defult?.id] || [];
    const accessRoot = User?.user?.access?.root;

    if (accessRoot === "admin") {
      navigate("/company/dashboard");
    } else if (userAccess.includes(1)) {
      navigate("/company/dashboard");
    } else if (userAccess.includes(2)) {
      navigate("/company/meetings?page=1&limit=10");
    } else if (userAccess.includes(3)) {
      navigate("/company/investors?page=1&limit=10");
    } else if (userAccess.includes(4)) {
      navigate("/company/startups?page=1&limit=10");
    } else if (userAccess.includes(5)) {
      navigate("/company/rooms?page=1&limit=10");
    } else if (userAccess.includes(6)) {
      navigate("/company/users?page=1&limit=10");
    } else if (userAccess.includes(7)) {
      navigate("/company/settings");
    } else {
      navigate("/company/invalid-link");
    }
  };
  return (
    <div className="CompanyHeader">
      <div className="CompanyHeader-text1">Hello, {User?.user?.name}</div>
      <div className="CompanyHeader-timer">
        <Clock format={"h:mm:ss A"} ticking={true} />
      </div>
      <div>
        <Popover
          content={
            <div style={{ width: 150 }} className="CompanyHeader-popover">
              {/* <LiaUserShieldSolid size={50} color="green" /> */}
           
              <div>{User?.user?.name}</div>
              <div style={{ fontSize: 10, color: "grey" }}>
                {User?.user?.email}
              </div>
              <br />
              <Popconfirm
            placement="bottomRight"
            title="Delete"
            description="Are you sure to logout?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              logOut();
            }}
          >
              <Button block danger >
                LOGOUT
              </Button>
              </Popconfirm>
            </div>
          }
        >
          <div>
            <img
              src={User?.user?.company?.logo || Profile}
              style={{ height: 40, width: 40, borderRadius: 100 }}
              alt="profile"
            />
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default CompanyHeader;
