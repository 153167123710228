import "./styles.scss";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Row, Col, Container } from "react-bootstrap";
import { List, message } from "antd";
import QRCode from "react-qr-code";
import MeetingItem from "./components/meetingItem";
import { IoMdTime } from "react-icons/io";
import { IoMailOutline, IoPeopleOutline } from "react-icons/io5";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import AssociateItem from "./components/associateItem";
import { useEffect, useState } from "react";
import { GET } from "../../utils/apiCalls";
import API from "../../config/api";
import { useParams, useSearchParams } from "react-router-dom";
import Loading from "../../components/loading";
import Slots from "../../config/timeSlots.json";
import ProfileItem from "./components/profileItem";
import { LuCalendarCheck } from "react-icons/lu";
import moment from "moment";
import { MdOutlineMeetingRoom } from "react-icons/md";
import CompanyInfo from "./components/companyInfo";
import NoImage from "../../assets/images/noimage.jpg";
import dayjs from "dayjs";
function VcDetailsScreen() {
  const params = useParams();
  const [query] = useSearchParams();
  const eventId = query.get("event");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]) as any;
  const [logo, setLogo] = useState("") as any;
  const [meetings, setMeetings] = useState([]) as any;
  const [item, setItem] = useState({});
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      let url = API.INVESTORS_DETAILS + params?.id + `?eventId=${eventId}`;
      let res: any = await GET(url, null);
      if (res.status) {
        setData(res?.data);

        setLogo(res?.data?.data?.logo);
        let meeting = await sortMeetingsByTime(res?.data?.meetings);
        setMeetings(meeting);
      } else {
        message.error("Something went wrong...!");
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  const showSlot = (value: any) => {
    let slot = Slots?.filter((i: any) => i.value === value);
    return slot[0]?.label;
  };

  const sortMeetingsByTime = (meetings: any) => {
    try {
      return meetings.sort((a: any, b: any) => {
        const parseTime = (time: any) => {
          const [hoursMinutes, period] = time.split(" ");
          let [hours, minutes] = hoursMinutes.split(":").map(Number);
          if (period === "PM" && hours !== 12) {
            hours += 12;
          }
          if (period === "AM" && hours === 12) {
            hours = 0;
          }
          return hours * 60 + minutes;
        };
        const [startA] = a.slot.split(" - ");
        const [startB] = b.slot.split(" - ");
        return parseTime(startA) - parseTime(startB);
      });
    } catch (err) {
      console.log("err = = = = >", err);
      return [];
    }
  };

  return (
    <div>
      {loading ? (
        <div style={{ height: "80vh" }}>
          <Loading />
        </div>
      ) : (
        <>
          <Header logo={data?.data?.eventDetails?.image || NoImage} />
          <div className="VcDetailsScreen">
            <Container>
              <Row>
                <Col sm={3}>
                  <br />
                  <div>
                    <div className="VcDetailsScreen-roud">
                      <div className="VcDetailsScreen-roud1">
                        <div className="VcDetailsScreen-roud2">
                          <img
                            src={data?.data?.logo || NoImage}
                            alt="Logo"
                            style={{
                              width: 100,
                              height: 100,
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </div>
                      <div>{data?.data?.company}</div>
                    </div>
                    <div className="VcDetailsScreen-text1">
                      {data?.data?.fullname}
                    </div>
                    <div className="VcDetailsScreen-text2">
                      {data?.data?.jobTitle}
                    </div>
                    <hr />
                    <ProfileItem
                      icon={
                        <HiOutlineBuildingOffice2 size={20} color="#0026ab" />
                      }
                      label={"Company"}
                      value={data?.data?.company}
                    />
                    <br />
                    <br />
                    <ProfileItem
                      icon={<IoMailOutline size={20} color="#0026ab" />}
                      label={"Email Address"}
                      value={data?.data?.email}
                    />
                    <br />
                    <br />
                    <ProfileItem
                      icon={<IoMdTime size={20} color="#0026ab" />}
                      label={"Meeting Slots"}
                      value={showSlot(data?.data?.timeSlots)}
                    />
                    <br />
                    <br />
                    <ProfileItem
                      icon={<MdOutlineMeetingRoom size={20} color="#0026ab" />}
                      label={"Meeting Room"}
                      value={data?.data?.meeting_room}
                    />
                    <br /> <br />
                    <ProfileItem
                      icon={<LuCalendarCheck size={20} color="#0026ab" />}
                      label={"Registered On"}
                      value={moment(data?.data?.createdAt).format(
                        "MMM D, YYYY h:mm:ss A"
                      )}
                    />
                    <hr />
                    <div className="VcDetailsScreen-item">
                      <div>
                        <IoPeopleOutline size={20} color="#0026ab" />
                      </div>
                      <div style={{ width: "100%" }}>
                        <div className="VcDetailsScreen-text3">Attendees</div>
                        <div style={{ margin: 10 }} />
                        {data?.data?.Attendees?.length ? (
                          data?.data?.Attendees.map((item: any, index: any) => {
                            return <AssociateItem item={item} key={index} />;
                          })
                        ) : (
                          <div className="VcDetailsScreen-text4">
                            You did not add any additional attendees
                          </div>
                        )}
                      </div>
                    </div>
                    <br />
                  </div>
                </Col>
                <Col sm={1}>
                  <div className="devider" />
                </Col>
                <Col sm={8}>
                  <br />
                  {/* {data?.data?.eventDetails?.id ? (
                    <div className="VcDetailsScreen-evnt">
                      <img
                        className="VcDetailsScreen-evnt-image"
                        src={data?.data?.eventDetails?.image || NoImage}
                      />
                      <div className="VcDetailsScreen-text10">
                        {data?.data?.eventDetails?.title}
                      </div>
                    </div>
                  ) : null} */}
                  <br />
                  <div className="VcDetailsScreen-box1">
                    <div>
                      <div className="VcDetailsScreen-text5">My Meetings</div>
                      <div className="VcDetailsScreen-text6">
                        See your scheduled meetings on the calendar below :
                      </div>
                      <div className="VcDetailsScreen-text11">
                        Date :{" "}
                        {dayjs(data?.data?.eventDetails?.event_date).format(
                          "DD-MM-YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: 20 }} />
                  {meetings?.map((item: any) => {
                    return (
                      <MeetingItem
                        key={item?.id}
                        item={item}
                        onClick={(val: any) => {
                          setItem(val);
                          setShowCompanyInfo(true);
                        }}
                      />
                    );
                  })}
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
      {showCompanyInfo && (
        <CompanyInfo
          item={item}
          visible={showCompanyInfo}
          onCancel={() => setShowCompanyInfo(false)}
        />
      )}
      <Footer />
    </div>
  );
}

export default VcDetailsScreen;
