import { useState } from "react";
import "./styles.scss";
import Diamond from "../../assets/images/Arrows.svg";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ImagePicker from "../../components/imagePicker";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Form, Input, Select, Steps, message } from "antd";
import { MdAdd } from "react-icons/md";
import { IoMdRemove } from "react-icons/io";
import Success from "./success";
import { COMPRESS_IMAGE, POST } from "../../utils/apiCalls";
import API from "../../config/api";
import Slots from "../../config/timeSlots.json";

function VcFormScreen() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [image_url, setImageUrl] = useState<any>();
  const [file, setFile] = useState<any>();

  const showSlot = (value: any) => {
    let slot = Slots?.filter((i: any) => i.value === value);
    return slot[0];
  };

  const onFinish = async (val: any) => {
    setLoading(true);
    try {
      let images: any = await COMPRESS_IMAGE(file);
      let obj = {
        fullname: val?.fullname,
        email: val?.email,
        timeSlots: val?.timeSlots,
        jobTitle: val?.jobTitle,
        company: val?.company,
        Attendees: val?.Attendees,
        slots: showSlot(val?.timeSlots).slots,
        logo: file?.name ? images?.url : null,
      };
      let url = API.INVESTORS_ADD;
      let res: any = await POST(url, obj);
      if (res.status) {
        setData(res?.data);
        setSuccess(true);
        window.scrollTo(0, 0);
        form.resetFields();
        setImageUrl(null);
        setFile(null);
      } else {
        message.error(res.message);
      }
    } catch (err) {
      console.log("err", err);
      message.error("Something went wrong...!");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Header />
      <div className="VcFormScreen-Box">
        <Container fluid={true}>
          <Row style={{ height: "100%" }}>
            <Col sm={5} style={{ padding: 0 }} className="VcFormScreen-sticky">
              <div className="VcFormScreen-Box2">
                <Row>
                  <Col sm={2} xs={12}></Col>
                  <Col sm={10} md={10} xs={12}>
                    <br />
                    <div className="VcFormScreen-Box3">
                      <div className="VcFormScreen-box5">
                        {/* <h1 className="VcFormScreen-txt1">
                          Investment Opportunity
                        </h1> */}
                        <h6 className="VcFormScreen-txt2">
                          To ensure we connect you with the most relevant
                          startups, please fill out the registration form below.
                        </h6>
                        <br />
                        <h6 className="VcFormScreen-txt2">
                          Mark your calendar for this in-person meetup:
                        </h6>
                        <div className="VcFormScreen-line" />
                        <br />
                        <h5
                          className="VcFormScreen-txt4"
                          style={{ fontSize: 30 }}
                        >
                          3rd July
                        </h5>
                        <h5
                          className="VcFormScreen-txt4"
                          style={{ fontSize: 20 }}
                        >
                          Hub71, Al Khatem Tower, ADGM, Al Maryah Island, Abu
                          Dhabi
                        </h5>
                        <br />
                        <Steps
                          direction="vertical"
                          current={5}
                          progressDot
                          items={[
                            {
                              title: (
                                <div className="VcFormScreen-txt6">
                                  <strong className="VcFormScreen-text7">
                                    9.30 AM
                                  </strong>{" "}
                                  / Meet and Greet (Briefing and intro with
                                  Hub71)
                                </div>
                              ),
                            },
                            {
                              title: (
                                <div className="VcFormScreen-txt6">
                                  <strong className="VcFormScreen-text7">
                                    10 AM to 1 PM{" "}
                                  </strong>
                                  / Startup meetings (30mins per startup)
                                </div>
                              ),
                            },
                            {
                              title: (
                                <div className="VcFormScreen-txt6">
                                  <strong className="VcFormScreen-text7">
                                    1 PM to 2 PM{" "}
                                  </strong>{" "}
                                  / Lunch (14th floor)
                                </div>
                              ),
                            },
                            {
                              title: (
                                <div className="VcFormScreen-txt6">
                                  <strong className="VcFormScreen-text7">
                                    2 PM to 5 PM
                                  </strong>{" "}
                                  / Startup meetings (30mins per startup)
                                </div>
                              ),
                            },
                            {
                              title: (
                                <div className="VcFormScreen-txt6">
                                  <strong className="VcFormScreen-text7">
                                    5.30 PM to 7.30 PM
                                  </strong>{" "}
                                  / Networking at Flamingo room
                                </div>
                              ),
                            },
                          ]}
                        />

                        <br />
                        <div className="VcFormScreen-line" />
                        <br />
                        <h6 className="VcFormScreen-txt2">
                          Don’t miss this opportunity to engage with Hub71
                          Cohort 14 startups and network with fellow investors.
                          We look forward to seeing you there!
                        </h6>
                      </div>

                      <img src={Diamond} className="VcFormScreen-img1" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={7} xs={12}>
              <div className="VcFormScreen-Box4">
                <Row>
                  <Col sm={1} xs={12}></Col>
                  <Col sm={10} xs={12}>
                    <br /> <br />
                    {success ? (
                      <Success data={data} goBack={() => setSuccess(false)} />
                    ) : (
                      <Form form={form} onFinish={onFinish}>
                        <Row>
                          <Col sm={6}>
                            <div className="label">Contact Person *</div>
                            <Form.Item
                              name={"fullname"}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing Contact Person",
                                },
                              ]}
                            >
                              <Input size="large" />
                            </Form.Item>
                            <div className="label">Email *</div>
                            <Form.Item
                              name={"email"}
                              rules={[
                                { required: true, message: "Missing Email" },
                                {
                                  type: "email",
                                  message: "Email not valid email",
                                },
                              ]}
                            >
                              <Input size="large" />
                            </Form.Item>
                            <div className="label">Time Slots *</div>
                            <Form.Item
                              name={"timeSlots"}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing Time Slot",
                                },
                              ]}
                            >
                              <Select size="large">
                                {Slots?.map((item: any) => {
                                  return (
                                    <Select.Option
                                      key={item?.id}
                                      value={item.value}
                                    >
                                      {item?.label}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col sm={6}>
                            <div className="label">Job Title *</div>
                            <Form.Item
                              name={"jobTitle"}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing Job Title",
                                },
                              ]}
                            >
                              <Input size="large" />
                            </Form.Item>
                            <div className="label">Company *</div>
                            <Form.Item
                              name={"company"}
                              rules={[
                                { required: true, message: "Missing Company" },
                              ]}
                            >
                              <Input size="large" />
                            </Form.Item>
                            <div className="label">Company Logo</div>
                            <ImagePicker
                              onChange={(value: any) => {
                                setImageUrl(value?.url);
                                setFile(value?.file);
                              }}
                              fileURL={image_url}
                              size={3145728}
                              format={"image/"}
                            />
                            <div className="VcFormScreen-txt5">
                              Max file size is 3MB. Only image files (JPEG, PNG,
                              GIF) are allowed.
                            </div>
                          </Col>
                        </Row>
                        <br />
                        <Form.List name="Attendees">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(
                                ({ key, name, ...restField }, index: any) => (
                                  <div
                                    className="VcFormScreen-FormBox2"
                                    key={index}
                                  >
                                    <div className="VcFormScreen-FormBox3">
                                      <div className="VcFormScreen-txt3">
                                        Attendees {index + 1}
                                      </div>
                                      <div onClick={() => remove(name)}>
                                        <IoMdRemove size={30} color="red" />
                                      </div>
                                    </div>
                                    <Row>
                                      <Col sm={6}>
                                        <div className="label">Full Name *</div>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "fullName"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "full Name Required",
                                            },
                                          ]}
                                        >
                                          <Input size="large" />
                                        </Form.Item>
                                      </Col>
                                      <Col sm={6}>
                                        <div className="label">Job Title *</div>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "jobTitle"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Job Title Required",
                                            },
                                          ]}
                                        >
                                          <Input size="large" />
                                        </Form.Item>
                                      </Col>
                                      <Col sm={6}>
                                        <div className="label">Email *</div>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "email"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Email Required",
                                            },
                                          ]}
                                        >
                                          <Input size="large" />
                                        </Form.Item>
                                      </Col>
                                      <Col sm={6}>
                                        <div className="label">Company *</div>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "company"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Company Required",
                                            },
                                          ]}
                                        >
                                          <Input size="large" />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </div>
                                )
                              )}
                              {fields?.length <= 2 ? (
                                <div
                                  className="VcFormScreen-formBox"
                                  onClick={() => add()}
                                >
                                  <div className="label">Add Attendees</div>
                                  <div>
                                    <MdAdd
                                      size={30}
                                      color="#0026ab"
                                      style={{ marginBottom: 4 }}
                                    />
                                  </div>
                                </div>
                              ) : null}
                            </>
                          )}
                        </Form.List>
                        <br />
                        <Row>
                          <Col sm={6}></Col>
                          <Col sm={6}>
                            <Button
                              type="primary"
                              block
                              style={{
                                height: 50,
                                fontFamily: "Roc_Grotesk_Wide",
                              }}
                              htmlType="submit"
                              loading={loading}
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                    <br /> <br />
                  </Col>
                  <Col sm={1} xs={12}></Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default VcFormScreen;
