import { useEffect, useState } from "react";
import { DELETE, GET, POST, PUT } from "../../../utils/apiCalls";
import { useSelector } from "react-redux";
import API from "../../../config/api";
import Loading from "../../../components/loading";
import { Alert, Button, Card, Form, Input, message } from "antd";
import ReactQuill from "react-quill";
import Defult from "./defult.json";
function MasterTemplate() {
  const [form] = Form.useForm();
  const Events = useSelector((state: any) => state.Events);
  const user = useSelector((state: any) => state.User.user);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [data, setData] = useState<any>({});
  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      let api = API?.MAIL_TEMPLATE_LIST + `${Events?.defult?.id}/meeting`;
      const response: any = await GET(api, null);
      if (response?.status) {
        if (response?.data?.length) {
          setData(response?.data[0]);
          setValue(response?.data[0]?.content);
        } else {
          setData({});
        }
        setLoading(false);
      } else {
        setData([]);
        setLoading(false);
      }
    } catch (err) {
      setData([]);
      setLoading(false);
    }
  };

  const submit = async (values: any) => {
    try {
      setLoading2(true);
      console.log(
        value?.replace(new RegExp("<p>Dear ${Person_name},</p>", "g"), "")
      );
      let obj = {
        id: values?.id,
        name: "meeting Template",
        type: "meeting",
        subject: values?.subject,
        content: value?.replace(
          new RegExp("<p>Dear ${Person_name},</p>", "g"),
          ""
        ),
        eventId: Events?.defult?.id,
      };
      let url = data?.id
        ? API.MAIL_TEMPLATE_EDIT + data?.id
        : API.MAIL_TEMPLATE_ADD;
      let response: any = data?.id ? await PUT(url, obj) : await POST(url, obj);
      message.success("Template updated successfully");
      setLoading2(false);
    } catch (err) {
      console.log("err", err);
      message.error("Oops.something gone wrong.");
      setLoading2(false);
    }
  };

  const setDefultMail = () => {
    let defolt = Defult;
    form.setFieldsValue({
      name: "meeting Template",
      subject: defolt?.subject,
      content: defolt?.content,
    });
    setValue(
      defolt?.content?.replace(
        new RegExp("<p>Dear ${Person_name},</p>", "g"),
        ""
      )
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <Form
      form={form}
      onFinish={submit}
      initialValues={{
        name: data?.name,
        subject: data?.subject,
        content: data?.content,
      }}
    >
      <Card
        title={"Meeting Mail Template"}
        extra={[
          <div className="settingScreen-pageHeader" style={{ marginTop: 15 }}>
            {Array.isArray(user.access[Events?.defult?.id]) &&
            user.access[Events?.defult?.id].includes(8) ? null : (
              <>
                <Button type="default" onClick={() => setDefultMail()}>
                  Set Defult
                </Button>
                <Button loading={loading2} type="primary" htmlType="submit">
                  Update
                </Button>
              </>
            )}
          </div>,
        ]}
      >
        <div className="label">Subject</div>
        <Form.Item
          name={"subject"}
          rules={[{ required: true, message: "Subject Required" }]}
        >
          <Input size="large" />
        </Form.Item>
        <div className="label">Content</div>
        <Form.Item name={"content"}>
          <div style={{ display: "none" }}>{value}</div>
          <ReactQuill
            style={{ height: 300 }}
            theme="snow"
            value={value}
            onChange={setValue}
          />
        </Form.Item>
        <br /> <br />
        <Alert
          message="Values and Definitions"
          description={
            <ol>
              <li>
                {" "}
                <span style={{ color: "red" }}>{"${EventDate}"}</span> =
                EventDate
              </li>
              <li>
                {" "}
                <span style={{ color: "red" }}>{"${Location}"}</span> = Location
              </li>
              <li>
                {" "}
                <span style={{ color: "red" }}>{"${Room}"}</span> = VC assigned
                meeting room
              </li>
              <li>
                <span style={{ color: "red" }}>{"${Meeting_slots}"} </span> = VC
                assigned meeting slots and companies
              </li>
              <li>
                {" "}
                <span style={{ color: "red" }}>{" ${Link}"} </span> = VC details
                link
              </li>
            </ol>
          }
          type="info"
          showIcon
        />
      </Card>
    </Form>
  );
}

export default MasterTemplate;
