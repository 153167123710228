import {
  Button,
  Pagination,
  Table,
  Tag,
  message,
  Tooltip,
  Popover,
  Spin,
  Popconfirm,
} from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import { CiMail, CiRead } from "react-icons/ci";
import { IoCopyOutline } from "react-icons/io5";
import Slots from "../../../config/timeSlots.json";
import API from "../../../config/api";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { LuMousePointer2 } from "react-icons/lu";
import { FaWhatsapp } from "react-icons/fa";
import { useState } from "react";
import { POST, PUT } from "../../../utils/apiCalls";
import NoImage from "../../../assets/images/noimage.jpg";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiOutlineLogout, AiOutlineLogin } from "react-icons/ai";

function DataTable(props: any) {
  const navigation = useNavigate();
  const Events = useSelector((state: any) => state.Events);
  const user = useSelector((state: any) => state.User.user);

  const [loading, setLoading] = useState(false);

  const showSlot = (value: any) => {
    let slot = Slots?.filter((i: any) => i.value === value);
    return slot[0];
  };

  const copyToClipBoard = (token: any) => {
    let link = `${API.WEB_URL}/vc-details/${token}/?event=${Events?.defult?.id}`;
    navigator.clipboard.writeText(link).then(() => {
      message.success("Link copied");
    });
  };

  const sendMail = async (item: any) => {
    try {
      setLoading(true);
      let link = `${API.WEB_URL}/vc-details/${item.uuid}/?event=${Events?.defult?.id}`;
      let obj = {
        image: Events?.defult?.image,
        name: Events?.defult?.title,
        url: link,
        subject: `Scan to know ${item?.company}'s Meetings`,
        to: item?.email,
      };
      let response: any = await POST(API.URL_SHARE_MAIL, obj);
      if (response.status) {
        message.success(response.message);
      } else {
        message.error("Oops.something gone wrong.");
      }
      setLoading(false);
    } catch (err) {
      message.error("Oops.something gone wrong.");
      setLoading(false);
    }
  };

  const sendWhatsapp = (item: any) => {
    const message = `Click to view ${item?.company}'s Meetings ${API.WEB_URL}/vc-details/${item.uuid}/?event=${Events?.defult?.id}`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };

  const columns = [
    {
      title: "",
      dataIndex: "logo",
      key: "logo",
      render: (item: any) => (
        <div>
          <img
            src={item || NoImage}
            style={{
              width: 50,
              height: 50,
              objectFit: "contain",
              backgroundColor: "#f5f5f5",
              borderRadius: 100,
              overflow: "hidden",
            }}
          />
        </div>
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Job",
      dataIndex: "jobTitle",
      key: "jobTitle",
    },

    {
      title: "Contact Person",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Time Slots",
      dataIndex: "timeSlots",
      key: "timeSlots",
      render: (item: any) => <Tag>{showSlot(item)?.label}</Tag>,
    },
    {
      title: "Room",
      dataIndex: "meeting_room",
      key: "meeting_room",
    },
    {
      title: "Slots",
      render: (item: any) => (
        <div>
          {item?.slots}/{showSlot(item?.timeSlots)?.slots}
        </div>
      ),
    },

    {
      title: "Registerd On",
      dataIndex: "createdAt",
      render: (item: any) => (
        <div style={{ fontSize: 12 }}>
          {moment(item).format("MMM D, YYYY h:mm:ss A")}
        </div>
      ),
    },
    {
      title: "Attendance",
      onCell: (record: any) => ({
        onClick: (event: any) => handleCellClick(event, "Actions", record),
      }),
      render: (item: any) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "10px",
            transition: "background 0.3s ease",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: "14px",
              color: item?.has_attended ? "#52c41a" : "#f5222d",
            }}
          >
            {item?.has_attended ? (
              <Tag
                color="green"
                style={{ width: 100, textAlign: "center", cursor: "default" }}
              >
                Attended
              </Tag>
            ) : (
              <Tag
                color="red"
                style={{ width: 100, textAlign: "center", cursor: "default" }}
              >
                Not Attended
              </Tag>
            )}
          </span>
          <Button
            size="small"
            type="primary"
            style={{
              fontSize: "12px",
              background: item?.has_attended ? "#ff4d4f" : "#52c41a",
              borderColor: item?.has_attended ? "#ff4d4f" : "#52c41a",
              color: "#fff",
              transition: "all 0.3s ease",
            }}
            onClick={() =>
              props.updateAttendance({
                id: item?.uuid,
                investor_id:item?.id,
                has_attended: !item?.has_attended,
              })
            }
          >
            {item?.has_attended ? (
              <Tooltip title="Check Out">
                <AiOutlineLogout size={20} />
              </Tooltip>
            ) : (
              <Tooltip title="Check In">
                <AiOutlineLogin size={20} />
              </Tooltip>
            )}
          </Button>
        </div>
      ),
    },

    {
      title: "Actions",
      onCell: (record: any) => ({
        onClick: (event: any) => handleCellClick(event, "Actions", record),
      }),
      align: "center" as const,
      render: (item: any) => (
        <div className="table-item">
          <div onClick={() => navigation(`/company/ivsdetails/${item.uuid}`)}>
            <Tooltip
              placement="top"
              title={<div style={{ fontSize: 10 }}>Profile Details</div>}
            >
              <CiRead size={25} color="#000" />
            </Tooltip>
          </div>

          <Popover
            placement="bottom"
            trigger={"click"}
            overlayStyle={{ width: 250 }}
            content={
              <>
               {Array.isArray(user.access[Events?.defult?.id]) &&
               user.access[Events?.defult?.id].includes(8) ? null : 
               <div className="d-flex gap-4 justify-content-center">
                  {" "}
                  {loading ? (
                    <Button size="small">
                      <Spin size="small" />
                    </Button>
                  ) : (
                    <Tooltip
                      placement="bottom"
                      title={
                        <div style={{ fontSize: 10 }}>Share QR via mail</div>
                      }
                    >
                      <Button size="small" onClick={() => sendMail(item)}>
                        <CiMail size={20} />
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip
                    placement="bottom"
                    title={
                      <div style={{ fontSize: 10 }}>Share QR via whatsapp</div>
                    }
                  >
                    <Button size="small" onClick={() => sendWhatsapp(item)}>
                      <FaWhatsapp size={20} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    placement="bottom"
                    title={
                      <div style={{ fontSize: 10 }}>Copy Profile Link</div>
                    }
                  >
                    <Button
                      size="small"
                      onClick={() => copyToClipBoard(item?.uuid)}
                    >
                      <IoCopyOutline size={20} />
                    </Button>
                  </Tooltip>
                </div>
            }
                <div className="d-flex flex-column gap-2 text-center p-3">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={Events?.defult?.image} alt="" width={20} />{" "}
                    {Events?.defult?.title}
                  </div>
                  <div>
                    <QRCode
                      size={100}
                      value={`${API.WEB_URL}/vc-details/${item.uuid}/?event=${Events?.defult?.id}`}
                      viewBox={`0 0 256 256`}
                      bgColor={"transparent"}
                      fgColor={"#0026ab"}
                    />
                    <div style={{ fontSize: 10, marginTop: 10 }}>
                      Scan to know {item?.company}'s Meetings
                    </div>
                  </div>
                </div>
              </>
            }
          >
            <LuMousePointer2 size={20} color="green" />
          </Popover>
          {Array.isArray(user.access[Events?.defult?.id]) &&
               user.access[Events?.defult?.id].includes(8) ? null : 
          <div onClick={() => props.meetings(item)}>
            <Button size="small" type="primary" style={{ fontSize: 12 }}>
              Meetings
            </Button>
          </div>
    }
         { Array.isArray(user.access[Events?.defult?.id]) &&
          user.access[Events?.defult?.id].includes(8) ? null :
          <Popconfirm
            placement="bottomLeft"
            title="Delete"
            description="Are you sure to delete this?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              props?.delete(item?.id);
            }}
          >
         
            <div>
              <Tooltip
                placement="top"
                title={<div style={{ fontSize: 10 }}>Delete Room</div>}
              >
                <IoIosCloseCircleOutline size={25} color="red" />
              </Tooltip>
            </div>
          </Popconfirm>}
        </div>
      ),
    },
  ];
  const handleRowClick = (record: any) => {
    navigation(`/company/ivsdetails/${record.uuid}`);
  };
  const handleCellClick = (event: any, column: any, record: any) => {
    if (column !== "Actions") {
      handleRowClick(record);
    } else {
      event.stopPropagation();
    }
  };
  return (
    <>
      <Table
        size="small"
        scroll={{ x: 1000 }}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        style={{ cursor: "pointer" }}
        dataSource={props?.data}
        columns={columns}
        pagination={false}
        loading={props?.loading}
      />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          defaultCurrent={props?.meta?.page}
          defaultPageSize={props?.meta?.take}
          total={props?.meta?.total_count}
          showSizeChanger
          showTotal={(total: any) => `Total ${props?.meta?.total_count} VCs`}
          onChange={(page, pageSize) => props.onPageChange(page, pageSize)}
          pageSizeOptions={["1", "12", "24", "48"]}
        />
      </div>
    </>
  );
}

export default DataTable;
