import { LoadingOutlined } from "@ant-design/icons"; // Import the icons you need
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Select,
  Spin,
  Table,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import { MdSave, MdCancel } from "react-icons/md";

const UploadTable = (props: any) => {
  const [editingRowIndex, setEditingRowIndex] = useState<number | null>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const firstDraftRow = document.querySelector(".row-draft");
    if (firstDraftRow) {
      firstDraftRow.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    setEditingRowIndex(null);
  }, [props?.data]); // Re-run when data changes

  const handleEdit = (record: any, index: number) => {
    setEditingRowIndex(index); // Set the editing row to the index of the record
    form.setFieldsValue(record); // Set the row values into the form
  };

  const handleSave = (id: any) => {
    form
      .validateFields()
      .then((values) => {
        props?.updateItem(id, values);
        if (!props?.loading) {
          setEditingRowIndex(null);
          form.resetFields();
        }
      })
      .catch((err) => {
        const errorMessage = err?.errorFields?.map((item: any) =>
          item?.errors?.length ? item?.errors[0] : ""
        );
        message.error(errorMessage.join(", "));
      });
  };
  const columns = [
    {
      title: "Sl. No.",
      width: 80, // Reduced width since it's just a small number column
      render: (_item: any, record: any, index: number) => (
        <div>{index + 1}</div>
      ),
    },
    {
      title: "Name",
      width: 150, // Reduced from 300 to 150 for better fit
      render: (_item: any, record: any, index: number) =>
        editingRowIndex === index ? (
          <Form.Item
            noStyle
            name="name"
            rules={[
              { required: true, message: "Please enter first name" },
              {
                pattern: /^[A-Za-z\s]+$/,
                message: "First name must contain only alphabetic characters",
              },
            ]}
          >
            <Input />
          </Form.Item>
        ) : (
          <div>{record?.name}</div>
        ),
    },
    {
      title: "Sector",
      width: 150, // Reduced from 300 to 150 for better fit
      render: (_item: any, record: any, index: number) =>
        editingRowIndex === index ? (
          <Form.Item noStyle name="category">
            <Input />
          </Form.Item>
        ) : (
          <div>{record?.category}</div>
        ),
    },
    {
      title: "Email",
      width: 150, 
      render: (_item: any, record: any, index: number) =>
        editingRowIndex === index ? (
          <Form.Item noStyle name="email">
            <Input />
          </Form.Item>
        ) : (
          <div>{record?.email}</div>
        ),
    },
    {
      title: "Country Code",
      width: 150,
      render: (_item: any, record: any, index: number) =>
        editingRowIndex === index ? (
          <Form.Item noStyle name="countrycode">
            <Input />
          </Form.Item>
        ) : (
          <div>{record?.countrycode}</div>
        ),
    },
    {
      title: "Phone Number",
      width: 150, 
      render: (_item: any, record: any, index: number) =>
        editingRowIndex === index ? (
          <Form.Item noStyle name="phone">
            <Input />
          </Form.Item>
        ) : (
          <div>{record?.phone}</div>
        ),
    },

    {
      title: "Founder",
      width: 200, // Adjusted width to accommodate longer emails
      render: (_item: any, record: any, index: number) =>
        editingRowIndex === index ? (
          <Form.Item noStyle name="founder">
            <Input />
          </Form.Item>
        ) : (
          <div>{record?.founder}</div>
        ),
    },
    {
      title: "Headquarters",
      width: 180, // Adjusted for dropdown consistency
      render: (_item: any, record: any, index: number) =>
        editingRowIndex === index ? (
          <Form.Item noStyle name="headquarters">
            <Input />
          </Form.Item>
        ) : (
          <div>{record?.country}</div>
        ),
    },
    {
      title: "Description",
      width: 120, // Adjusted for a shorter field like tag
      render: (_item: any, record: any, index: number) =>
        editingRowIndex === index ? (
          <Form.Item noStyle name="description">
            <Input />
          </Form.Item>
        ) : (
          <div>{record?.description}</div>
        ),
    },
    {
      title: "Investment Stage",
      width: 200, // Reduced from 300 for better balance
      render: (_item: any, record: any, index: number) =>
        editingRowIndex === index ? (
          <Form.Item noStyle name="Stage">
            <Input />
          </Form.Item>
        ) : (
          <div>{record?.Stage}</div>
        ),
    },
    {
      title: "Funds Raised",
      width: 200, // Reduced from 300 for better balance
      render: (_item: any, record: any, index: number) =>
        editingRowIndex === index ? (
          <Form.Item noStyle name="fund_raised">
            <Input />
          </Form.Item>
        ) : (
          <div>{record?.fund_raised}</div>
        ),
    },
    {
      title: "Team Members",
      width: 200, // Reduced from 300 for better balance
      render: (_item: any, record: any, index: number) =>
        editingRowIndex === index ? (
          <Form.Item noStyle name="members">
            <Input />
          </Form.Item>
        ) : (
          <div>{record?.members}</div>
        ),
    },
    {
      title: "Notes",
      width: 200, // Reduced from 300 for better balance
      render: (_item: any, record: any, index: number) =>
        editingRowIndex === index ? (
          <Form.Item noStyle name="notable_inverstors">
            <Input />
          </Form.Item>
        ) : (
          <div>{record?.notable_inverstors}</div>
        ),
    },
    {
      title: "Action",
      width: 120, // Reduced the action column width to fit buttons nicely
      render: (_item: any, record: any, index: number) =>
        editingRowIndex === index ? (
          <div className="d-flex">
            <Button
              type="text"
              size="small"
              disabled={props?.isSubmitLoading}
              onClick={() => handleSave(record.id)}
              loading={props?.isLoading4}
              className="p-3"
            >
              <MdSave size={25} color={"#346ace"} />
            </Button>
            <Button
              type="text"
              size="small"
              disabled={props?.isSubmitLoading}
              onClick={() => setEditingRowIndex(null)}
              className="p-3"
            >
              <MdCancel size={25} color={"red"} />
            </Button>
          </div>
        ) : (
          <div className="d-flex">
            <Button
              type="text"
              size="small"
              disabled={editingRowIndex !== null || props?.isSubmitLoading}
              onClick={() => handleEdit(record, index)}
              className="p-3"
            >
              <MdModeEdit
                size={25}
                color={
                  editingRowIndex === null
                    ? "#346ace"
                    : props?.isSubmitLoading
                    ? "#bdbbbb"
                    : "#bdbbbb"
                }
              />
            </Button>
            <Popconfirm
              title="Delete this Startup"
              description="Are you sure to delete this Startup?"
              onConfirm={() => props?.deleteItem(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="text"
                size="small"
                disabled={editingRowIndex !== null || props?.isSubmitLoading}
                className="p-3"
              >
                <IoIosCloseCircleOutline
                  size={25}
                  color={
                    editingRowIndex === null
                      ? "red"
                      : props?.isSubmitLoading
                      ? "#bdbbbb"
                      : "#bdbbbb"
                  }
                />
              </Button>
            </Popconfirm>
          </div>
        ),
    },
  ];
  const getRowClassName = (record: any) => {
    if (!record?.isValid) {
      return "row-draft";
    }
    return "";
  };
  return (
    <div>
      <Form form={form}>
        <Table
          size="small"
          dataSource={props?.data}
          rowHoverable={false}
          rowClassName={getRowClassName}
          columns={columns}
          pagination={false}
          scroll={{ y: 400 }}
          loading={{
            spinning: props?.loading,
            indicator: (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
              />
            ),
          }}
        />
      </Form>
    </div>
  );
};

export default UploadTable;
