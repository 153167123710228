import { Button, message, Modal } from "antd";
import { useState } from "react"; // Add React import
import { Col, Row } from "react-bootstrap";
import { IoMdDownload } from "react-icons/io";
import { useSelector } from "react-redux";
import FilePicker from "./filepicker";
import UploadTable from "./uploadTable";
import API from "../../../config/api";
import { POST } from "../../../utils/apiCalls";

const UploadModal = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [isLoading3, setIsLoading3] = useState(false);
  const Events = useSelector((state: any) => state.Events);
  const user = useSelector((state: any) => state?.Auth?.user);
  const [fileName, setFileName] = useState<string>();
  const onSubmit = async (values: any) => {
    if (!data?.length) {
      message.warning("No data to submit.");
      return;
    }
    setLoading(true);
    try {
      const api = API.STARTUPS_BULK_ADD;

      const validationErrors: any = new Set<string>();

      if (validationErrors.size > 0) {
        message.error("Invalid data:" + [...validationErrors].join(", "));
        setLoading(false);
        return;
      }
      let objArray = values.map((value: any) => ({
        name: value.name,
        category: value.category,
        logo: value.logo || "", // Assuming logo can be empty
        description: value.description,
        founder: value.founder,
        email: value.email,
        countrycode: value.countrycode,
        phone: value.phone,
        country: value.country,
        Stage: value.Stage,
        fund_raised: value.fund_raised,
        notable_inverstors: value.notable_inverstors,
        members: value.members,
        eventId: Events?.defult?.id, // Add eventId to each object
      }));

      const res: any = await POST(api, objArray);
      if (res?.status) {
        message.success("Startup updated successfully!");
        setData([]);
        setFileName("");
        props?.close();
        props?.reload();
      } else {
        message.error("Failed to update Startup.");
        props?.close();
        props?.reload();
      }
    } catch (error: any) {
      if (error?.message) {
        message.error(`Submission failed:`);
      } else {
        message.error("An error occurred during submission.");
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setData([]);
    setFileName("");
    message.success("Data cleared successfully");
  };

  const bulkUpdate = (excelData: any) => {
    try {
      setIsLoading3(true);
      let arr = data;
      arr = arr.concat(excelData);
      setData(arr);
      setTimeout(() => {
        setIsLoading3(false);
      }, 50);
    } catch (err) {
      message.error("Oops. something went wrong");
      setIsLoading3(false);
    }
  };

  const deleteItem = (itemData: any) => {
    try {
      setIsLoading3(true);
      let arr = data;
      let filteredArr = arr?.filter((i: any) => i?.id !== itemData?.id);
      if (filteredArr.length === arr.length) {
        message.error("Oops.something went wrong");
      } else {
        setData(filteredArr);
        setTimeout(() => {
          setIsLoading3(false);
        }, 50);
      }
    } catch (err) {
      message.error("Oops.something went wrong");
      setIsLoading3(false);
    }
  };

  const updateItem = (id: any, updatingValues: any, dat: any) => {
    try {
      setIsLoading3(true);
      let updatedData = dat.map((item: any) => {
        if (item?.id === id) {
          return {
            ...item,
            ...updatingValues,
            isValid: true,
          };
        }
        return item;
      });
      setData(updatedData);
    } catch (err) {
      message.error("Oops.something went wrong");
    } finally {
      setIsLoading3(false);
    }
  };

  return (
    <div>
      <Modal
        title={props?.speakerData ? "Update Users" : "Upload Users"}
        open={props?.open}
        width={"75%"}
        onCancel={() => (loading ? undefined : props?.close())}
        footer={false}
        centered
        maskClosable={loading ? false : true}
      >
        <br />
        <div>
          <div className="d-flex justify-content-between">
            <FilePicker
              types={props?.types}
              onChange={(values: any) => bulkUpdate(values)}
              setloading={() => {}}
              fileName={fileName}
              setFileName={setFileName}
            />

            <div>
              <Button
                block
                size="large"
                ghost
                className="text-decoration-none"
                type="primary"
                href="https://vc-meets-files-for-download.s3.me-central-1.amazonaws.com/startupsSampleUpload.xlsx"
                icon={<IoMdDownload size={20} />}
              >
                Download Sample
              </Button>
            </div>
          </div>
          <br />

          {data?.length ? (
            <div
              style={{ color: "#000", fontSize: "15px", marginBottom: "20px" }}
            >
              Total Startups : {data?.length}
            </div>
          ) : null}

          <UploadTable
            data={data}
            loading={isLoading3}
            deleteItem={(value: any) => deleteItem(value)}
            updateItem={(id: any, value: any) => updateItem(id, value, data)}
            isSubmitLoading={loading}
          />

          <br />
          {data?.length ? (
            <Row>
              <Col md={6} />
              <Col md={6} className="d-flex text-end mt-5 gap-3">
                <Button
                  danger
                  size="large"
                  onClick={handleClear}
                  block
                  disabled={loading}
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => onSubmit(data)}
                  loading={loading}
                  block
                >
                  Submit
                </Button>
              </Col>
            </Row>
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default UploadModal;
