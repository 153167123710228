const API = {
  // WEB_URL: "https://v2-multi-events.dezdcdthyho7z.amplifyapp.com",
  WEB_URL: "https://vcmeets.hub71.com",
  BASE_URL: "https://api-hub71v2.semikolen.io/",
  // WEB_URL: "http://localhost:3000",
  // BASE_URL: "http://localhost:8082/",

  LOGIN: "userauth/login", //POST

  FILE_COMPRESS_UPLOAD: "img_compress/compress", //POST

  DASHBOARD_LIST: "dashboard",

  USERS_LIST: "users/list/", // POST
  USERS_LIST_SEARCH: "users/all-list", //GET
  USERS_ADD: "users/create", //POST
  USERS_EDIT: "users/update/", // PUT
  USERS_DELETE: "users/delete/", //DELETE

  COMPANIES_LIST: "companies/list/", // POST
  COMPANIES_ADD: "companies/create", //POST
  COMPANIES_EDIT: "companies/update/", // PUT
  COMPANIES_DELETE: "companies/delete/", //DELETE

  EVENTS_LIST: "events/list/", // POST
  EVENTS_ADD: "events/create", //POST
  CHECK_SLUG: "events/check-slug/", //POST
  EVENTS_EDIT: "events/update", // PUT
  EVENTS_DELETE: "events/delete/", //DELETE
  EVENTS_PICKER: "events/picker/", //GET
  EVENTS_DETAILS: "events/details/", //GET
  EVENTS_DETAILS_SLUG: "events/slug/", //GET
  EVENT_LATEST: "events/latest-event/", //GET

  INVESTORS_LIST: "investors/list", // POST
  INVESTORS_LIST_SEARCH: "investors/all-list", //GET
  INVESTORS_LIST_ALL: "investors/all", // POST
  INVESTORS_UPDATE_ATTENDANCE: "investors/vc-attetence/", // PUT
  INVESTORS_LIST_EVENT_ID: "investors/by-event-id/", //GET
  INVESTORS_ADD: "investors/create", // POST
  INVESTORS_EDIT: "investors/update", // POST
  INVESTORS_DELETE: "investors/",
  INVESTORS_DETAILS: "investors/uuid/", // GET
  INVESTORS_EMAIL_DETAILS: "investors/email/", // GET
  INVESTORS_MAILING: "investors/send-mail", // POST
  INVESTORS_SET_ROOM: "investors/set-room", // GET

  STARTUPS_LIST: "startups/list", // POST
  STARTUPS_LIST_SEARCH: "startups/all-list", // GET
  STARTUPS_LIST_ALL: "startups/all", // POST
  STARTUPS_LIST_EVENT_IDL: "startups/by-event-id/", // GET
  STARTUPS_ADD: "startups/create", // POST
  STARTUPS_BULK_ADD: "startups/bulk-create", // POST
  STARTUPS_EDIT: "startups/update", // POST
  STARTUPS_DELETE: "startups/delete/", //DELETE
  STARTUPS_DETAILS: "startups/details/", // GET
  STARTUPS_IMPORT: "startups/import-excel", // GET
  STARTUPS_FREESLOT: "startups/free/", //GET
  STARTUPS_PICKER: "startups/picker/", //GET
  STARTUPS_UUID: "startups/uuid/", // GET
  STARTUPS_UPDATE_ATTENDANCE: "startups/attetence/", // PUT

  MEETINGS_LIST: "meetings/list",
  MEETINGS_LIST_SEARCH: "meetings/all-list",
  MEETING: "meetings/",
  UPDATE_MEETING_STATUS: "meetings/meeting-status-update/",
  MEETINGS_LIST_ALL: "meetings/all",
  MEETINGS_LIST_EVENT_ID: "meetings/by-event-id/",
  MEETINGS_ADD: "meetings/create", //POST
  MEETINGS_EDIT: "meetings/edit",
  MEETINGS_DELETE: "meetings/delete/", //DELETE
  MEETINGS_DETAILS: "meetings/details/",
  MEETING_BULK_CREATE: "meetings/bulk-create", //POST
  MEETING_BYID: "meetings/id/", //GET

  MAIL_TEMPLATE_LIST: "mail_template/list/", // GET
  MAIL_TEMPLATE_ADD: "mail_template/create", //POST
  MAIL_TEMPLATE_EDIT: "mail_template/update/", // PUT
  MAIL_TEMPLATE_DELETE: "mail_template/delete/", //DELETE

  ROOMS_LIST: "rooms/list/", //GET
  ROOM_LIST_SEARCH: "rooms/all-list/", //GET
  ROOMS_LIST_EVENT_ID: "rooms/by-event-id/", //GET
  ROOMS_LIST_ALL: "rooms/all",
  ROOMS_PICKER: "rooms/picker/", //GET
  ROOMS_ADD: "rooms/create", //POST
  ROOMS_EDIT: "rooms/update/", // PUT
  ROOMS_DELETE: "rooms/delete/", //DELETE

  URL_SHARE_MAIL: "investors/url-share-mail", //POST
};
export default API;
