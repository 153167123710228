import { Container, Col, Row } from "react-bootstrap";
import "./styles.scss";
function Footer() {
  return (
    <div className="Footer">
      <Container>
        <Row>
          {/* <Col sm={2} xs={6} xxl={2}>
            <a href="" className="Footer-text1">
              PRIVACY NOTICE
            </a>
          </Col>
          <Col sm={2} xs={6} xxl={2}>
            <div style={{ textAlign: "right" }}>
              <a href="" className="Footer-text2">
                {" "}
                TERMS OF USE
              </a>
            </div>
          </Col>
          <Col sm={3}></Col> */}
          <Col sm={12} xs={12}>
            <div className="Footer-text3">© Copyright 2024</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
