const GENERATE_SLOTS = async (slot: any, period: any, exclusions: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const intervals: any = [];
      const [startHour, endHour] = slot.split("-").map(Number);
      const [excludeStart, excludeEnd] = exclusions.split("-").map(Number);
      const ranges = [
        { start: `${startHour}-00`, end: `${excludeStart}-00` },
        { start: `${excludeEnd}-00`, end: `${endHour}-00` },
      ];
      ranges.forEach((range: any) => {
        const [startHour, startMinute] = range.start.split("-").map(Number);
        const [endHour, endMinute] = range.end.split("-").map(Number);
        let currentTime = new Date();
        currentTime.setHours(startHour, startMinute, 0, 0);
        const endTime = new Date();
        endTime.setHours(endHour, endMinute, 0, 0);
        while (currentTime < endTime) {
          const nextTime = new Date(currentTime.getTime() + period * 60000);
          if (nextTime <= endTime) {
            intervals.push(
              `${formatTime(currentTime)} - ${formatTime(nextTime)}`
            );
          }
          currentTime = nextTime;
        }
      });
      resolve(intervals);
    } catch (err) {
      resolve([]);
    }
  });
};

const formatTime = (date: any) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

export { GENERATE_SLOTS };
