import { Button, Form, Image, Input, Modal, Select } from "antd";
import { Col, Row } from "react-bootstrap";
import { IoMailOutline, IoSendOutline } from "react-icons/io5";
import countries from "../../../components/countries.json";

function URLMailing(props: any) {
  const [form] = Form.useForm();

  const cascaderOptions = countries?.map((country) => ({
    flag: country?.flag,
    value: country?.dial_code,
    label: `${country.dial_code}`,
  }));
  return (
    <Modal
      title={props.title}
      open={props.visible}
      onCancel={() => props.onClose()}
      footer={false}
      centered
      width={500}
    >
      <Form 
      form={form} 
      onFinish={(val) => props.onFinish(val)}
      initialValues={{
        countryCode: "+971",
      }}
        >
        <div className="label">To</div>
        <Form.Item
          name="mail"
          initialValue={props?.data?.email}
          rules={[
            {
              required: true,
              message: props.mail ? "Missing Email" : "Missing number",
            },
            props.mail
              ? { type: "email", message: "Email not valid" }
              : { pattern: /^[0-9]+$/, message: "Number not valid" },
          ]}
        >
          <Input
            size="large"
            addonBefore={
              props.mail ? (
                <IoMailOutline
                  style={{ marginRight: 10 }}
                  size={20}
                  color="blue"
                />
              ) : (
                <Form.Item
                  noStyle
                  name="countryCode"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Select
                    placeholder=""
                    style={{ width: 100 }}
                    showSearch
                    dropdownStyle={{ width: 100 }}
                    allowClear
                  >
                    {cascaderOptions?.map((item: any, index: number) => {
                      return (
                        <Select.Option key={index} value={item?.value}>
                          <div className="d-flex align-items-center gap-2">
                            <Image
                              width={31}
                              alt={item?.code}
                              src={`https://flagsapi.com/${item?.flag}/flat/24.png`}
                              preview={false}
                            />
                            {item?.label}
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )
            }
          />
        </Form.Item>
        <Row>
          <Col sm={6}></Col>
          <Col sm={3}>
            <Button size="large" block danger onClick={() => props.onClose()}>
              Close
            </Button>
          </Col>
          <Col sm={3}>
            <Button
              size="large"
              type="primary"
              block
              htmlType="submit"
              icon={<IoSendOutline />}
              loading={props.loading}
            >
              Send
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default URLMailing;
