import { Pagination, Popconfirm, Switch, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import { CiEdit } from "react-icons/ci";
import { IoIosCloseCircleOutline } from "react-icons/io";
function DataTable(props: any) {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Company",
      dataIndex: "companyDetails ",
      key: "companyDetails",
      render: (item: any, item1: any) => (
        <div>{item1?.companyDetails?.name}</div>
      ),
    },
    {
      title: "Type",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (item: any) => (
        <div style={{ fontSize: 12 }}>
          {moment(item).format("MMM D, YYYY h:mm:ss A")}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 50,
      render: (item: any) => (
        <div>
          <Tag color={item ? "green" : "red"}>
            {item ? "Active" : "Inactive"}
          </Tag>
        </div>
      ),
    },

    {
      title: "",
      dataIndex: "status",
      render: (item: any, record: any) => (
        <div>
          <Switch onClick={() => props.toggleStatus(record)} checked={item} />
        </div>
      ),
    },

    {
      title: "Actions",
      width: 50,
      render: (item: any, record: any) => (
        <div className="table-item">
          <div>
            <Tooltip
              placement="bottomLeft"
              title={<div style={{ fontSize: 10 }}>Edit User</div>}
            >
              <CiEdit onClick={() => props.onChange(record)} size={25} />
            </Tooltip>
          </div>
          {record.email === "admin@vcmeets.com" ? (
            <div></div>
          ) : (
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this?"
              okText="Yes"
              cancelText="No"
              placement="bottomLeft"
              onConfirm={() => {
                props?.delete(item?.id);
              }}
            >
              <div>
                <IoIosCloseCircleOutline size={25} color="red" />
              </div>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={props?.data}
        columns={columns}
        pagination={false}
        loading={props?.loading}
        scroll={{ x: 1000 }}
      />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.meta?.total_count}
          showSizeChanger
          showTotal={(total: any) =>
            `Total ${props?.meta?.total_count} Companys`
          }
          onChange={(page, pageSize) => props.onPageChange(page, pageSize)}
        />
      </div>
    </>
  );
}

export default DataTable;
