import { Button, DatePicker, Form, Input, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState, useCallback } from "react";
import { SiMicrosoftexcel } from "react-icons/si";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as XLSX from "xlsx";
import Loading from "../../components/loading";
import API from "../../config/api";
import { DELETE, GET, PUT } from "../../utils/apiCalls";
import PageHeader from "../routes/pageHeader";
import DataTable from "./components/dataTable";
import Forms from "./components/form";
import UploadModal from "./components/uploadModal";
import "./styles.scss";

function StartupScreen() {
  const Events = useSelector((state: any) => state.Events);
  const user = useSelector((state: any) => state.User.user);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isGeneratingExcel, setIsGeneratingExcel] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [formModal, setFormModal] = useState(false);

 
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  const fetchData = useCallback(async () => {
    setIsLoadingData(true);
    try {
      const params = {
        ...(searchParams.get("query") && { query: searchParams.get("query") }),
        ...(searchParams.get("startDate") && {
          startDate: searchParams.get("startDate"),
        }),
        ...(searchParams.get("page") && { page: searchParams.get("page") }),
        ...(searchParams.get("limit") && { take: searchParams.get("limit") }),
        eventId: Events?.defult?.id,
      };
      const res: any = await GET(API.STARTUPS_LIST_SEARCH, params);
      if (res.status) {
        setData(res.data);
        setMeta(res.meta);
      } else {
        message.error("Failed to fetch startup data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("An error occurred while fetching data.");
    } finally {
      setIsLoadingData(false);
      setLoading(false);
    }
  }, [Events?.defult?.id, searchParams]);

  const handleEditItem = (item: any) => {
    setSelectedItem(item);
    setOpenForm(true);
  };
  const handleDeleteItem = async (id: any) => {
    try {
      const response: any = await DELETE(`${API.STARTUPS_DELETE}${id}`);
      if (response?.status) {
        fetchData();
        message.success("Successfully deleted.");
      } else {
        message.error("An error occurred while deleting.");
      }
    } catch {
      message.error("An error occurred while deleting.");
    }
  };
  const updateAttendance = async (data: {
    id: string;
    has_attended: boolean;
    startup_id: string;
  }) => {
    try {
      const response: any = await PUT(
        `${API.STARTUPS_UPDATE_ATTENDANCE}${data.id}`,
        { has_attended: data?.has_attended, startup_id: data?.startup_id }
      );
      if (response?.status) {
        fetchData();
        message.success("Attendance updated successfully.");
      } else {
        message.error("Failed to update.");
      }
    } catch (err) {
      message.error("An error occurred while deleting.");
    }
  };
  const handleGenerateExcel = async () => {
    setIsGeneratingExcel(true);
    try {
      const url = `${API.STARTUPS_LIST_EVENT_IDL}${Events?.defult?.id}`;
      const response: any = await GET(url);

      if (response?.status) {
        const worksheet = XLSX.utils.json_to_sheet(response?.data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const dataBlob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(dataBlob);
        link.download = `Startup-Report-${new Date().toLocaleString()}.xlsx`;
        link.click();
      } else {
        message.error("Couldn't generate the report.");
      }
    } catch (error) {
      console.error("Error generating report:", error);
      message.error("An error occurred while generating the report.");
    } finally {
      setIsGeneratingExcel(false);
    }
  };

  const handleSearchParamsChange = (data: any) => {
    const queryParams = new URLSearchParams({
      ...(data?.query && { query: data.query }),
      ...(data?.startDate && { startDate: data.startDate }),
      page: data?.page || searchParams.get("page") || "1",
      limit: data?.pageSize || searchParams.get("limit") || "10",
    });

    navigate(`/company/startups?${queryParams.toString()}`);
  };

  return (
    <div>
      <PageHeader title="Startups">
        <Form
          form={form}
          onValuesChange={(_, allData) =>
            handleSearchParamsChange({ ...allData, page: 1 })
          }
          initialValues={{
            query: searchParams.get("query"),
            startDate:
              searchParams.get("startDate") &&
              dayjs(searchParams.get("startDate")),
          }}
        >
          <div className="AdminHeader-extra">
            <Form.Item name="query">
              <Input placeholder="Search" allowClear size="middle" />
            </Form.Item>
            <Form.Item name="startDate">
              <DatePicker placeholder="Date" size="middle" />
            </Form.Item>
            {Array.isArray(user.access[Events?.defult?.id]) &&
               user.access[Events?.defult?.id].includes(8) ? null : 
               <>
            <Button
              type="primary"
              onClick={() => setOpenForm(true)}
              size="middle"
              >
              ADD STARTUPS +
            </Button>
            <Button onClick={() => setFormModal(true)}>
              UPLOAD <SiMicrosoftexcel size={20} color="green" />
            </Button>
            <Button
              loading={isGeneratingExcel}
              onClick={handleGenerateExcel}
              size="middle"
              style={{ background: "#fff", cursor: "pointer" }}
              >
              EXPORT <SiMicrosoftexcel size={20} color="green" />
            </Button>
              </>
}
          </div>
        </Form>
      </PageHeader>
      {loading ? (
        <Loading />
      ) : (
        <DataTable
          data={data}
          meta={meta}
          loading={isLoadingData}
          onPageChange={(page: number, pageSize: number) =>
            handleSearchParamsChange({ page, pageSize })
          }
          onChange={handleEditItem}
          delete={handleDeleteItem}
          updateAttendance={(data: any) => updateAttendance(data)}
        />
      )}
      {openForm && (
        <Forms
          visible={openForm}
          data={selectedItem}
          onClose={() => {
            setSelectedItem({});
            setOpenForm(!openForm);
          }}
          onChange={fetchData}
        />
      )}
      {formModal && (
        <UploadModal
          open={formModal}
          close={() => setFormModal(false)}
          reload={fetchData}
        />
      )}
    </div>
  );
}

export default StartupScreen;
