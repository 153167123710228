import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
function PageHeader(props: any) {
  const navigate = useNavigate();
  return (
    <div className="CompanyHeader-pageHeader">
      <div
        className="CompanyHeader-pageHeader-Box"
        onClick={() => navigate(-1)}
      >
        <BsArrowLeft size={30} />
      </div>
      <div>
        <div className="CompanyHeader-txt1">{props?.title}</div>
        <div className="CompanyHeader-txt2">
          Company {">"} <span style={{ color: "#0026ab" }}>{props?.title}</span>
        </div>
      </div>
      <div style={{ flex: 1 }} />
      {props?.children ? <div>{props?.children}</div> : null}
    </div>
  );
}

export default PageHeader;
