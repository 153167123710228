import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal } from "antd";

import API from "../../../config/api";
import { GET, POST } from "../../../utils/apiCalls";
import Loading from "../../../components/loading";
import NoImage from "../../../assets/images/noimage.jpg"

function AssignModal(props: any) {
  const Events = useSelector((state: any) => state.Events);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState<any>(null);
  const [startups, setStartups] = useState([]);

  useEffect(() => {
    loadStartups();
  }, []);
  const loadStartups = async () => {
    try {
      let url = API.STARTUPS_FREESLOT + `${Events?.defult?.id}/${props?.item}`;
      var response: any = await GET(url, null);
      if (response.status) {
        const filteredStartups = response?.data?.filter(
          (startup: any) =>
            !props.slots?.some(
              (meeting: any) => meeting?.startup_id === startup?.id
            )
        );
        setStartups(filteredStartups);
      } else {
        setStartups([]);
      }
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      setLoading(false);
    }
  };

  const submit = async (startup: any, index: number) => {
    try {
      setLoading2(index);
      let obj = {
        slot: props?.item,
        investor_name: props?.data?.fullname,
        startup_name: startup?.name,
        investor_id: props?.data?.id,
        startup_id: startup?.id,
        eventId: Events?.defult?.id,
      };
      let response: any = await POST(API.MEETINGS_ADD, obj);
      if (response?.status) {
        props?.onChange();
        props.onClose();
      }
      setLoading2(null);
    } catch (err) {
      console.log("err", err);
      setLoading2(null);
    }
  };

  return (
    <Modal
      title={`Slot Available Startups on ${props?.item}`}
      open={props.visible}
      onCancel={() => props.onClose()}
      footer={false}
      centered
      width={600}
      height={"90vh"}
    >
      <div style={{ color: "grey" }}>{startups?.length} Startups Available</div>
      <br />
      <div className="">
        {loading ? (
          <Loading />
        ) : startups?.length ? (
          startups?.map((item: any, index: number) => {
            return (
              <div key={item?.id} className="investorScreen-startupItem">
                <div>
                  <img src={item.logo || NoImage} className="investorScreen-startuplogo" />
                </div>
                <div style={{ flex: 1 }}>
                  <div className="investorScreen-startuptext1">
                    {item?.name}
                  </div>
                  <div className="investorScreen-startuptext2">
                    {item?.category} , {item?.country}
                  </div>
                </div>
                <div>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => submit(item, index)}
                    loading={loading2 === index ? true : false}
                  >
                    Assign Slot
                  </Button>
                </div>
              </div>
            );
          })
        ) : (
          <div>Startups not Available in this time slot</div>
        )}
      </div>
    </Modal>
  );
}

export default AssignModal;
