import { Button, message } from "antd";
import dayjs from "dayjs";
import Dropzone from "react-dropzone";
import { SiMicrosoftexcel } from "react-icons/si";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";

const FilePicker = (props: any) => {
  const Events = useSelector((state: any) => state.Events);

  const handleDrop = async (acceptedFiles: any) => {
    try {
      const allowedTypes = [
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      var myFile = acceptedFiles[0];
      props?.setloading(true);
      if (!allowedTypes.includes(myFile.type)) {
        message.error("Only CSV or excel files are allowed.");
        return;
      } else {
        const readedData: any = await readExcelFile(myFile);
        var formated = createData(readedData);

        props?.onChange(formated);
      }
      props?.setFileName(myFile?.name);
      props?.setloading(false);
    } catch (err) {
      props?.setloading(false);
      console.log("err", err);
    }
  };

  const readExcelFile = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        if (json) {
          resolve(json);
        } else {
          message.error(`Missing fields: Please refer to the sample excel.`);
          props?.setloading(false);
        }
      };
      reader.onerror = () => {
        message.error("Error reading file");
        reject("Error processing file");
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const createData = (data: any) => {
    try {
      const formattedData: any = [];
      let hasError = false;
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        let obj = {
          action: "add",
          id: dayjs(new Date()).unix() + i,
          name: item["Name"],
          category: item["Sector"],
          founder: item["Founder"],
          country: item["Headquarters"],
          description: item["Description"],
          Stage: item["Investment Stage"],
          fund_raised: item["Fund Raised"],
          members: item["Team Members"],
          countrycode: item["Country Code"],
          phone: item["Phone Number"],
          email: item["Email"],
          notable_inverstors: item["Notable Investors"],
          eventId: Events?.defult?.id,
          isValid: true,
        };
        const isValid = obj?.name && obj?.founder;
        const isImportValid = isValid ;
        obj.isValid = isImportValid;
        formattedData.push(obj);
        
      }
      if (hasError) {
        message.error("Please ensure all required fields are filled");
      }
      if (hasError) {
        message.error("Ensure all required fields are filled.");
      }
      return formattedData;
    } catch (err) {
      return [];
    }
  };

  return (
    <Dropzone
      onDrop={handleDrop}
      accept={{
        "text/csv": [".csv"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
        "application/vnd.ms-excel": [".xls"],
      }}
    >
      {({ getRootProps, getInputProps }: any) => (
        <section>
          <div
            {...getRootProps({
              className: "sponsor-FilePicker",
            })}
          >
            <input {...getInputProps()} />
            <div>
              <div className="sponsor-FilePicker2">
                <Button size="large" type="primary" loading={props?.loading}>
                  Choose file
                </Button>

                {props?.file ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <SiMicrosoftexcel />
                    <span>{props?.file}</span>
                  </div>
                ) : (
                  <div>
                    {props?.fileName ? props?.fileName : "Upload CSV/Excel"}
                  </div>
                )}
              </div>
              <div className="sponsor-FilePicker-txt2 ms-2">
                Allowed file types: .csv , .xlsx
              </div>
            </div>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default FilePicker;
