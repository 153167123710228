import { Line } from "react-chartjs-2";
import { Chart, ScriptableContext, registerables } from "chart.js";
import moment from "moment";
Chart.register(...registerables);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    title: { display: false },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
  bezierCurve: false,
};

export default function GraphChart(props: any) {
  const labels = props?.data?.map((entry: any) =>
    moment(entry.date).format("l")
  );
  const datas = props?.data?.map((entry: any) => entry.count);
  const data = {
    labels,
    datasets: [
      {
        label: "VCs Registration",
        data: datas,
        borderColor: "rgba(18,78,245,0.4)",
        fill: true,
        pointHoverRadius: 2,
        pointRadius: 5,
        lineTension: 0.1,
        backgroundColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 250);
          gradient.addColorStop(0, "rgba(18,78,245,0.5)");
          gradient.addColorStop(1, "rgba(18,78,245,0)");
          return gradient;
        },
      },
    ],
  };
  return (
    <div className="DashboardScreen-box1">
      <div style={{ overflow: "hidden", height: 355 }}>
        <div style={{ margin: 50 }} />
        <Line options={options} data={data} />
      </div>
    </div>
  );
}
