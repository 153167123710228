import { useState } from "react";
import { Button, Form, Input, Modal, Select, Tooltip, message } from "antd";
import { Row, Col } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import Slots from "../../../config/timeSlots.json";

import API from "../../../config/api";
import { COMPRESS_IMAGE, POST } from "../../../utils/apiCalls";
import ImagePicker from "../../../components/imagePicker";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";

function EditVcModal(props: any) {
  const [loading, setLoading] = useState(false);
  const [image_url, setImageUrl] = useState<any>(props?.data?.logo);
  const [file, setFile] = useState<any>();
  const Events = useSelector((state: any) => state.Events);
  const showSlot = (value: any) => {
    let slot = Slots?.filter((i: any) => i.value === value);
    return slot[0];
  };
  const submit = async (val: any) => {
    try {
      setLoading(true);
      let images: any = await COMPRESS_IMAGE(file);
      let obj = {
        id: props?.data?.id,
        fullname: val?.fullname,
        email: val?.email,
        timeSlots: val?.timeSlots,
        jobTitle: val?.jobTitle,
        company: val?.company,
        Attendees: val?.Attendees,
        slots: showSlot(val?.timeSlots).slots,
        meeting_room: props?.roomData.find(
          (item: any) => item.id === val?.meeting_room
        )?.name,
        room_id: val?.meeting_room,
        logo: file?.name ? images?.url : image_url,
        eventId: Events?.defult?.id,
      };
      let url = props?.data?.id ? API.INVESTORS_EDIT : API.INVESTORS_ADD;
      let response: any = await POST(url, obj);
      if (response?.status) {
        message.success(
          `${
            props?.data?.id ? ` Updated  successfully` : "Added VC successfully"
          }`
        );
        props?.onFinish();
        props.onClose();
      } else {
        message.error(response.message);
      }
    } catch (err) {
      console.log("err", err);
      message.error("Oops.something gone wrong.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={`${
        props?.data?.id ? "Edit VC - " + props?.data?.fullname : "Add VC"
      }`}
      open={props.visible}
      onCancel={() => props.onClose()}
      footer={false}
      centered
      width={800}
      height={"90vh"}
      maskClosable={false}
      closable={!loading}
    >
      <hr />
      <Form
        onFinish={submit}
        scrollToFirstError
        initialValues={{
          fullname: props?.data?.fullname,
          email: props?.data?.email,
          timeSlots: props?.data?.timeSlots,
          jobTitle: props?.data?.jobTitle,
          company: props?.data?.company,
          Attendees: props?.data?.Attendees,
          meeting_room: props?.data?.room_id,
        }}
      >
        <Row>
          <Col sm={6}>
            <div className="label">Contact Person *</div>
            <Form.Item
              name={"fullname"}
              rules={[
                { required: true, message: "Please enter contact person" },
                {
                  pattern: /^[a-zA-Z ]+$/,
                  message: "Enter a valid name",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Contact Person"
                type="text"
                onKeyPress={(event) => {
                  if (!/^[a-zA-Z ]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <div className="label">Email *</div>
            <Form.Item
              name={"email"}
              rules={[
                { required: true, message: "Please enter an email" },
                {
                  type: "email",
                  message: "Enter a valid email",
                },
              ]}
            >
              <Input size="large" placeholder="Email" type="email" />
            </Form.Item>
            <div className="label">Time Slot *</div>
            <Form.Item
              name={"timeSlots"}
              rules={[{ required: true, message: "Please choose a time slot" }]}
            >
              <Select size="large" placeholder="Time Slot">
                {Slots?.map((item: any) => {
                  return (
                    <Select.Option key={item?.id} value={item.value}>
                      {item?.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={6}>
            <div className="label">Job Title *</div>
            <Form.Item
              name={"jobTitle"}
              rules={[{ required: true, message: "Please enter job title" }]}
            >
              <Input size="large" placeholder="Job Title" type="text" />
            </Form.Item>
            <div className="label">Company Name *</div>
            <Form.Item
              name={"company"}
              rules={[{ required: true, message: "Company name is required" }]}
            >
              <Input size="large" placeholder="Company Name" type="text" />
            </Form.Item>
            <div className="label">Meeting Room </div>
            <Form.Item name={"meeting_room"}>
              <Select className="w-100" placeholder="Meeting Room" size="large">
                {props?.roomData?.map((item: any) => {
                  return (
                    <Select.Option key={item?.id} value={item?.id}>
                      {item?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <div className="label d-flex justify-content-between">
              Company Logo
              {image_url ? (
                <Tooltip placement="top" title={"Remove logo"}>
                  <IoIosCloseCircleOutline
                    color="red"
                    size={20}
                    onClick={() => {
                      setImageUrl(null);
                      setFile(null);
                    }}
                  />
                </Tooltip>
              ) : null}
            </div>
            <Form.Item name={"logo"}>
              <ImagePicker
                onChange={(value: any) => {
                  setImageUrl(value?.url);
                  setFile(value?.file);
                }}
                fileURL={props?.id ? props?.logo : image_url}
                size={3145728}
                format={"image/"}
              />
              <div className="VcFormScreen-txt5">
                Max file size is 3MB. File Formats (JPEG, PNG, GIF) are allowed.
              </div>
            </Form.Item>
          </Col>
        </Row>
        <div className="label">Attendees</div>

        <hr />
        <Form.List name="Attendees">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index: any) => (
                <div key={index} className="investorScreen-formItem">
                  <div className="investorScreen-formRow">
                    <div>Attendees - {index + 1}</div>
                    <div>
                      <Button
                        danger
                        size="small"
                        onClick={() => remove(name)}
                        disabled={loading}
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                  <Row>
                    <Col sm={6}>
                      <div className="label mt-2">Full Name *</div>
                      <Form.Item
                        {...restField}
                        name={[name, "fullName"]}
                        rules={[
                          { required: true, message: "Please enter full name" },
                          {
                            pattern: /^[a-zA-Z ]+$/,
                            message: "Enter a valid full name",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Full Name"
                          type="text"
                          onKeyPress={(event) => {
                            if (!/^[a-zA-Z ]+$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={6}>
                      <div className="label mt-2">Job Title *</div>
                      <Form.Item
                        {...restField}
                        name={[name, "jobTitle"]}
                        rules={[
                          { required: true, message: "Please enter job title" },
                        ]}
                      >
                        <Input placeholder="Job Title" type="text" />
                      </Form.Item>
                    </Col>
                    <Col sm={6}>
                      <div className="label mt-2">Email *</div>
                      <Form.Item
                        {...restField}
                        name={[name, "email"]}
                        rules={[
                          { required: true, message: "Please enter an email" },

                          {
                            type: "email",
                            message: "Enter a valid email",
                          },
                        ]}
                      >
                        <Input placeholder="Email" type="email" />
                      </Form.Item>
                    </Col>
                    <Col sm={6}>
                      <div className="label mt-2">Company Name*</div>
                      <Form.Item
                        {...restField}
                        name={[name, "company"]}
                        rules={[
                          {
                            required: true,
                            message: "Please enter company name",
                          },
                        ]}
                      >
                        <Input placeholder="Company Name" type="text" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}

              <Button
                type="primary"
                size="small"
                onClick={() => add()}
                disabled={loading}
              >
                Add Attendees +{" "}
              </Button>
            </>
          )}
        </Form.List>
        <br />
        <Row>
          <Col sm={6}></Col>
          <Col sm={3}>
            <Button
              block
              danger
              size="large"
              onClick={() => props.onClose()}
              disabled={loading}
            >
              Close
            </Button>
          </Col>
          <Col sm={3}>
            <Button
              block
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              {props?.data?.id ? "Update" : "Submit"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default EditVcModal;
