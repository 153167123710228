import moment from "moment";
import API from "../../../config/api";
import dayjs from "dayjs";
const EmailContent = async (data: any) => {
  let content = data?.content;
  let Room = data?.room||"";
  let EventDate = data?.event_date
    ? dayjs(data?.event_date).format("DD-MM-YYYY")
    : "";
  let Location = data?.location;
  let Link = `${API.WEB_URL}/vc-details/${data?.user?.uuid}?event=${data?.eventId}`;
  let Meeting_slots: any = "";
  let Rows = data?.meetings?.forEach(function (item: any) {
    if (item?.startups?.id) {
      Meeting_slots += `<li><strong>${item?.slot} </strong>- ${item?.startup_name} </li>`;
    }
  });
  content = content
    .replace(/\${<p>Dear Person_name,<\/p>}/g, "")
    .replace(/\${Room}/g, Room)
    .replace(/\${Link}/g, Link)
    .replace(/\${Meeting_slots}/g, Meeting_slots)
    .replace(/\${EventDate}/g, EventDate)
    .replace(/\${Location}/g, Location);
  return content;
};

export default EmailContent;
