import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Diamond from "../../assets/images/Arrows.svg";
import Logo from "../../assets/images/logo.svg";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./styles.scss";
import ImagePicker from "../../components/imagePicker";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import { Button, Form, Input, Result, Select, Steps, message } from "antd";
import { MdAdd } from "react-icons/md";
import { IoMdRemove } from "react-icons/io";
import Success from "../vcFormScreen/success";
import { COMPRESS_IMAGE, GET, POST } from "../../utils/apiCalls";
import API from "../../config/api";
import Slots from "../../config/timeSlots.json";
import { useParams } from "react-router-dom";

function EventFormScreen() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [eventData, setEventData] = useState<any>();
  const [startDate, setStartDate] = useState<any>(null);
  const [invalidLink, setInvalidLink] = useState<any>(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [image_url, setImageUrl] = useState<any>();
  const [file, setFile] = useState<any>();
  const { eventId, uuid } = useParams();

  const showSlot = (value: any) => {
    let slot = Slots?.filter((i: any) => i.value === value);
    return slot[0];
  };
  useEffect(() => {
    getEvent();
  }, []);

  const getEvent = async () => {
    try {
      setLoading2(true);
      let api = API.EVENTS_DETAILS_SLUG + eventId + "/" + uuid;
      const response: any = await GET(api, null);
      if (response?.status) {
        setEventData(response?.data);
        setStartDate(dayjs(response?.data?.start_date).format("YYYY-MM-DD"));
        setInvalidLink(false);
      } else {
        setEventData([]);
        setInvalidLink(true);
      }
    } catch (err) {
      console.log("err", err);
      setInvalidLink(true);
    } finally {
      setLoading2(false);
    }
  };
  const onFinish = async (val: any) => {
    setLoading(true);
    try {
      let images: any = await COMPRESS_IMAGE(file);
      let obj = {
        fullname: val?.fullname,
        email: val?.email,
        timeSlots: val?.timeSlots,
        jobTitle: val?.jobTitle,
        company: val?.company,
        Attendees: val?.Attendees,
        slots: showSlot(val?.timeSlots).slots,
        logo: file?.name ? images?.url : null,
        eventId: eventData?.id,
      };
      let url = API.INVESTORS_ADD;
      let res: any = await POST(url, obj);
      if (res.status) {
        setData(res?.data);
        setSuccess(true);
        window.scrollTo(0, 0);
        form.resetFields();
        setImageUrl(null);
        setFile(null);
      } else {
        message.error(res.message);
      }
    } catch (err) {
      console.log("err", err);
      message.error("Something went wrong...!");
    } finally {
      setLoading(false);
    }
  };
  const progressDot = (dot: any, { status, index }: any) => {
    return (
      <div
        style={{
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          backgroundColor: blendColor(
            typeof eventData?.bg_color === "string"
              ? eventData.bg_color // use directly if it's already a hex string
              : eventData?.bg_color?.toHexString
              ? eventData.bg_color.toHexString()
              : "#FFFFFF",
            0.3
          ),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    );
  };
  const blendColor = (baseColor: string, factor: number): string => {
    const hexToRgb = (hex: string): [number, number, number] => {
      let r = 0,
        g = 0,
        b = 0;

      // 3 digits
      if (hex?.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
      }
      // 6 digits
      else if (hex?.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
      }

      return [r, g, b];
    };

    const rgbToHex = (r: number, g: number, b: number): string => {
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    };

    const [r, g, b] = hexToRgb(baseColor);

    // Blend towards white based on the factor (0 = original color, 1 = white)
    const blendedR = Math.round(r + (255 - r) * factor);
    const blendedG = Math.round(g + (255 - g) * factor);
    const blendedB = Math.round(b + (255 - b) * factor);

    return rgbToHex(blendedR, blendedG, blendedB);
  };
  const validateAndCallApi = async (email: string) => {
    try {
      setLoading3(true);
      const response: any = await GET(
        `${API.INVESTORS_EMAIL_DETAILS}${email}`,
        {}
      );
      if (response?.statusCode === 200 && response?.status === true) {
        const data = response.data;
        form.setFieldsValue({
          fullname: data.fullname,
          timeSlots: data.timeSlots,
          jobTitle: data.jobTitle,
          company: data.company,
        });
      } else {
        form.setFieldsValue({
          fullname: null,
          timeSlots: null,
          jobTitle: null,
          company: null,
        });
      }
    } catch (error) {
    } finally {
      setLoading3(false);
    }
  };

  const onValuesChange = (changedValues: any) => {
    let email = changedValues.email;
    if (email) {
      email = email.trim();
      form.setFieldsValue({ email });
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailPattern.test(email)) {
        validateAndCallApi(email);
      }
    }
  };
  if (invalidLink) {
    return (
      <Result
        status="404"
        title="Invalid-Link"
        subTitle="Oops! The page you're looking for is not available."
      />
    );
  }

  const progressBarStyle = {
    height: "1px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  };

  const progressStyle = {
    backgroundColor: "white",
    transition: "width 300ms ease-out",
  };
  return (
    <div>
      {loading2 ? (
        <Container
          fluid
          className="min-vh-100 d-flex align-items-center justify-content-center text-white p-0 m-0"
          style={{ background: "#0026ab" }}
        >
          <Row className="text-center">
            <Col xs={12} className="mb-3">
              <h1 className="display-4 fw-light">99%</h1>
            </Col>
            <Col xs={12} className="mb-4">
              <p className="text-light letter-spacing-2 opacity-75">
                Loading event...
              </p>
            </Col>
            <Col xs={12} style={{ maxWidth: "320px" }} className="mx-auto">
              <ProgressBar now={100} style={progressBarStyle} variant="custom">
                <ProgressBar now={100} style={progressStyle} />
              </ProgressBar>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          {<Header logo={eventData?.image} />}
          <div className="eventFormScreen-Box">
            <Container fluid={true}>
              <Row style={{ height: "100%" }}>
                <Col
                  sm={5}
                  xs={12}
                  style={{ padding: 0 }}
                  className="eventFormScreen-sticky"
                >
                  <div
                    className="eventFormScreen-Box2"
                    style={{
                      backgroundColor:
                        typeof eventData?.bg_color === "string"
                          ? eventData.bg_color
                          : eventData?.bg_color?.toHexString
                          ? eventData.bg_color.toHexString()
                          : "#0026ab",
                    }}
                  >
                    <Row style={{ padding: 0, margin: 0 }}>
                      <Col sm={2} xs={12}></Col>
                      <Col sm={10} md={10} xs={12} className="p-0">
                        <br />
                        <div className="eventFormScreen-Box3">
                          <div className="eventFormScreen-box5">
                            {/* <h1 className="eventFormScreen-txt1">
                              {eventData?.title}
                            </h1> */}

                            <h6 className="eventFormScreen-txt2">
                              {eventData?.sub_title}
                            </h6>
                            <br />
                            <h6 className="eventFormScreen-txt2">
                              {eventData?.name}:
                            </h6>
                            <div className="eventFormScreen-line" />

                            <br />
                            <h5
                              className="eventFormScreen-txt4"
                              style={{ fontSize: 30 }}
                            >
                              {eventData?.notes}
                            </h5>
                            <h5
                              className="eventFormScreen-txt4"
                              style={{ fontSize: 20 }}
                            >
                              {eventData?.location}
                            </h5>
                            <br />
                            <Steps
                              direction="vertical"
                              current={eventData?.agendas?.length || 1}
                              progressDot={progressDot}
                              items={
                                eventData?.agendas &&
                                eventData?.agendas?.map((item: any) => ({
                                  title: (
                                    <div className="eventFormScreen-txt6">
                                      {item?.startTime || item?.endTime ? (
                                        <strong className="eventFormScreen-text7">
                                          {item?.startTime && item?.endTime
                                            ? item?.startTime +
                                              " to " +
                                              item?.endTime
                                            : item?.startTime || item?.endTime}
                                          &nbsp;/&nbsp;
                                        </strong>
                                      ) : null}
                                      {item?.title ? item?.title : null}
                                      {item?.description
                                        ? ` (${item?.description})`
                                        : null}
                                    </div>
                                  ),
                                }))
                              }
                            />

                            <br />
                            <div className="eventFormScreen-line" />
                            <br />
                            <h6 className="eventFormScreen-txt2">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: eventData?.description,
                                }}
                              />
                            </h6>
                          </div>

                          <img
                            src={Diamond}
                            className="eventFormScreen-img1"
                            alt="arrow"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col sm={7} xs={12}>
                  <div className="eventFormScreen-Box4">
                    <Row>
                      <Col sm={1} xs={12}></Col>
                      <Col sm={10} xs={12}>
                        <br /> <br />
                        {success ? (
                          <Success
                            data={data}
                            goBack={() => setSuccess(false)}
                          />
                        ) : (
                          <Form
                            form={form}
                            scrollToFirstError
                            onFinish={onFinish}
                            onValuesChange={onValuesChange}
                          >
                            <Row>
                              <Col sm={6}>
                                <div className="label">Email *</div>
                                <Form.Item
                                  name={"email"}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter email",
                                    },
                                    {
                                      type: "email",
                                      message: "Enter a valid email",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Email"
                                    size="large"
                                    // suffix={
                                    //   loading3 ? (
                                    //     <Spin size="small" />
                                    //   ) : undefined
                                    // }
                                  />
                                </Form.Item>
                                <div className="label">Contact Person *</div>
                                <Form.Item
                                  name={"fullname"}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter contact person",
                                    },
                                    {
                                      pattern: /^[a-zA-Z ]+$/,
                                      message: "Enter valid contact person",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Contact Person"
                                    onKeyPress={(event) => {
                                      if (!/^[a-zA-Z ]+$/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Item>

                                <div className="label">Time Slot *</div>
                                <Form.Item
                                  name={"timeSlots"}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please choose time slot",
                                    },
                                  ]}
                                >
                                  <Select size="large" placeholder="Time Slot">
                                    {Slots?.map((item: any) => {
                                      return (
                                        <Select.Option
                                          key={item?.id}
                                          value={item.value}
                                        >
                                          {item?.label}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col sm={6}>
                                <div className="label">Job Title *</div>
                                <Form.Item
                                  name={"jobTitle"}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter job title",
                                    },
                                  ]}
                                >
                                  <Input size="large" placeholder="Job Title" />
                                </Form.Item>
                                <div className="label">Company Name *</div>
                                <Form.Item
                                  name={"company"}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter company name",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Company Name"
                                  />
                                </Form.Item>
                                <div className="label">Company Logo</div>
                                <ImagePicker
                                  onChange={(value: any) => {
                                    setImageUrl(value?.url);
                                    setFile(value?.file);
                                  }}
                                  fileURL={image_url}
                                  size={3145728}
                                  format={"image/"}
                                />
                                <div className="eventFormScreen-txt5">
                                  Max file size is 3MB. Only image files (JPEG,
                                  PNG, GIF) are allowed.
                                </div>
                              </Col>
                            </Row>
                            <br />
                            <Form.List name="Attendees">
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(
                                    (
                                      { key, name, ...restField },
                                      index: any
                                    ) => (
                                      <div
                                        className="eventFormScreen-FormBox2"
                                        key={index}
                                      >
                                        <div className="eventFormScreen-FormBox3">
                                          <div className="eventFormScreen-txt3">
                                            Attendees {index + 1}
                                          </div>
                                          <div
                                            onClick={() => remove(name)}
                                            style={{
                                              cursor: loading
                                                ? "not-allowed"
                                                : "pointer",
                                            }}
                                          >
                                            <IoMdRemove size={30} color="red" />
                                          </div>
                                        </div>
                                        <Row>
                                          <Col sm={6}>
                                            <div className="label">
                                              Full Name *
                                            </div>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "fullName"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please enter full name ",
                                                },
                                                {
                                                  pattern: /^[a-zA-Z ]+$/,
                                                  message:
                                                    "Enter valid full name",
                                                },
                                              ]}
                                            >
                                              <Input
                                                size="large"
                                                placeholder="Full Name"
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/^[a-zA-Z ]+$/.test(
                                                      event.key
                                                    )
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col sm={6}>
                                            <div className="label">
                                              Job Title *
                                            </div>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "jobTitle"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please enter job title",
                                                },
                                              ]}
                                            >
                                              <Input
                                                size="large"
                                                placeholder="Job Title"
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col sm={6}>
                                            <div className="label">Email *</div>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "email"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Please enter email",
                                                },
                                                {
                                                  type: "email",
                                                  message: "Enter valid email",
                                                },
                                              ]}
                                            >
                                              <Input
                                                size="large"
                                                placeholder="Email"
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col sm={6}>
                                            <div className="label">
                                              Company Name
                                            </div>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "company"]}
                                            >
                                              <Input
                                                size="large"
                                                placeholder="Company Name"
                                              />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </div>
                                    )
                                  )}
                                  {fields?.length <= 2 && (
                                    <div
                                      className="eventFormScreen-formBox"
                                      onClick={() => add()}
                                      style={{
                                        cursor: loading
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                    >
                                      <div className="label">Add Attendees</div>
                                      <div>
                                        <MdAdd
                                          size={30}
                                          color="#0026ab"
                                          style={{ marginBottom: 4 }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </Form.List>
                            <br />
                            <Row>
                              <Col sm={6}></Col>
                              <Col sm={6}>
                                <Button
                                  type="primary"
                                  block
                                  style={{
                                    height: 50,
                                    fontFamily: "Roc_Grotesk_Wide",
                                    backgroundColor:
                                      typeof eventData?.bg_color === "string"
                                        ? eventData.bg_color
                                        : eventData?.bg_color?.toHexString
                                        ? eventData.bg_color.toHexString()
                                        : "#0026ab",
                                  }}
                                  htmlType="submit"
                                  loading={loading}
                                  disabled={loading}
                                >
                                  Submit
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        )}
                        <br /> <br />
                      </Col>
                      <Col sm={1} xs={12}></Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}
export default EventFormScreen;
