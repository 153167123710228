import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./styles.scss";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { BsPersonVideo3 } from "react-icons/bs";
import { PiBuildingOffice } from "react-icons/pi";
import { useEffect, useState } from "react";
import { GET } from "../../utils/apiCalls";
import API from "../../config/api";
import { Button, message } from "antd";
import DataTable from "./dataTable";
import Graph from "./graph";
import BarChart from "./barChart";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading";
import {
  MdOutlineBlock,
  MdOutlineMeetingRoom,
  MdOutlineNoMeetingRoom,
} from "react-icons/md";
import { SlHourglass, SlPeople } from "react-icons/sl";
function DashboardScreen() {
  const navigate = useNavigate();
  const [data, setData] = useState([]) as any;
  const [isLoading, setIsLoading] = useState(false);

  const Events = useSelector((state: any) => state.Events);
  useEffect(() => {
    if (Events.defult?.id) {
      loadData();
    } else {
      navigate("/company/events?page=1&limit=6");
    }
  }, [Events.defult?.id]);

  const loadData = async () => {
    try {
      setIsLoading(true);
      let url = API.DASHBOARD_LIST + `/${Events.defult?.id}`;
      let res: any = await GET(url, null);
      if (res.status) {
        setData(res.data);
      } else {
        message.error("Something went wrong...!");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Row>
            <Col md={4} xs={12}>
              <div className="DashboardScreen-box">
                <div
                  className="DashboardScreen-box2"
                  onClick={() => navigate("/company/meetings?page=1&limit=10")}
                >
                  <div>
                    <div className="DashboardScreen-text1">
                      {data?.totalMeetings}
                    </div>
                    <div style={{ margin: 10 }} />
                    <div className="DashboardScreen-text2">Total Meetings</div>
                  </div>
                  <div>
                    <HiOutlineUserGroup size={25} color="#598cd9" />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4} xs={12}>
              <div
                className="DashboardScreen-box"
                onClick={() =>
                  navigate(`/company/meetings?page=1&limit=10&start=${true}`)
                }
              >
                <div className="DashboardScreen-box2">
                  <div>
                    <div className="DashboardScreen-text1">
                      {data?.totalStartMeetings}
                    </div>
                    <div style={{ margin: 10 }} />
                    <div className="DashboardScreen-text2">
                      Total Started Meetings
                    </div>
                  </div>
                  <div>
                    <SlHourglass size={25} color="#598cd9" />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4} xs={12}>
              <div
                className="DashboardScreen-box"
                onClick={() =>
                  navigate(`/company/meetings?page=1&limit=10&pending=${true}`)
                }
              >
                <div className="DashboardScreen-box2">
                  <div>
                    <div className="DashboardScreen-text1">
                      {data?.totalNotStartMeetings}
                    </div>
                    <div style={{ margin: 10 }} />
                    <div className="DashboardScreen-text2">
                      Total Pending Meetings
                    </div>
                  </div>
                  <div>
                    <MdOutlineBlock size={25} color="#598cd9" />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4} xs={12}>
              <div
                className="DashboardScreen-box"
                onClick={() => navigate("/company/investors?page=1&limit=10")}
              >
                <div className="DashboardScreen-box2">
                  <div>
                    <div className="DashboardScreen-text1">
                      {data?.TotalInvestors}
                    </div>
                    <div style={{ margin: 10 }} />
                    <div className="DashboardScreen-text2">
                      Total Venture Capitalists
                    </div>
                  </div>
                  <div>
                    <BsPersonVideo3 size={25} color="#598cd9" />
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4} xs={12}>
              <div
                className="DashboardScreen-box"
                onClick={() =>
                  navigate("/company/investors?nomeeting=true&page=1&limit=10")
                }
              >
                <div className="DashboardScreen-box2">
                  <div>
                    <div className="DashboardScreen-text1">
                      {data?.totalInvestorsWithoutMeating}
                    </div>
                    <div style={{ margin: 10 }} />
                    <div className="DashboardScreen-text2">
                      VCs without meetings
                    </div>
                  </div>
                  <div>
                    <SlPeople size={25} color="#598cd9" />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4} xs={12}>
              <div
                className="DashboardScreen-box"
                onClick={() =>
                  navigate("/company/investors?noroom=true&page=1&limit=10")
                }
              >
                <div className="DashboardScreen-box2">
                  <div>
                    <div className="DashboardScreen-text1">
                      {data?.totalInvestorsWithoutRoom}
                    </div>
                    <div style={{ margin: 10 }} />
                    <div className="DashboardScreen-text2">
                      VCs without meetings rooms
                    </div>
                  </div>
                  <div>
                    <MdOutlineNoMeetingRoom size={25} color="#598cd9" />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4} xs={12}>
              <div
                className="DashboardScreen-box"
                onClick={() => navigate("/company/startups?page=1&limit=10")}
              >
                <div className="DashboardScreen-box2">
                  <div>
                    <div className="DashboardScreen-text1">
                      {data?.totalStartups}
                    </div>
                    <div style={{ margin: 10 }} />
                    <div className="DashboardScreen-text2">Total Startups</div>
                  </div>
                  <div>
                    <PiBuildingOffice size={25} color="#598cd9" />
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4} xs={12}>
              <div
                className="DashboardScreen-box"
                onClick={() => navigate("/company/rooms?page=1&limit=10")}
              >
                <div className="DashboardScreen-box2">
                  <div>
                    <div className="DashboardScreen-text1">
                      {data?.totalRooms}
                    </div>
                    <div style={{ margin: 10 }} />
                    <div className="DashboardScreen-text2">Total Rooms</div>
                  </div>
                  <div>
                    <MdOutlineMeetingRoom size={25} color="#598cd9" />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4} xs={12}>
              <div
                className="DashboardScreen-box"
                onClick={() =>
                  navigate("/company/rooms?page=1&limit=10&available=false")
                }
              >
                <div className="DashboardScreen-box2">
                  <div>
                    <div className="DashboardScreen-text1">
                      {data?.totalFreeRooms}
                    </div>
                    <div style={{ margin: 10 }} />
                    <div className="DashboardScreen-text2">
                      Total Available Rooms
                    </div>
                  </div>
                  <div>
                    <MdOutlineMeetingRoom size={25} color="#598cd9" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6} xs={12}>
              <div className="DashboardScreen-row">
                <h4 className="DashboardScreen-text1">
                  Daily VCs Registration
                </h4>
              </div>
              <div style={{ margin: 15 }} />
              <Graph data={data?.investorsGraph} />
            </Col>
            <Col md={6} xs={12}>
              <div className="DashboardScreen-row">
                <h4 className="DashboardScreen-text1">Daily Statistics</h4>
              </div>
              <div style={{ margin: 15 }} />
              <BarChart data={data} />
            </Col>
          </Row>
          <div className="DashboardScreen-row">
            <h4 className="DashboardScreen-text1">Recently Registered VCs</h4>
            <div style={{ flex: 1 }} />
            <div>
              <Button
                type="link"
                size="small"
                onClick={() => navigate("/company/meetings?page=1&limit=10")}
              >
                See More {">"}
              </Button>
            </div>
          </div>
          <div style={{ margin: 15 }} />
          <div className="DashboardScreen-box" style={{ padding: 0 }}>
            <DataTable data={data} />
          </div>
        </>
      )}
      <br />
    </div>
  );
}

export default DashboardScreen;
