import { Button, Form, Input, message, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import { Col } from "react-bootstrap";

const DeleteModal = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();

  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);

      if (val?.delete == "delete") {
        await props?.deleteCompany();
        props?.onClose();
      } else {
        message.info("Please type 'delete' to confirm");
      }
    } catch (err) {
      console.log("err", err);
      message.error("Oops. something gone wrong");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <Modal
        title="Delete company"
        open={props?.open}
        onCancel={props?.onClose}
        maskClosable={false}
        centered
        footer={false}
      >
        <div>
          By deleting this company, you will permanently lose all data
          associated with it.
        </div>
        <ul>
          <li>
            <strong>Users</strong>
          </li>
          <li>
            <strong>Events</strong>
          </li>
          <li>
            <strong>Startups</strong>
          </li>
          <li>
            <strong>Investors</strong>
          </li>
          <li>
            <strong>Meeting rooms</strong>
          </li>
          <li>
            <strong>Mail templates</strong>
          </li>
          <li>
            <strong>Event links</strong>
          </li>
        </ul>
        <Form form={form} onFinish={onFinish}>
          <div className="label">
            To delete permenently enter '<i>delete</i>' and click delete
          </div>
          <Form.Item name="delete">
            <Input size="large" placeholder="delete" />
          </Form.Item>
          <Col
            xs={12}
            md={{ offset: 6, span: 6 }}
            className="d-flex gap-3 mt-3"
          >
            <Button onClick={props?.onClose} disabled={isLoading} block>
              Cancel
            </Button>
            <Button
              danger
              loading={isLoading}
              type="primary"
              block
              htmlType="submit"
            >
              {" "}
              Delete
            </Button>
          </Col>
        </Form>
      </Modal>
    </div>
  );
};

export default DeleteModal;
