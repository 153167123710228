import { Button, Form, Input, message } from "antd";
import { useEffect, useState, useCallback } from "react";
import { SiMicrosoftexcel } from "react-icons/si";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import Loading from "../../components/loading";
import API from "../../config/api";
import { DELETE, GET } from "../../utils/apiCalls";
import PageHeader from "../routes/pageHeader";
import RoomDataTable from "./components/dataTable";
import RoomForm from "./components/form";

const MeetingRooms = () => {
  const { search } = useLocation();
  const Events = useSelector((state: any) => state.Events);
  const user = useSelector((state: any) => state.User.user);
  const query = new URLSearchParams(search);
  const { defult } = useSelector((state: any) => state.Events) || {};
  const eventId = defult?.id;
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [meta, setMeta] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState({
    list: true,
    excel: false,
  });
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [openForm, setOpenForm] = useState(false);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState(1);

  useEffect(() => {
    fetchData();
  }, [data1]);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading((prev) => ({ ...prev, list: true }));
      const params = {
        ...(query.get("query") && { query: query.get("query") }),
        ...(query.get("page") && { page: query.get("page") }),
        ...(query.get("limit") && { take: query.get("limit") }),
        ...(query.get("available") && {
          has_allocated: query.get("available"),
        }),
        eventId,
      };
      const res: any = await GET(API.ROOM_LIST_SEARCH, params);
      if (res.status) {
        setData(res.data);
        setMeta(res.meta);
      } else {
        message.error("Failed to fetch meetings data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("An error occurred while fetching meetings.");
    } finally {
      setIsLoading((prev) => ({ ...prev, list: false }));
      setLoading(false);
    }
  }, [query, eventId]);

  const generateExcel = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, excel: true }));
      const response: any = await GET(API.ROOMS_LIST_EVENT_ID + eventId, null);
      if (response?.status) {
        const worksheet = XLSX.utils.json_to_sheet(response.data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const dataBlob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(dataBlob);
        link.download = "Report.xlsx";
        link.click();
      } else {
        message.error("Couldn't generate reports");
      }
    } catch (err) {
      console.error(err);
      message.error("Couldn't generate reports");
    } finally {
      setIsLoading((prev) => ({ ...prev, excel: false }));
    }
  };

  const handleEditItem = (value: any) => {
    setSelectedItem(value);
    setOpenForm(true);
  };

  const handleDeleteItem = async (id: any) => {
    try {
      const response: any = await DELETE(API.ROOMS_DELETE + id);
      if (response?.status) {
        fetchData();
        message.success("Deleted successfully.");
      } else {
        message.error("Failed to delete.");
      }
    } catch (err) {
      message.error("An error occurred while deleting.");
    }
  };

  const changeParams = (data: any) => {
    const queryParams = [
      { key: "query", value: data?.query || (data?.val && query.get("query")) },
      query.get("available") && {
        key: "available",
        value: query.get("available"),
      },
    ]
      .filter((param: any) => param?.value)
      .map((param: any) => `&${param.key}=${param?.value}`)
      .join("");
    const baseUrl = `/company/rooms?page=${
      data.page || query.get("page")
    }&limit=${data.pageSize || query.get("limit")}`;
    navigate(`${baseUrl}${queryParams}`);
    setData1((prev) => prev + 1);
  };

  return (
    <div>
      <PageHeader title="Meeting Rooms">
        <Form
          form={form}
          onValuesChange={(_, allData) =>
            changeParams({
              ...allData,
              page: 1,
            })
          }
          initialValues={{
            query: query.get("query"),
          }}
        >
          <div className="AdminHeader-extra">
            <Form.Item name="query" noStyle>
              <Input style={{ width: 200 }} placeholder="Search" allowClear />
            </Form.Item>
            {Array.isArray(user.access[Events?.defult?.id]) &&
               user.access[Events?.defult?.id].includes(8) ? null : 
               <>
            <Button type="primary" onClick={() => handleEditItem(null)}>
              ADD ROOMS +
            </Button>
            <Button
              loading={isLoading.excel}
              onClick={generateExcel}
              style={{ padding: 5, background: "#fff", cursor: "pointer" }}
            >
              EXPORT <SiMicrosoftexcel size={20} color="green" />
            </Button>
</>}
          </div>
        </Form>
      </PageHeader>
      {loading ? (
        <Loading />
      ) : (
        <RoomDataTable
          data={data}
          meta={meta}
          loading={isLoading.list}
          onPageChange={(page: number, pageSize: number) =>
            changeParams({ page, pageSize, val: true })
          }
          onChange={handleEditItem}
          delete={handleDeleteItem}
        />
      )}
      {openForm && (
        <RoomForm
          open={openForm}
          data={selectedItem}
          onClose={() => setOpenForm(false)}
          reload={fetchData}
        />
      )}
      <br />
    </div>
  );
};

export default MeetingRooms;
